/* eslint-disable no-useless-constructor */
import * as React from "react";
import { ChartComponent, SeriesCollectionDirective, SeriesDirective, Inject, Legend, Category, Tooltip, LineSeries, DateTime, Trendlines, TrendlinesDirective, TrendlineDirective, ColumnSeries, DataLabel } from '@syncfusion/ej2-react-charts';
import { GridComponent, ColumnsDirective, ColumnDirective } from '@syncfusion/ej2-react-grids';
import { RadioButtonComponent } from '@syncfusion/ej2-react-buttons';
import { fabricColors, bootstrapColors, materialColors, highContrastColors } from './theme-color';
import { SampleBase } from './sample-base';
import * as CustomJS from '../Custom';
import _ from 'underscore/underscore-min';
import $ from 'jquery';

var dataCorrente = new Date();
var dd = String(dataCorrente.getDate()).padStart(2, '0');
var mm = String(dataCorrente.getMonth() + 1).padStart(2, '0'); //January is 0!
var yyyy = dataCorrente.getFullYear();
dataCorrente = yyyy + mm + dd;

function headerTemplate1(props) {    
    return (<div>
         <span style={{ fontSize: '0.75vw', fontWeight: 'bolder' }}>{props.headerText}</span>
    </div>);
}
function headerTemplate2(props) {    
    return (<div>
         <span style={{ fontSize: '1.1vw', fontWeight: 'bolder' }}>{props.headerText}</span>
    </div>);
}
export default class ChartSinistriPervenuti extends SampleBase {
    constructor() {
        super(...arguments); 
    }
    state= {
    }
    cambioVisione(args) {
        if (args.value === 'andamento') {
            document.getElementById('divChartBarre').style.display = 'none';
            document.getElementById('divChartLinee').style.removeProperty('display');          
        }
        else if (args.value === 'puntuale') {
            document.getElementById('divChartLinee').style.display = 'none';
            document.getElementById('divChartBarre').style.removeProperty('display');          
        }
    }
    onQueryCellInfo1(args) {            
        args.cell.style.color = args.column.index === 0 ? 'black' : 'purple';   
        args.cell.style.fontSize = '1.0vw';
        args.cell.style.fontWeight = 'bold';   
        if (args.data.rowDescr === 'Delta %' && args.cell.innerText !== 'Delta %')
            args.cell.innerText = parseFloat(args.cell.innerText).toLocaleString('it-IT'); 
        if (args.data.rowDescr.indexOf('Perv') > -1 && args.cell.innerText.indexOf('Perv') === -1) 
            args.cell.innerText = parseFloat(args.cell.innerText).toLocaleString('it-IT'); 
        if (args.column.index === 13)             
            args.cell.style.backgroundColor = 'gold'; 
        if (args.cell.innerText === 'NaN' || args.cell.innerText === '+∞')
            args.cell.innerText = '';    
        if (args.cell.innerText.indexOf('-') > -1)           
            args.cell.style.color = 'red';    
        else if (args.data.rowDescr === 'Delta %' && args.cell.innerText !== 'Delta %' && args.cell.innerText !== '') {       
            args.cell.innerText = '+' + args.cell.innerText;  
            args.cell.style.color = 'green';
        }       
        if (args.cell.innerText === '+')   
            args.cell.innerText = '';        
    }    
    onQueryCellInfo2(args) { 
        args.cell.style.color = args.column.index === 0 ? 'black' : 'purple';   
        args.cell.style.fontSize = '1.0vw';
        args.cell.style.fontWeight = 'bold';  
        if (args.column.index === 1 || args.column.index === 2 || args.column.index === 3)
            args.cell.innerText = parseFloat(args.cell.innerText).toLocaleString('it-IT'); 
        if (args.column.index === 3) {
            if (args.cell.innerText.indexOf('-') > -1)           
                args.cell.style.color = 'red';
            else {
                args.cell.innerText = `+${args.cell.innerText}`;  
                args.cell.style.color = 'green';
            }
        }      
        if (args.cell.innerText === 'NaN' || args.cell.innerText === '+NaN' || args.cell.innerText === '-NaN' || args.cell.innerText === '+∞')
            args.cell.innerText = '';           
    }    
    onDatabound1(args) {       
        for (let ind=0;ind<=13;ind++)
             document.getElementById('GridDeltaMensili').querySelectorAll('.e-columnheader')[0].children[ind].style.backgroundColor = 'Linen';    
        document.getElementById('GridDeltaMensili').querySelectorAll('.e-columnheader')[0].children[13].style.backgroundColor = 'gold';        
    }
    onDatabound2(args) {       
        for (let ind=0;ind<=3;ind++)
             document.getElementById('GridDeltaCompagnie').querySelectorAll('.e-columnheader')[0].children[ind].style.backgroundColor = 'Linen';
    }
    onDatabound3(args) {       
        for (let ind=0;ind<=3;ind++)
             document.getElementById('GridDeltaCompagniePeriodo').querySelectorAll('.e-columnheader')[0].children[ind].style.backgroundColor = 'Linen';
    }
    onRowDatabound(args) {  
        if (args.data.DescrizioneCompagnia.indexOf('TOTALI') > -1) {
            args.row.className = 'e-row';
            args.row.bgColor = 'gold'; 
        }
    }
    render() { 
        return (<div className='container-fluid'> 
                    <div className="row" style={{ marginTop: '10px' }}>      
                        <div className="col-xs-12 col-sm-12 col-lg-10 col-md-10">
                            <span id='titoloChartSinistriPervenuti' style={{ fontSize: '1.7vw', fontWeight: 'bold', color:'#133B95'}}></span>
                        </div>  
                        <div className="col-xs-6 col-sm-6 col-md-2 col-lg-2"> 
                            <RadioButtonComponent id='visioneAndamento' checked={true} label='Confronto degli andamenti' style={{ fontSize: '0.8vw'}} name='sceltaVisione' change={this.cambioVisione.bind(this)} value="andamento"></RadioButtonComponent>  
                            <RadioButtonComponent id='visioneBarre' checked={false} label='Confronto dei valori puntuali' name='sceltaVisione' change={this.cambioVisione.bind(this)} value="puntuale"></RadioButtonComponent> 
                        </div>   
                     </div>    
                    <div className="row">
                        <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12">    
                            <span id='elencoCompagnieChartSinistriPervenuti' style={{ fontSize: '1.2vw', fontWeight: 'bold', color:'#133B95'}}></span>
                        </div> 
                    </div>   
                    <div id='divChartLinee' className="row">                 
                        <ChartComponent id='ChartSinistriPervenutiLinee' style={{ textAlign: "center" }}
                            className='col-xs-12 col-sm-12 col-lg-12 col-md-12'
                            ref={chart => {this.chartInstance1 = chart}}
                            primaryXAxis={{
                                valueType: 'DateTime',
                                labelFormat: 'MMMM',
                                intervalType: 'Months',
                                edgeLabelPlacement: 'Shift',
                                majorGridLines: { width: 0 }                
                            }}
                            primaryYAxis={{
                                labelFormat: '{value}',
                                rangePadding: 'None',                                
                                interval: 250,
                                lineStyle: { width: 0 },                                
                                minimum: 0,
                                majorTickLines: { width: 0 },
                                minorTickLines: { width: 0 }
                            }}
                            chartArea={{ border: { width: 0 } }}
                            tooltip={{ enable: true }}
                            width={CustomJS.chartWidth4}
                            title='' loaded={this.operazioniDopoCarica.bind(this)}
                            legendSettings={{ visible: true }}>
                            <Inject services={[LineSeries, DateTime, Legend, Tooltip, Trendlines]} />
                            <SeriesCollectionDirective>
                                <SeriesDirective xName='x' yName='y' name={`Sinistri Pervenuti ${(yyyy - 1).toString()}`}
                                    width={3} marker={{ visible: true, width: 10, height: 10 }} type='Line'>
                                        {/* <TrendlinesDirective>
                                            <TrendlineDirective type='Linear' width={3} marker={{ visible: false }} name='Linea Tendenza' fill='#C64A75'>
                                            </TrendlineDirective>
                                        </TrendlinesDirective> */}
                                </SeriesDirective>
                                <SeriesDirective xName='x' yName='y' name={`Sinistri Pervenuti ${yyyy}`}
                                    width={3} marker={{ visible: true, width: 10, height: 10 }} type='Line'>
                                        {/* <TrendlinesDirective>
                                            <TrendlineDirective type='Linear' width={3} marker={{ visible: false }} name='Linea Tendenza' fill='#C64A75'>
                                            </TrendlineDirective>
                                        </TrendlinesDirective> */}
                                </SeriesDirective>
                            </SeriesCollectionDirective>
                        </ChartComponent>  
                    </div>       
                    <div id='divChartBarre' className="row">                 
                        <ChartComponent id='ChartSinistriPervenutiBarre' style={{ textAlign: "center" }} loaded={this.operazioniDopoCarica.bind(this)}
                            className='col-xs-12 col-sm-12 col-lg-12 col-md-12' ref={chart => {this.chartInstance2 = chart}}
                            primaryXAxis={{ valueType: 'Category', interval: 1, majorGridLines: { width: 0 } }} 
                            primaryYAxis={{ majorGridLines: { width: 0 }, majorTickLines: { width: 0 }, lineStyle: { width: 0 }, labelStyle: { color: 'transparent' } }}
                            chartArea={{ border: { width: 0 } }} tooltip={{ enable: true }} 
                            width={CustomJS.chartWidth4} 
                            height={CustomJS.chartHeight} title=''
                            legendSettings={{ visible: true }}>
                            <Inject services={[ColumnSeries, Legend, Tooltip, Category, DataLabel]}/>
                            <SeriesCollectionDirective>
                                <SeriesDirective xName='x' yName='y' type='Column' name={`Sinistri Pervenuti ${(yyyy - 1).toString()}`} 
                                     cornerRadius={{ bottomLeft: 10, bottomRight: 10, topLeft: 10, topRight: 10 }} marker={{ dataLabel: { visible: true, position: 'Top', font: { fontWeight: '600', color: '#ffffff' } } }}>
                                </SeriesDirective>
                                <SeriesDirective xName='x' yName='y' type='Column' name={`Sinistri Pervenuti ${yyyy}`}
                                     cornerRadius={{ bottomLeft: 10, bottomRight: 10, topLeft: 10, topRight: 10 }} marker={{ dataLabel: { visible: true, position: 'Top', font: { fontWeight: '600', color: '#ffffff' } } }}>
                                </SeriesDirective>                                                                             
                            </SeriesCollectionDirective>
                        </ChartComponent>      
                    </div> 
                    <div className="row" style={{ marginTop: '10px' }}>      
                        <div className="col-xs-12 col-sm-12 col-lg-10 col-md-10">
                            <span id='titoloChartSinistriPervenuti' style={{ fontSize: '1.2vw', fontWeight: 'bold', color:'#133B95'}}>CONFRONTO SINISTRI PERVENUTI MENSILI</span>
                        </div>  
                    </div>       
                    <div className='row'>
                        <GridComponent id="GridDeltaMensili" ref={grid => this.gridInstance1 = grid} allowSelection={false} enableHover={false} 
                                allowPaging={false} queryCellInfo={this.onQueryCellInfo1.bind(this)} dataBound={this.onDatabound1.bind(this)} enableAltRow={false} 
                                allowTextWrap={true}>
                            <ColumnsDirective>
                                <ColumnDirective field='rowDescr' headerText='' width='8%' headerTemplate={headerTemplate1} textAlign='Center'></ColumnDirective>
                                <ColumnDirective field='GEN' headerText='GENNAIO' headerTemplate={headerTemplate1} textAlign='Center'></ColumnDirective>
                                <ColumnDirective field='FEB' headerText='FEBBRAIO' headerTemplate={headerTemplate1} textAlign='Center'></ColumnDirective>
                                <ColumnDirective field='MAR' headerText='MARZO' headerTemplate={headerTemplate1} textAlign='Center'></ColumnDirective>
                                <ColumnDirective field='APR' headerText='APRILE' headerTemplate={headerTemplate1} textAlign='Center'></ColumnDirective>
                                <ColumnDirective field='MAG' headerText='MAGGIO' headerTemplate={headerTemplate1} textAlign='Center'></ColumnDirective>
                                <ColumnDirective field='GIU' headerText='GIUGNO' headerTemplate={headerTemplate1} textAlign='Center'></ColumnDirective>
                                <ColumnDirective field='LUG' headerText='LUGLIO' headerTemplate={headerTemplate1} textAlign='Center'></ColumnDirective>
                                <ColumnDirective field='AGO' headerText='AGOSTO' headerTemplate={headerTemplate1} textAlign='Center'></ColumnDirective>
                                <ColumnDirective field='SET' headerText='SETTEMBRE' headerTemplate={headerTemplate1} textAlign='Center'></ColumnDirective>
                                <ColumnDirective field='OTT' headerText='OTTOBRE' headerTemplate={headerTemplate1} textAlign='Center'></ColumnDirective>
                                <ColumnDirective field='NOV' headerText='NOVEMBRE' headerTemplate={headerTemplate1} textAlign='Center'></ColumnDirective>
                                <ColumnDirective field='DIC' headerText='DICEMBRE' headerTemplate={headerTemplate1} textAlign='Center'></ColumnDirective>
                                <ColumnDirective field='TOT' headerText='TOTALI' headerTemplate={headerTemplate1} textAlign='Center'></ColumnDirective>
                            </ColumnsDirective>                        
                        </GridComponent>
                    </div>
                    <div className='row'></div>
                    <div className="row" style={{ marginTop: '10px' }}>      
                        <div className="col-xs-12 col-sm-12 col-lg-10 col-md-10">
                            <span style={{ fontSize: '1.2vw', fontWeight: 'bold', color:'#133B95'}}>CONFRONTO SINISTRI PERVENUTI COMPAGNIE (annuale)</span>
                        </div>  
                    </div>  
                    <div className='row'>
                        <GridComponent id="GridDeltaCompagnie" ref={grid => this.gridInstance2 = grid} allowSelection={false} enableHover={false} 
                                allowPaging={false} queryCellInfo={this.onQueryCellInfo2.bind(this)} dataBound={this.onDatabound2.bind(this)} enableAltRow={false} 
                                rowDataBound={this.onRowDatabound} allowTextWrap={true}>
                            <ColumnsDirective>
                                <ColumnDirective field='DescrizioneCompagnia' headerText='Compagnia' width='30%' headerTemplate={headerTemplate2} textAlign='Center'></ColumnDirective>
                                <ColumnDirective field='PervAnnoPrec' headerText={`Pervenuti ${(yyyy - 1).toString()}`} headerTemplate={headerTemplate2} textAlign='Center'></ColumnDirective>
                                <ColumnDirective field='PervAnnoCorr' headerText={`Pervenuti ${yyyy.toString()}`} headerTemplate={headerTemplate2} textAlign='Center'></ColumnDirective>
                                <ColumnDirective field='DeltaPerc' headerText='Delta %' headerTemplate={headerTemplate2} textAlign='Center'></ColumnDirective>
                            </ColumnsDirective>                        
                        </GridComponent>
                    </div>
                    <div className='row'></div>
                    <div className="row" style={{ marginTop: '10px' }}>      
                        <div className="col-xs-12 col-sm-12 col-lg-10 col-md-10">
                            <span style={{ fontSize: '1.2vw', fontWeight: 'bold', color:'#133B95'}}>CONFRONTO SINISTRI PERVENUTI COMPAGNIE (nel periodo scelto)</span>
                        </div>  
                    </div>  
                    <div className='row'>
                        <GridComponent id="GridDeltaCompagniePeriodo" ref={grid => this.gridInstance2 = grid} allowSelection={false} enableHover={false} 
                                allowPaging={false} queryCellInfo={this.onQueryCellInfo2.bind(this)} dataBound={this.onDatabound3.bind(this)} enableAltRow={false} 
                                rowDataBound={this.onRowDatabound} allowTextWrap={true}>
                            <ColumnsDirective>
                                <ColumnDirective field='DescrizioneCompagnia' headerText='Compagnia' width='30%' headerTemplate={headerTemplate2} textAlign='Center'></ColumnDirective>
                                <ColumnDirective field='PervAnnoPrec' headerText={`Pervenuti ${(yyyy - 1).toString()}`} headerTemplate={headerTemplate2} textAlign='Center'></ColumnDirective>
                                <ColumnDirective field='PervAnnoCorr' headerText={`Pervenuti ${yyyy.toString()}`} headerTemplate={headerTemplate2} textAlign='Center'></ColumnDirective>
                                <ColumnDirective field='DeltaPerc' headerText='Delta %' headerTemplate={headerTemplate2} textAlign='Center'></ColumnDirective>
                            </ColumnsDirective>                        
                        </GridComponent>
                    </div>
            </div>);
    } 
    rendereComplete() {                 
    }
    operazioniDopoCarica(args) {                
    }    
}