/* eslint-disable no-extend-native */
import React from 'react';
import { SampleBase } from './sample-base';
import * as CustomJS from '../Custom';
import Marker from './Marker';
import Popup from './Popup';
import { fetchCoordinatesData, fetchCoordinatesData2 } from '../FetchCoordinatesData';
import mapboxgl from 'mapbox-gl'; 
import BlockUi from 'react-block-ui';
import 'react-block-ui/style.css';
import CustomMarker from '../assets/map-marker.png';
import _ from 'underscore/underscore-min';
import { random, isUndefined } from 'underscore';
import { ToastContainer, toast, Slide, Zoom, Flip, Bounce } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";

String.prototype.format2 = function() {
    let a = this;
    for (let k in arguments) {
      a = a.replace("{" + k + "}", arguments[k])
    }
    return a
}

//mapboxgl.accessToken = CustomJS.tokenAPIMapboxPublic; //pubblico
mapboxgl.accessToken = CustomJS.tokenAPIMapboxPrivate;  //privato

const getRealCoordinate = (indirizzo, cbFunc) => {        
    let longitude, latitude, re = new RegExp('/',"gi");
    let count = indirizzo.match(re)?.length; 
    indirizzo = indirizzo.replace(/\?/g, '');
    if (count && count > 0) {
        for (let ind=1;ind<=count;ind++)
             indirizzo = CustomJS.removeStringFromString(indirizzo, indirizzo.indexOf('/'), indirizzo.indexOf(' ', indirizzo.indexOf('/') - 1));
    }
    console.log(`Indirizzo sanificato(se necessario) : ${indirizzo}`);    
    CustomJS.getRemoteData2(CustomJS.APIUrlMapboxMaps.format2(indirizzo),'', 
      'GET', "", retData => {
        if (!CustomJS.isNullEmpty(retData)) {
            let geoObj = _.sortBy(JSON.parse(retData).features, 'relevance').reverse();
            longitude = geoObj[0]?.center[0]; //geoObj[0]?.geometry?.coordinates[0];     //Mapbox Maps
            //latitude = geoObj.resourceSets[0]?.resources[0]?.point?.coordinates[0];   //Bing Maps
            latitude = geoObj[0]?.center[1]; //geoObj[0]?.geometry?.coordinates[1];    //Mapbox Maps
            //longitude = geoObj.resourceSets[0]?.resources[0]?.point?.coordinates[1];  //Bing Maps             
            cbFunc(longitude, latitude);       
        }
    }, true, true);  
}  

export default class MappaSinistri extends SampleBase {
    constructor() {
        super(...arguments);   
        this.toggleBlocking = this.toggleBlocking.bind(this);
        this.loaderType = ['Ball-Triangle', 'Bars', 'Circles', 'Grid', 'Puff', 'Rings', 'TailSpin', 'ThreeDots', 'Oval', 'MutatingDots', 'RevolvingDot'];
        this.tutteCoords = new mapboxgl.LngLatBounds(); 
        this.sorgenteMarkers = {};                       
    }  
    state = {
        lng: 12.482932,
        lat: 41.89332,
        zoom: 5.67,
        blocking: false
    };
    toggleBlocking() {        
        this.setState({blocking: !this.state.blocking});
    }       

    render() {
        return (<div id='divMappaSinistri' className='container-fluid'>   
                    <ToastContainer containerId='toastContainer2' transition={Zoom} style={{ fontSize: '1.5em', color: 'darkred', fontWeight: 'bold', width: '500px' }}></ToastContainer>
                    <BlockUi id='blockUI2' tag='div' blocking={this.state.blocking} loader={<Loader width={80} height={80} type={this.loaderType[random(this.loaderType.length-1)]} color='#133B95' /> }>
                        <div id='divTitoloMappaSinistri' className="row" style={{ marginTop: '10px' }}>      
                            <div className="col-xs-12 col-sm-12 col-lg-6 col-md-6">
                                <span id='titoloMappaSinistri' style={{ fontSize: '1.6vw', fontWeight: 'bold', color:'#133B95'}}></span>
                            </div>  
                            <div className="col-xs-12 col-sm-12 col-lg-6 col-md-6">    
                                <span id='elencoCompagnieMappaSinistri' style={{ fontSize: '1.1vw', fontWeight: 'bold', color:'#133B95'}}></span>
                            </div>                                 
                        </div>   
                        <div className="row">                                                   
                            <div className='sidebarStyle'>
                                <div>Longitudine: {this.state.lng} | Latitudine: {this.state.lat} | Zoom: {this.state.zoom}</div>
                            </div>        
                            <div style={{ height: '1000px' }} ref={el => this.mapContainer = el} className="mapContainer"></div>  
                        </div>   
                    </BlockUi>               
                </div>);
    }
    rendereComplete() {        
    }
    componentDidMount() {    
        const map = new mapboxgl.Map({
            container: this.mapContainer,
            style: 'mapbox://styles/mapbox/streets-v11',
            center: [this.state.lng, this.state.lat],
            zoom: this.state.zoom
        });  
        map.on('move', () => {
            this.setState({
                lng: map.getCenter().lng.toFixed(4),
                lat: map.getCenter().lat.toFixed(4),
                zoom: map.getZoom().toFixed(2)
            });
        }); 
        this.toggleBlocking();
        let newFeaturesList = [];        
        CustomJS.getRemoteData2(CustomJS.APIUrlProd,
            `token=${CustomJS.tokenProd}&azione=mappaSinistri&tipoSinistri=aperti&compagnie=${document.getElementById('compagnieSelezionate').ej2_instances[0].value.join(',')}`, 
            'POST', "application/x-www-form-urlencoded", retData => { 
                let arrayIndirizzi = [], arrayInfoSinistri = [];
                retData.forEach(elm => {
                    let coordinate = CustomJS.isNullEmpty(elm.LongitudineAccadimentoSinistro) ? 'NonPresente' : [elm.LongitudineAccadimentoSinistro, elm.LatitudineAccadimentoSinistro];
                    arrayIndirizzi.push({ 
                        Indirizzo: `${elm.IndirizzoAccadimentoSinistro} ${elm.CittaAccadimentoSinistro} ${elm.CAPAccadimentoSinistro} (${elm.ProvinciaAccadimentoSinistro})`,
                        Coordinate: coordinate
                    });
                    arrayInfoSinistri.push({ IdSinistro: elm.IdSinistroSIPA, Contraente: elm.NominativoContraente })
                });
                //const results = fetchCoordinatesData2(arrayIndirizzi);
                arrayIndirizzi.forEach((elm,idx) => {
                    const id = idx;   
                    if (elm.Coordinate === 'NonPresente') {              
                        getRealCoordinate(elm.Indirizzo, (longitude, latitude) => {                              
                            console.log(longitude);
                            console.log(latitude);                                
                            if (!CustomJS.isNullEmpty(longitude) && !CustomJS.isNullEmpty(latitude)) {
                                newFeaturesList.push({
                                    type: 'Feature',
                                    geometry: {
                                        type: 'Point',
                                        coordinates: [longitude, latitude],
                                    },
                                    properties: {                                
                                        name: `${arrayInfoSinistri[idx].IdSinistro}`,
                                        description: `${arrayInfoSinistri[idx].Contraente}`,
                                        address: `${arrayIndirizzi[idx].Indirizzo}`
                                    },
                                });                           
                                CustomJS.callAPI(CustomJS.APIUrlProd, 
                                    `token=${CustomJS.tokenProd}&azione=salvaCoordinateGPS&IdSinistroSIPA=${arrayInfoSinistri[idx].IdSinistro}&Longitudine=${longitude}&Latitudine=${latitude}`,
                                    'POST', 'application/x-www-form-urlencoded', retData => {  
                                        console.log(`Aggiornate in SIPA le coordinate per il sinistro ${arrayInfoSinistri[idx].IdSinistro} - Indirizzo: ${elm.Indirizzo} - Longitudine: ${longitude}, Latitudine: ${latitude}`);                                  
                                }, error => { 
                                    console.log(error);                                    
                                }, true, false)
                            }
                            else
                                console.log(`Non trovate le coordinate per l'indirizzo: ${elm.Indirizzo}`);
                        });
                    }
                    else {
                        //console.log(elm.Coordinate);                       
                        //this.tutteCoords.extend([elm.Coordinate[0], elm.Coordinate[1]]);
                        newFeaturesList.push({
                            type: 'Feature',
                            geometry: {
                                type: 'Point',
                                coordinates: elm.Coordinate,
                            },
                            properties: {                                
                                name: `${arrayInfoSinistri[idx].IdSinistro}`,
                                description: `${arrayInfoSinistri[idx].Contraente}`,
                                address: `${arrayIndirizzi[idx].Indirizzo}`
                            },
                        });
                    }
                });      
                this.sorgenteMarkers = { type: 'geojson', data: {'type': 'FeatureCollection',
                                                                 'features': newFeaturesList} };
                setTimeout(() => { this.toggleBlocking(); }, 4000);
        }, true, false);
        //for (let ind=0;ind<=1000000000;ind++) {};                  
        //const sorgenteMarkers = { type: 'geojson', data: {'type': 'FeatureCollection',
        //                                                  'features': newFeaturesList} };       
        map.on('load', () => {  
            setTimeout(() => {
                map.loadImage(
                    'https://docs.mapbox.com/mapbox-gl-js/assets/custom_marker.png',
                    // Add an image to use as a custom marker
                    (error, image) => {
                        if (error) throw error;
                        map.addImage('custom-marker', image);
                        map.addSource('places', this.sorgenteMarkers);  
                        map.addLayer({
                            'id': 'places',
                            'type': 'symbol',
                            'source': 'places',
                            'layout': {
                                'icon-image': 'custom-marker',
                                'icon-allow-overlap': true
                            }
                        });
                    }
                );           
            }, 2500);  
        }); 
        // Create a popup, but don't add it to the map yet.
        var popup = new mapboxgl.Popup({
            closeButton: false,
            closeOnClick: false
        });

        map.on('mouseenter', 'places', function (e) {
            // Change the cursor style as a UI indicator.
            map.getCanvas().style.cursor = 'pointer';
             
            var coordinates = e.features[0].geometry.coordinates.slice();
            var description = e.features[0].properties.description;
            var name = e.features[0].properties.name;
            let indirizzo = e.features[0].properties.address;
             
            // Ensure that if the map is zoomed out such that multiple
            // copies of the feature are visible, the popup appears
            // over the copy being pointed to.
            while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
                coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
            }
             
            // Populate the popup and set its coordinates
            // based on the feature found.
            popup.setLngLat(coordinates).setHTML(`<b>${name}</b><br/>${description}<br/>${indirizzo}`).addTo(map);
        });
             
        map.on('mouseleave', 'places', function () {
            map.getCanvas().style.cursor = '';
            popup.remove();
        });    
        
        setTimeout(() => {
            //map.fitBounds(this.tutteCoords);
        }, 1000);
    }
}