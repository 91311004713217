import React from 'react';
import { ChartComponent, SeriesCollectionDirective, SeriesDirective, Inject, Category, ColumnSeries, DataLabel, Tooltip, BarSeries, Legend} from '@syncfusion/ej2-react-charts';
import { Browser } from '@syncfusion/ej2-base';
import { MultiSelectComponent } from '@syncfusion/ej2-react-dropdowns';
import { Query } from '@syncfusion/ej2-data';
import { fabricColors, bootstrapColors, materialColors, highContrastColors } from './theme-color';
import { SampleBase } from './sample-base';
import * as CustomJS from '../Custom';
import { random } from 'underscore';
import _ from 'underscore/underscore-min';
import $ from 'jquery';

let data1 = [], data2 = [], data3 = [], data4 = [], filtroPeritoVisual = [], applicaFiltro = false;

export default class ChartSuddivisionePerito extends SampleBase {
    constructor() {
        super(...arguments);
        this.fieldsPerito = { text: 'DescrizionePerito', value: 'DescrizionePerito' };
    }
    tooltipTemplate(props) {    
        debugger;  
        let NumSinistriEntrati = props.color === '#70ad47' ? _.find(JSON.parse(window.localStorage.getItem('ChartData1')), elm => {return elm.x === props.x})?.y
                                 : _.find(JSON.parse(window.localStorage.getItem('ChartData3')), elm => {return elm.x === props.x})?.y;
        let NumSinistriChiusi = props.color === '#70ad47' ? _.find(JSON.parse(window.localStorage.getItem('ChartData2')), elm => {return elm.x === props.x})?.y
                                 : _.find(JSON.parse(window.localStorage.getItem('ChartData4')), elm => {return elm.x === props.x})?.y;
        let GiorniMediRientro = props.color === '#70ad47' ? _.find(JSON.parse(window.localStorage.getItem('ChartData2')), elm => {return elm.x === props.x})?.GiorniMediRientroRE
                                 : _.find(JSON.parse(window.localStorage.getItem('ChartData4')), elm => {return elm.x === props.x})?.GiorniMediRientroRC;
        let PagatoMedio = props.color === '#70ad47' ? _.find(JSON.parse(window.localStorage.getItem('ChartData2')), elm => {return elm.x === props.x})?.PagatoMedioRE
                                 : _.find(JSON.parse(window.localStorage.getItem('ChartData4')), elm => {return elm.x === props.x})?.PagatoMedioRC;
        let percChiusi = NumSinistriEntrati === 0 ? 0 : ((NumSinistriChiusi / NumSinistriEntrati) * 100) < 100 && ((NumSinistriChiusi / NumSinistriEntrati) * 100) > 0 
                                               && (((NumSinistriChiusi / NumSinistriEntrati) * 100) - Math.floor(((NumSinistriChiusi / NumSinistriEntrati) * 100))) !== 0
                                              ? ((NumSinistriChiusi / NumSinistriEntrati) * 100).toFixed(2)
                                              : ((NumSinistriChiusi / NumSinistriEntrati) * 100).toFixed(0);  
        let testoSinistriEntrati = props.color === '#70ad47' ? 'Sinistri Entrati RE' : 'Sinistri Entrati RC';
        let coloreSinistriEntrati = props.color === '#70ad47' ? materialColors[5 % 10] : materialColors[7 % 10];
        let testoSinistriChiusi = props.color === '#70ad47' ? 'Sinistri Chiusi RE' : 'Sinistri Chiusi RC';
        let coloreSinistriChiusi = props.color === '#70ad47' ? materialColors[4 % 10] : materialColors[6 % 10];
        let testoGiorniMediRientro = props.color === '#70ad47' ? 'Giorni Medi Rientro RE' : 'Giorni Medi Rientro RC';        
        let testoPagatoMedio = props.color === '#70ad47' ? 'Pagato Medio RE' : 'Pagato Medio RC';
        let testoScarico = props.color === '#70ad47' ? 'Scarico RE' : 'Scarico RC';

        return (<div id="tooltipContainer" className="tooltip-container">             
            <div className="row">
                <div className="col-lg-12">
                    <span style={{ fontSize: '1.1vw', color: '#133B95', fontWeight: 'bold', padding: '5px' }}>{props.x}</span>
                </div>
            </div> 
            <div className="row">
                <div className="col-lg-6">
                    <table style={{ width: '100%', border: '1px solid lightgrey' }}>
                        <tbody>                                                                         
                            <tr style={{ border: 'none' }}>
                                <td style={{ fontSize: '0.9vw', color: coloreSinistriEntrati, fontWeight: 'bold' }}>{testoSinistriEntrati}: {NumSinistriEntrati?.toLocaleString('it-IT')}</td>
                            </tr>
                            <tr>
                                <td style={{ fontSize: '0.9vw', color: coloreSinistriChiusi, fontWeight: 'bold' }}>{testoSinistriChiusi}: {NumSinistriChiusi?.toLocaleString('it-IT')}</td>
                            </tr>
                            <tr>
                                <td style={{ fontSize: '0.9vw', color: 'purple', fontWeight: 'bold' }}>{testoScarico}: {parseFloat(percChiusi)?.toLocaleString('it-IT')}%</td>
                            </tr>                                
                        </tbody>
                    </table>
                </div>
                <div className="col-lg-6">
                    <table style={{ width: '100%', border: '1px solid lightgrey' }}>
                        <tbody>  
                            <tr style={{ border: 'none' }}>
                                <td style={{ fontSize: '0.9vw', color: 'darkslategrey', fontWeight: 'bold', padding: '5px' }}>{testoGiorniMediRientro}: {GiorniMediRientro?.toLocaleString('it-IT')}</td>
                            </tr>
                            <tr>
                                <td style={{ fontSize: '0.9vw', color: 'darkslategrey', fontWeight: 'bold', padding: '5px' }}>{testoPagatoMedio}: {PagatoMedio?.toLocaleString('it-IT')}</td>
                            </tr> 
                        </tbody>
                    </table>
                </div>
            </div> 
        </div>);
    }
    onTagging(e) {
        e.setClass(CustomJS.colorsData[random(26)].Color.toLowerCase());
    }      
    onSelect(e) {
        debugger;
        filtroPeritoVisual.push(e.itemData.DescrizionePerito);  
        this.applicaFiltroPerito();      
    }
    onRemove(e) {
        filtroPeritoVisual = filtroPeritoVisual.filter(elm => { return elm !== e.itemData.DescrizionePerito}); 
        this.applicaFiltroPerito();
    }
    onFiltering(e) {  
        applicaFiltro = true;      
        let query = new Query();
        query = (e.text !== "") ? query.where("DescrizionePerito", "contains", e.text, true) : query;     
        e.updateData(this.dataSource, query);
    }
    onDataBound(e) {
        if (!applicaFiltro)
            filtroPeritoVisual = [];
        applicaFiltro = false;
    }

    render() {
        return (<div id='divChartPerito'>  
                    <div className="e-card-resize-container">                            
                        <div className="card-layout" >
                            <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12">
                                <div className="e-card" id="basic_card" >  
                                    <div className="e-card-content">
                                        <div className="row" style={{ marginTop: '10px' }}>      
                                            <div className="col-xs-2 col-sm-4 col-lg-6 col-md-5">
                                                <span style={{ fontSize: '2.0vw', fontWeight: 'bold', color:'#133B95'}}>SCARICO PER PERITO (RE/RC)</span>
                                            </div>     
                                            <div className="col-xs-10 col-sm-8 col-lg-6 col-md-7 text-right">
                                                <span style={{ fontSize: '1.2vw', fontWeight: 'bold', color:'#133B95'}}>(posizionare il cursore sulle barre del grafico per vedere le info di dettaglio)</span>
                                            </div>                                     
                                        </div>    
                                        <div className="row">
                                            <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12">    
                                                <span id='elencoCompagnieChartPerito' style={{ fontSize: '1.3vw', fontWeight: 'bold', color:'#133B95'}}></span>
                                            </div> 
                                        </div>
                                        <div className="row">&nbsp;</div>
                                        <div className="row">
                                            <div className="col-xs-12 col-sm-3 col-md-4 col-lg-4" style={{ paddingTop: '10px', paddingBottom: '10px' }}>
                                                <MultiSelectComponent id="filtroPeritoVisual" mode="Box" fields={this.fieldsPerito} cssClass='e-outline' ref={ddlPerito => this.ddlPeritoInstance = ddlPerito} 
                                                        placeholder="Filtro Periti da visualizzare" floatLabelType='always' tagging={this.onTagging.bind(this)} select={this.onSelect.bind(this)}
                                                        allowFiltering={true} filtering={this.onFiltering} removed={this.onRemove.bind(this)} dataBound={this.onDataBound.bind(this)} />
                                            </div>  
                                            <div className="col-xs-12 col-sm-9 col-lg-8 col-md-8 text-right">
                                                <table style={{ width: '100%', border: '1px solid purple' }}>
                                                    <tbody>                                                                         
                                                        <tr>
                                                            <td align='center' style={{ width: '33%' }}>
                                                                <span id='TotaleEntratiPerito' style={{ fontSize: '1.5vw', fontWeight: 'bold', color: '#133B95' }}></span>
                                                            </td>
                                                            <td align='center' style={{ width: '33%' }}>
                                                                <span id='TotaleChiusiPerito' style={{ fontSize: '1.5vw', fontWeight: 'bold', color: '#133B95' }}></span>
                                                            </td>
                                                            <td align='center' style={{ width: '33%' }}>
                                                                <span id='ScaricoTotalePerito' style={{ fontSize: '1.5vw', fontWeight: 'bold', color: '#133B95' }}></span>
                                                            </td>                                                           
                                                        </tr>                                                                                                             
                                                        <tr>
                                                            <td align='center' style={{ width: '33%' }}>
                                                               <span id='TotaleEntratiRE' style={{ fontSize: '1.5vw', fontWeight: 'bold', color:materialColors[5%10] }}></span>
                                                            </td>
                                                            <td align='center' style={{ width: '33%' }}>
                                                               <span id='TotaleChiusiRE' style={{ fontSize: '1.5vw', fontWeight: 'bold', color:materialColors[4%10] }}></span>
                                                            </td>
                                                            <td align='center' style={{ width: '33%' }}>
                                                               <span id='ScaricoTotaleRE' style={{ fontSize: '1.5vw', fontWeight: 'bold', color: 'purple' }}></span>
                                                            </td>
                                                        </tr>       
                                                        <tr>
                                                            <td align='center' style={{ width: '33%' }}>
                                                               <span id='TotaleEntratiRC' style={{ fontSize: '1.5vw', fontWeight: 'bold', color:materialColors[7%10] }}></span>
                                                            </td>
                                                            <td align='center' style={{ width: '33%' }}>
                                                               <span id='TotaleChiusiRC' style={{ fontSize: '1.5vw', fontWeight: 'bold', color:materialColors[6%10] }}></span>
                                                            </td>
                                                            <td align='center' style={{ width: '33%' }}>
                                                               <span id='ScaricoTotaleRC' style={{ fontSize: '1.5vw', fontWeight: 'bold', color: 'purple' }}></span>
                                                            </td>
                                                        </tr>                                                         
                                                        <tr>
                                                            <td colSpan='3' align='center'>
                                                                <span id='MedieTotaliPeritoRE' style={{ fontSize: '1.5vw', fontWeight: 'bold', color:'darkslategrey'}}></span>
                                                            </td>
                                                        </tr>   
                                                        <tr>
                                                            <td colSpan='3' align='center'>
                                                                <span id='MedieTotaliPeritoRC' style={{ fontSize: '1.5vw', fontWeight: 'bold', color:'darkslategrey'}}></span>
                                                            </td>
                                                        </tr>                             
                                                    </tbody>
                                                </table>
                                            </div> 
                                        </div>
                                        <div className="row">&nbsp;</div>
                                        <div className="row">          
                                            <ChartComponent id='ChartSuddPerito' loaded={this.operazioniDopoCarica.bind(this)} style={{ textAlign: "center" }}
                                                className='col-xs-12 col-sm-12 col-lg-12 col-md-12' ref={chart => {this.chartPeritoInstance = chart;}}  
                                                primaryXAxis={{ valueType: 'Category', interval: 1, majorGridLines: { width: 0 }, labelStyle: { color: '#133B95', fontSize: '1.8vw', fontWeight: 'bolder' } }} 
                                                primaryYAxis={{ majorGridLines: { width: 0 }, majorTickLines: { width: 0 }, lineStyle: { width: 0 }, labelStyle: { color: 'transparent' } }}
                                                chartArea={{ border: { width: 0 } }} tooltip={{ enable: true, template: this.tooltipTemplate.bind(this) }} 
                                                width={CustomJS.chartWidth} 
                                                height='100%' title=''
                                                legendSettings={{ visible: false, position: 'Top' }}>
                                                <Inject services={[ColumnSeries, Legend, Tooltip, Category, DataLabel, BarSeries]}/>
                                                <SeriesCollectionDirective>                                                                                                             
                                                    <SeriesDirective dataSource={data1} xName='x' yName='y' name='Sinistri Entrati RE' type='Bar' fill={materialColors[5 % 10]} cornerRadius={{ bottomLeft: 10, bottomRight: 10, topLeft: 10, topRight: 10 }} marker={{ dataLabel: { visible: true, position: 'Top', font: { fontWeight: '600', color: '#ffffff' } } }}>
                                                    </SeriesDirective>                                                                                                          
                                                    <SeriesDirective dataSource={data3} xName='x' yName='y' name='Sinistri Entrati RC' type='Bar' fill={materialColors[7 % 10]} cornerRadius={{ bottomLeft: 10, bottomRight: 10, topLeft: 10, topRight: 10 }} marker={{ dataLabel: { visible: true, position: 'Top', font: { fontWeight: '600', color: '#ffffff' } } }}>
                                                    </SeriesDirective>
                                                </SeriesCollectionDirective>
                                            </ChartComponent>      
                                        </div>      
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>      
            </div>)
    }
    applicaFiltroPerito() {
        this.chartPeritoInstance.series[0].dataSource = filtroPeritoVisual.length === 0 ? JSON.parse(window.localStorage.getItem('ChartData1Both')) : JSON.parse(window.localStorage.getItem('ChartData1Both')).filter(function(e) { return this.indexOf(e.x) > -1; }, filtroPeritoVisual);
        this.chartPeritoInstance.series[1].dataSource = filtroPeritoVisual.length === 0 ? JSON.parse(window.localStorage.getItem('ChartData3Both')) : JSON.parse(window.localStorage.getItem('ChartData3Both')).filter(function(e) { return this.indexOf(e.x) > -1; }, filtroPeritoVisual);
        this.chartPeritoInstance.height = filtroPeritoVisual.length === 0 ? (JSON.parse(window.localStorage.getItem('ChartData1')).length * 50).toString() + '%' : (JSON.parse(window.localStorage.getItem('ChartData1')).filter(function(e) { return this.indexOf(e.x) > -1; }, filtroPeritoVisual).length * 50).toString() + '%';
    }
    operazioniDopoCarica(args) {    
        setTimeout(() => {    
            for (let ind=1;ind<=this.chartPeritoInstance.series[0].dataSource.length-1;ind+=2)
                 $(`#ChartSuddPerito_Series_0_Point_${(ind).toString()}`).attr('fill', materialColors[4 % 10]);    
            for (let ind=1;ind<=this.chartPeritoInstance.series[1].dataSource.length-1;ind+=2)
                 $(`#ChartSuddPerito_Series_1_Point_${(ind).toString()}`).attr('fill', materialColors[6 % 10]);   
        }, 500); 
    }
}