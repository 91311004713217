/* eslint-disable no-useless-constructor */
import * as React from 'react';
import { GridComponent, ColumnsDirective, ColumnDirective, Page, Inject } from '@syncfusion/ej2-react-grids';
import { SampleBase } from './sample-base';
import * as CustomJS from '../Custom';
import _ from 'underscore/underscore-min';

var dataCorrente = new Date();
var dd = String(dataCorrente.getDate()).padStart(2, '0');
let mm = String(dataCorrente.getMonth() + 1).padStart(2, '0'); //January is 0!
var yyyy = dataCorrente.getFullYear();
dataCorrente = yyyy + mm + dd;

function headerTemplate(props) {    
    return (<div>
         <span style={{ fontSize: '0.85vw', fontWeight: 'bolder'}}>{props.headerText}</span>
    </div>);
}
export default class GridTotaliSinistriEntratiChiusi extends SampleBase {    
    constructor() {
        super(...arguments);
    } 
    onQueryCellInfo(args) {
        args.cell.style.color = args.column.index === 0 ? 'black' : 'purple';
        args.cell.style.fontSize = '1.0vw';
        args.cell.style.fontWeight = 'bold'; 
        if (args.data.rowDescr.indexOf('%') > -1 && args.cell.innerText.indexOf('%') === -1)
            args.cell.innerText = parseFloat(args.cell.innerText).toLocaleString('it-IT');
        if (args.column.index === parseInt(mm))             
            args.cell.style.backgroundColor = 'gold';     
        if (args.cell.innerText === 'NaN')
            args.cell.innerText = '';    
        if (args.cell.innerText.indexOf('-') > -1)
            args.cell.style.color = 'red';   
        else if (args.data.rowDescr === 'DIFF.' && args.cell.innerText !== 'DIFF.' && args.cell.innerText !== '')         
            args.cell.innerText = '+' + args.cell.innerText;          
    }    
    onDatabound(args) {         
        for (let ind=0;ind<=12;ind++)
             document.getElementById('GridTotaliSinistriEntratiChiusi').querySelectorAll('.e-columnheader')[0].children[ind].style.backgroundColor = 'Linen';       
        document.getElementById('GridTotaliSinistriEntratiChiusi').querySelectorAll('.e-columnheader')[0].children[parseInt(mm)].style.backgroundColor = 'gold';       
    }
    render() {
        return (<div id='divGridSinistriEntratiChiusi'>
                    <div className='control-section'>
                        <GridComponent id="GridTotaliSinistriEntratiChiusi" ref={grid => this.gridInstance = grid} allowSelection={false} enableHover={false} 
                                allowPaging={false} queryCellInfo={this.onQueryCellInfo.bind(this)} dataBound={this.onDatabound.bind(this)} enableAltRow={false} 
                                allowTextWrap={true}>
                            <ColumnsDirective>
                                <ColumnDirective field='rowDescr' headerText='' width='7%' headerTemplate={headerTemplate} textAlign='Center'></ColumnDirective>
                                <ColumnDirective field='GEN' headerText='GENNAIO' headerTemplate={headerTemplate} textAlign='Center'></ColumnDirective>
                                <ColumnDirective field='FEB' headerText='FEBBRAIO' headerTemplate={headerTemplate} textAlign='Center'></ColumnDirective>
                                <ColumnDirective field='MAR' headerText='MARZO' headerTemplate={headerTemplate} textAlign='Center'></ColumnDirective>
                                <ColumnDirective field='APR' headerText='APRILE' headerTemplate={headerTemplate} textAlign='Center'></ColumnDirective>
                                <ColumnDirective field='MAG' headerText='MAGGIO' headerTemplate={headerTemplate} textAlign='Center'></ColumnDirective>
                                <ColumnDirective field='GIU' headerText='GIUGNO' headerTemplate={headerTemplate} textAlign='Center'></ColumnDirective>
                                <ColumnDirective field='LUG' headerText='LUGLIO' headerTemplate={headerTemplate} textAlign='Center'></ColumnDirective>
                                <ColumnDirective field='AGO' headerText='AGOSTO' headerTemplate={headerTemplate} textAlign='Center'></ColumnDirective>
                                <ColumnDirective field='SET' headerText='SETTEMBRE' headerTemplate={headerTemplate} textAlign='Center'></ColumnDirective>
                                <ColumnDirective field='OTT' headerText='OTTOBRE' headerTemplate={headerTemplate} textAlign='Center'></ColumnDirective>
                                <ColumnDirective field='NOV' headerText='NOVEMBRE' headerTemplate={headerTemplate} textAlign='Center'></ColumnDirective>
                                <ColumnDirective field='DIC' headerText='DICEMBRE' headerTemplate={headerTemplate} textAlign='Center'></ColumnDirective>
                            </ColumnsDirective>                        
                        </GridComponent>
                    </div>
                    <div id='waitingpopup' className='waitingpopup'>
                        <span id='gif' className='image'></span>
                    </div>
            </div>);
    }    
}    