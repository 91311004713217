/* eslint-disable default-case */
import * as React from "react";
import { GridComponent, ColumnsDirective, ColumnDirective, Toolbar, ExcelExport, Page, Filter, Inject, Sort } from '@syncfusion/ej2-react-grids';
import { SampleBase } from './sample-base';
import * as CustomJS from '../Custom';
import _ from 'underscore/underscore-min';

var dataCorrente = new Date();
var dd = String(dataCorrente.getDate()).padStart(2, '0');
var mm = String(dataCorrente.getMonth() + 1).padStart(2, '0'); //January is 0!
var yyyy = dataCorrente.getFullYear();
dataCorrente = yyyy + mm + dd;

function headerTemplate1(props) {  
    debugger;  
    let fontSize = props.field === 'sin_num' || props.field === 'gestFormaDiretta' || props.field === 'compagnia'
                                   || props.field === 'tipo_sinistro' ? '0.65vw' : props.field === 'PrimoContatto' ? '0.62vw' : '0.75vw';
    return (<div>
         <span style={{ fontSize: fontSize, fontWeight: 'bolder' }}>{props.headerText}</span>
    </div>);
}
export default class DettaglioSinistriPervenuti extends SampleBase {
    constructor() {
        super(...arguments); 
        this.toolbarSettings = ['ExcelExport', 'Search'];
        this.filterSettings = { type: 'CheckBox'}; 
    }
    state= {
    }    
    onQueryCellInfo1(args) {            
        args.cell.style.color = args.column.index === 0 ? 'black' : 'purple';   
        args.cell.style.fontSize = '0.8vw';
        args.cell.style.fontWeight = 'bold';           
        if (args.cell.innerText === 'NaN' || args.cell.innerText === '+∞')
            args.cell.innerText = '';      
    }  
    onDatabound1(args) {       
        for (let ind=0;ind<=13;ind++)
             document.getElementById('GridDettaglioSinistriPervenuti').querySelectorAll('.e-columnheader')[0].children[ind].style.backgroundColor = 'Linen';
    }    
    onToolbarClick(args) {
        switch (args.item.properties.prefixIcon) {
            case 'e-pdfexport':
                this.gridInstance1.pdfExport();
                break;
            case 'e-excelexport':
                this.gridInstance1.excelExport();
                break;
        }
    }

    render() { 
        return (<div className='container-fluid'> 
                    <div className="row" style={{ marginTop: '10px' }}>      
                        <div className="col-xs-12 col-sm-12 col-lg-10 col-md-10">
                            <span id='titoloDettaglioSinistriPervenuti' style={{ fontSize: '1.7vw', fontWeight: 'bold', color:'#133B95'}}></span>
                        </div>                          
                     </div>    
                    <div className="row">
                        <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12">    
                            <span id='elencoCompagnieDettaglioSinistriPervenuti' style={{ fontSize: '1.2vw', fontWeight: 'bold', color:'#133B95'}}></span>
                        </div> 
                    </div> 
                    <div className='row'>
                        <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12">
                            <GridComponent id="GridDettaglioSinistriPervenuti" ref={grid => this.gridInstance1 = grid} allowSelection={false} enableHover={false} 
                                    allowPaging={true} queryCellInfo={this.onQueryCellInfo1.bind(this)} dataBound={this.onDatabound1.bind(this)} enableAltRow={true} 
                                    allowTextWrap={true} pageSettings={{ pageCount: 5, pageSize: 15, pageSizes: ['15', '50', '100', '500', 'Tutti'] }} allowFiltering={true} 
                                    filterSettings={this.filterSettings} toolbar={this.toolbarSettings} allowExcelExport={true} allowSorting={true}
                                    toolbarClick={this.onToolbarClick.bind(this)}>
                                <ColumnsDirective>
                                    <ColumnDirective field='id_sinistro' headerText='ID' width='6%' headerTemplate={headerTemplate1} textAlign='Center' allowFiltering={false}></ColumnDirective>
                                    <ColumnDirective field='sin_num' headerText='Num. Sinistro Compagnia' headerTemplate={headerTemplate1} textAlign='Center' allowFiltering={false}></ColumnDirective>
                                    <ColumnDirective field='area' headerText='Area' headerTemplate={headerTemplate1} textAlign='Center'></ColumnDirective>
                                    <ColumnDirective field='stato' headerText='Stato' headerTemplate={headerTemplate1} textAlign='Center'></ColumnDirective>
                                    <ColumnDirective field='denom' headerText='Perito' headerTemplate={headerTemplate1} textAlign='Center'></ColumnDirective>
                                    <ColumnDirective field='compagnia' headerText='Compagnia' headerTemplate={headerTemplate1} textAlign='Center' allowFiltering={false}></ColumnDirective>
                                    <ColumnDirective field='tipo_sinistro' headerText='Tipo Sinistro' headerTemplate={headerTemplate1} textAlign='Center'></ColumnDirective>
                                    <ColumnDirective field='gestFormaDiretta' headerText='Gestibile Forma Diretta' headerTemplate={headerTemplate1} textAlign='Center'></ColumnDirective>
                                    <ColumnDirective field='FormaDiretta' headerText='Forma Diretta' headerTemplate={headerTemplate1} textAlign='Center'></ColumnDirective>
                                    <ColumnDirective field='PrimoContatto' headerText='Primo Contatto' headerTemplate={headerTemplate1} textAlign='Center'></ColumnDirective>
                                    <ColumnDirective field='sin_per' format='dd/MM/yyyy' headerText='Data Pervenim.' headerTemplate={headerTemplate1} textAlign='Center' allowFiltering={false}></ColumnDirective>
                                    <ColumnDirective field='data_incarico' format='dd/MM/yyyy' headerText='Data Incarico' headerTemplate={headerTemplate1} textAlign='Center' allowFiltering={false}></ColumnDirective>
                                    <ColumnDirective field='data_res' format='dd/MM/yyyy' width='8%' headerText='Data Restituzione' headerTemplate={headerTemplate1} textAlign='Center' allowFiltering={false}></ColumnDirective>
                                    <ColumnDirective field='data_chi' format='dd/MM/yyyy' headerText='Data Chiusura' headerTemplate={headerTemplate1} textAlign='Center' allowFiltering={false}></ColumnDirective>
                                </ColumnsDirective>  
                                <Inject services={[Toolbar, ExcelExport, Filter, Sort, Page]}/>                       
                            </GridComponent>
                        </div>
                    </div>                    
            </div>);
    } 
    rendereComplete() {                 
    }
    operazioniDopoCarica(args) {                
    }    
}