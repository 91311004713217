import * as React from "react";
import { ChartComponent, SeriesCollectionDirective, SeriesDirective, Inject, Legend, Category, Tooltip, ColumnSeries, DataLabel } from '@syncfusion/ej2-react-charts';
import { Browser } from '@syncfusion/ej2-base';
import { fabricColors, bootstrapColors, materialColors, highContrastColors } from './theme-color';
import { SampleBase } from './sample-base';
import * as CustomJS from '../Custom';
import _ from 'underscore/underscore-min';
import $ from 'jquery';

var dataCorrente = new Date();
var dd = String(dataCorrente.getDate()).padStart(2, '0');
var mm = String(dataCorrente.getMonth() + 1).padStart(2, '0'); //January is 0!
var yyyy = dataCorrente.getFullYear();
dataCorrente = yyyy + mm + dd;

export default class ChartSinistriEntratiChiusiDefault extends SampleBase {
    constructor() {
        super(...arguments); 
    }
    state= {
    }
    render() { 
        return (<div id='divChartSinistriEntratiChiusiDefault' className='container-fluid'> 
                    <div className="row" style={{ marginTop: '10px' }}>      
                        <div className="col-xs-2 col-sm-4 col-lg-6 col-md-5">
                            <span id='titoloChartSinistriEntratiChiusi' style={{ fontSize: '1.7vw', fontWeight: 'bold', color:'#133B95'}}></span>
                        </div>                                  
                    </div>    
                    <div className="row">
                        <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12">    
                            <span id='elencoCompagnieChartSinistriEntratiChiusiDefault' style={{ fontSize: '1.2vw', fontWeight: 'bold', color:'#133B95'}}></span>
                        </div> 
                    </div>                    
                    <div className="row">    
                        <div id='apertiDiCuiAnnoCorr' className="col-xs-12 col-sm-12 col-lg-5 col-md-5 text-center" style={{ marginTop: '5px'}}>
                            <span id='testoApertiDiCuiAnnoCorr' style={{ fontSize: '1.6vw', fontWeight: 'bolder', color:'#133B95'}}></span>
                        </div>                    
                        <div id='chiusiAnnoCorr' className="col-xs-12 col-sm-12 col-lg-5 col-md-5 text-center" style={{ marginTop: '5px'}}>
                            <span id='testoChiusiAnnoCorr' style={{ fontSize: '1.6vw', fontWeight: 'bolder', color:'#133B95'}}>CHIUSI ANNO CORRENTE:</span>
                        </div>
                        <div id='chiusiAnnoPrec' className="col-xs-12 col-sm-12 col-lg-5 col-md-5 text-right" style={{ marginTop: '5px'}}>
                            <span id='testoChiusiAnnoPrec' style={{ fontSize: '1.6vw', fontWeight: 'bolder', color:'#133B95'}}>CHIUSI ANNO PRECEDENTE:</span>
                        </div>
                    </div>      
                    <div className="row">                 
                        <ChartComponent id='ChartSinistriEntratiChiusiDefault' style={{ textAlign: "center" }} loaded={this.dopoCaricamento.bind(this)}
                            className='col-xs-12 col-sm-12 col-lg-12 col-md-12' ref={chart => {this.chartInstance1 = chart}}
                            primaryXAxis={{ valueType: 'Category', interval: 1, majorGridLines: { width: 0 } }} 
                            primaryYAxis={{ majorGridLines: { width: 0 }, majorTickLines: { width: 0 }, lineStyle: { width: 0 }, labelStyle: { color: 'transparent' } }}
                            chartArea={{ border: { width: 0 } }} tooltip={{ enable: true }} 
                            width={CustomJS.chartWidth} 
                            height={CustomJS.chartHeight} title=''
                            legendSettings={{ visible: true }}>
                            <Inject services={[ColumnSeries, Legend, Tooltip, Category, DataLabel]}/>
                            <SeriesCollectionDirective>
                                <SeriesDirective xName='x' yName='y' type='Column' cornerRadius={{ bottomLeft: 10, bottomRight: 10, topLeft: 10, topRight: 10 }} marker={{ dataLabel: { visible: true, position: 'Top', font: { fontWeight: '600', color: '#ffffff' } } }}>
                                </SeriesDirective>
                                <SeriesDirective xName='x' yName='y' type='Column' cornerRadius={{ bottomLeft: 10, bottomRight: 10, topLeft: 10, topRight: 10 }} marker={{ dataLabel: { visible: true, position: 'Top', font: { fontWeight: '600', color: '#ffffff' } } }}>
                                </SeriesDirective>                                                                             
                            </SeriesCollectionDirective>
                        </ChartComponent>      
                    </div>         
            </div>);
    } 
    rendereComplete() {
                 
    }
    dopoCaricamento(args) {  
        setTimeout(() => { $(`#ChartSinistriEntratiChiusiDefault_Series_0_Point_${parseInt(mm).toString()}`).attr('fill', '#133B95'); }, 100);   
        for (let ind=0;ind<=parseInt(mm)-1;ind++)
             $(`#ChartSinistriEntratiChiusiDefault_Series_1_Point_${(ind+12).toString()}`).attr('fill', '#D2691E');
        if ($('#legendaCustom').length > 0) return;
        let fontSizeLegCust = '1.0vw'; 
        $(`<div id="legendaCustom"><span id="testoLegendaCustom" style="font-size:${fontSizeLegCust};font-weight:bold;color:#133B95";></span></div>`).appendTo('#divChartSinistriEntratiChiusiDefault');        
        let d = document.getElementById('legendaCustom');
        d.style.position = "absolute";
        d.style.left = '10px';
        let topPosLegCust = '785';
        topPosLegCust = (parseInt(topPosLegCust) - ((1080 - CustomJS.heightScreenResolution) === 0 ? 0 : (1080 - CustomJS.heightScreenResolution) < 0 ? (1080 - CustomJS.heightScreenResolution) : (1080 - CustomJS.heightScreenResolution + 50))).toString();
        d.style.top = Browser.isDevice ? '625px' : topPosLegCust + 'px';   
        $(`<div id="legendaCustom2"><span id="testoLegendaCustom2" style="font-size:${fontSizeLegCust};font-weight:bold;color:#D2691E";></span></div>`).appendTo('#divChartSinistriEntratiChiusiDefault');        
        let e = document.getElementById('legendaCustom2');
        e.style.position = "absolute";
        e.style.left = '10px';
        topPosLegCust = '785';
        topPosLegCust = (parseInt(topPosLegCust) - ((1080 - CustomJS.heightScreenResolution) === 0 ? 0 : (1080 - CustomJS.heightScreenResolution) < 0 ? (1080 - CustomJS.heightScreenResolution) : (1080 - CustomJS.heightScreenResolution + 50))).toString();
        e.style.top = Browser.isDevice ? '625px' : topPosLegCust + 'px';    
    }
}