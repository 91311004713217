/* eslint-disable no-loop-func */
/* eslint-disable no-extend-native */
import * as React from 'react';
import ReactDOM from 'react-dom';
import { MultiSelectComponent, DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import { DateRangePickerComponent } from '@syncfusion/ej2-react-calendars';
import ChartSuddivisioneArea from './ChartSuddivisioneArea';
import ChartSuddivisionePerito from './ChartSuddivisionePerito';
import ChartSuddivisioneProvReg from './ChartSuddivisioneProvinciaRegione';
import ChartSinistriEntratiChiusiDefault from './ChartSinistriEntratiChiusiDefault';
import PaginaSinistriPervenuti from './PaginaSinistriPervenuti';
import GridTotaliSinistriChiusi from './GridTotaliSinistriChiusi';
import GridTotaliSinistriEntratiChiusi from './GridTotaliSinistriEntratiChiusi';
import GridSecondoPeritoSurveyor from './GridSecondoPeritoSurveyor';
import PaginaParcellaMedia from './PaginaParcellaMedia';
import MappaSinistri from './MappaSinistri';
import PaginaDettaglioSinistriPervenuti from './PaginaDettaglioSinistriPervenuti';
import { Query } from '@syncfusion/ej2-data';
import { fabricColors, bootstrapColors, materialColors, highContrastColors } from './theme-color';
import { ButtonComponent, CheckBoxComponent } from '@syncfusion/ej2-react-buttons';
import { SampleBase } from './sample-base';
import { Browser, isNullOrUndefined } from '@syncfusion/ej2-base';
import * as CustomJS from '../Custom';
import { random, isUndefined } from 'underscore';
import _ from 'underscore/underscore-min';
import $ from 'jquery';
import BlockUi from 'react-block-ui';
import 'react-block-ui/style.css';
import { ToastContainer, toast, Slide, Zoom, Flip, Bounce } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import RegioniProvince from '../assets/RegioniProvince.json';
import moment from 'moment';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";

Array.prototype.sum = function (prop) {
    let total = 0
    for ( let i = 0, _len = this.length; i < _len; i++ ) {
        total += this[i][prop];
    }
    return total;
}

Array.prototype.mediaPonderataMedie = function (campoMedia, campoNumerosita) {
    let mediaTotale = 0, pesoTotale = 0, numerositaTotale = 0;
    for ( var i = 0, _len = this.length; i < _len; i++ ) {
          pesoTotale += (this[i][campoMedia] * this[i][campoNumerosita]);
          //media = Math.round(((media * numerosita) + (this[i][campoMedia] * this[i][campoNumerosita]) / (numerosita + this[i][campoNumerosita])) * 100) / 100;
          numerositaTotale += this[i][campoNumerosita]; 
    }   
    mediaTotale = numerositaTotale === 0 ? 0 : pesoTotale / numerositaTotale;    
    return Math.round(mediaTotale * 100) / 100;
}

Array.prototype.mediaStandard = function (campoMedia) {
    let mediaTotale = 0, pesoTotale = 0, numerositaTotale = 0;
    for ( var i = 0, _len = this.length; i < _len; i++ ) {         
          pesoTotale += isNaN(this[i][campoMedia]) ? 0 : this[i][campoMedia];
          numerositaTotale++; 
    }   
    mediaTotale = numerositaTotale === 0 ? 0 : pesoTotale / numerositaTotale;
    return Math.round(mediaTotale * 100) / 100;
}

let dataCorr = new Date();
let CompagnieVisual = CustomJS.compagnieVisualDefault, Statistiche = CustomJS.statisticheDefault;
let dataInizioAnno = '1/1/' + dataCorr.getFullYear().toString();
let dataInizioSecolo = '1/1/2000';
let dataCorrenteDRP = new Date().toDateString();
let data1 = [];
let data2 = [];
let totChiusiAnnoCorr = 0;
let totChiusiAnnoPrec = 0;
let totChiusiStessoGGAnnoPrec = 0;
let dataCorrente = new Date();
let annoPrec = (new Date().getFullYear() - 1).toString();
let dd = String(dataCorrente.getDate()).padStart(2, '0');
let mm = String(dataCorrente.getMonth() + 1).padStart(2, '0'); //January is 0!
let yyyy = dataCorrente.getFullYear();
let dataCorrAnnoPrec = (yyyy - 1).toString() + mm + dd;
dataCorrente = yyyy + mm + dd;

export default class ParametriSelezione extends SampleBase {
    constructor() {
        super(...arguments); 
        this.fieldsComp = { text: 'DescrizioneCompagnia', value: 'IdCompagnia' }; 
        this.fieldsStat = { text: 'Text', value: 'Value' }; 
        this.statistiche = Statistiche;     
        this.toggleBlocking = this.toggleBlocking.bind(this);
        this.loaderType = ['Ball-Triangle', 'Bars', 'Circles', 'Grid', 'Puff', 'Rings', 'TailSpin', 'ThreeDots', 'Oval', 'MutatingDots', 'RevolvingDot'];
        this.toasts = [{ title: 'Attenzione!', content: 'Nessun dato estratto con i parametri selezionati!!', cssClass: 'e-toast-warning', timeOut: 4000, icon: 'e-warning toast-icons' },
                       { title: 'Success!', content: '', cssClass: 'e-toast-success', icon: 'e-success toast-icons', timeOut: 4000 },
                       { title: 'Error!', content: '', cssClass: 'e-toast-danger', icon: 'e-error toast-icons', timeOut: 4000 },
                       { title: 'Informazione!', content: 'Nessun dato estratto con i parametri selezionati!!', cssClass: 'e-toast-info', icon: 'e-info toast-icons', timeOut: 4000 }];
        this.position = { X: 'Center', Y: 'Top' };
        this.childKey = 0;
        this.checkSel1 = [];
        this.checkSel2 = [];
    }    
    state = {
        blocking: false        
    } 
    toggleBlocking() {        
        this.setState({blocking: !this.state.blocking});
    }        
    onTagging(e) {
       e.setClass(CustomJS.colorsData[random(26)].Color.toLowerCase());
    }  
    onFiltering(e) {              
        let query = new Query();
        query = (e.text !== "") ? query.where("DescrizioneCompagnia", "contains", e.text, true) : query;     
        e.updateData(this.dataSource, query);
    }
    onChangeTutteComp(args) {
        if (args.checked === true) 
            this.ddlCompInstance.selectAll(true);        
        else
            this.ddlCompInstance.selectAll(false);
    }
    onChangeTipoStat(args) {
        if (args.value === 11)
            this.dateRangeInstance.min = new Date(dataInizioSecolo);
        else {
            this.dateRangeInstance.min = new Date(dataInizioAnno);   
            this.dateRangeInstance.startDate= new Date(dataInizioAnno);
            this.dateRangeInstance.endDate = new Date(dataCorrenteDRP);
        }     
    }
    onRemoved(args) {
        this.chkboxInstance1.checked = false;
    }
    render() {
        return (<React.Fragment>
                   <BlockUi id='blockUI' tag='div' blocking={this.state.blocking} loader={<Loader width={100} height={100} type={this.loaderType[random(this.loaderType.length-1)]} color='#133B95' /> }>
                        <div className='control-section card-control-section basic_card_layout'>                                                
                            <div className="e-card-resize-container">                                
                                <div className="card-layout" >
                                    <ToastContainer containerId='toastContainer1' transition={Zoom} style={{ fontSize: '1.1vw', color: 'purple', fontWeight: 'bold', width: '25%' }}></ToastContainer>
                                    <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12">
                                        <div className="e-card" id="basic_card" >                                        
                                            <div className="e-card-header">                                        
                                                <div className="e-card-header-caption">
                                                    <div className="e-card-title" style={{ fontSize: '1.2em', color:'#133B95', fontWeight: 'bold' }}>PARAMETRI SELEZIONE</div>                                                                                
                                                </div>
                                            </div>
                                            <div className="e-card-content">
                                                <div className='row'>
                                                    <div className="col-xs-12 col-sm-12 col-md-11 col-lg-11" style={{ paddingTop: '10px', paddingBottom: '10px' }}>
                                                        <MultiSelectComponent id="compagnieSelezionate" mode="Box" fields={this.fieldsComp} cssClass='e-outline' ref={ddlComp => this.ddlCompInstance = ddlComp} 
                                                                placeholder="Compagnie" floatLabelType='always' tagging={this.onTagging.bind(this)} allowFiltering={true} filtering={this.onFiltering} 
                                                                removed={this.onRemoved.bind(this)}  />
                                                    </div>
                                                    <div className="col-xs-2 col-sm-2 col-md-1 col-lg-1" style={{ paddingTop: '12px', paddingBottom: '10px' }}>
                                                        <CheckBoxComponent label='Tutte le compagnie' ref={chkbox => this.chkboxInstance1 = chkbox} change={this.onChangeTutteComp.bind(this)}></CheckBoxComponent>
                                                    </div>
                                                </div>
                                                <div className='row'>&nbsp;</div>
                                                <div className='row'>
                                                    <div className="col-xs-7 col-sm-5 col-md-4 col-lg-3">
                                                        <DateRangePickerComponent id='RangeDateEstrazione1' ref={drp => this.dateRangeInstance = drp} readOnly={window.localStorage.getItem("IdReferente") !== 'ADM'} startDate={new Date(dataInizioAnno)} cssClass='e-outline' format='dd/MM/yyyy'
                                                        allowEdit={false} endDate={new Date(dataCorrenteDRP)} showClearButton={false} placeholder='Data estrazione (Da - A)' floatLabelType='always' min={new Date(dataInizioAnno)}></DateRangePickerComponent>
                                                    </div>   
                                                    <div  className="col-xs-5 col-sm-5 col-md-5 col-lg-5">
                                                        <DropDownListComponent id="TipoStatistica" ref={ddlStat => this.ddlStatInstance = ddlStat} dataSource={this.statistiche} fields={this.fieldsStat}
                                                            placeholder="Selezionare il tipo di statistica richiesta" floatLabelType='always' cssClass='e-outline' popupHeight="400px"
                                                            change={this.onChangeTipoStat.bind(this)}/>    
                                                    </div>                                             
                                                </div>
                                                <div className='row'>&nbsp;</div>
                                                <div className='row'>
                                                    <div className="col-xs-4 col-sm-4 col-lg-2 col-md-3">   
                                                        <ButtonComponent id='BtnParametriSelez' alt='Nascondi parametri di selezione' cssClass='e-success' style={{height: '35px', verticalAlign: 'baseline'}} 
                                                                onClick={this.clickBottoneGeneraStat.bind(this)}>Genera Statistica</ButtonComponent>
                                                    </div>
                                                </div>
                                            </div> 
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>    
                    </BlockUi>
                    <br/>  
            </React.Fragment>);
    }
    clickBottoneGeneraStat(args) {  
        if (!CustomJS.isNullEmpty(this.ddlStatInstance.value) && !CustomJS.isNullEmpty(this.ddlCompInstance.value)) {
            CompagnieVisual = this.ddlCompInstance.value;  
            this.toggleBlocking();         
            caricaDati(this.ddlStatInstance.value, this);             
        }
    }
    componentDidMount() {
        CustomJS.getRemoteData2(CustomJS.APIUrlProd,
            `token=${CustomJS.tokenProd}&azione=compagnie&AnnoPrec=true`, 
            'POST', "application/x-www-form-urlencoded", (retData) => {  
                if (window.localStorage.getItem("IdReferente") !== 'ADM' && window.localStorage.getItem("IdReferente") !== 'REFZONA') 
                    retData = _.filter(retData, elm => { return !CustomJS.isNullEmpty(_.find(JSON.parse(window.localStorage.getItem("CompagnieReferente")), elm2 => { return elm2 ===  elm.IdCompagnia })) });
                this.ddlCompInstance.dataSource = retData;  
                this.ddlCompInstance.value = CompagnieVisual;                
        }, true, false);
        document.getElementById('accordionStatistiche').ej2_instances[0].enableItem(1, false);
        if (window.localStorage.getItem("IdReferente") !== 'ADM' && window.localStorage.getItem("IdReferente") !== 'REFZONA') {
            this.ddlStatInstance.dataSource = this.ddlStatInstance.dataSource.filter(elm => elm.Value !== 10 && elm.Value !== 11);
        }
        else if (window.localStorage.getItem("IdReferente") === 'REFZONA') {
            this.ddlStatInstance.dataSource = this.ddlStatInstance.dataSource.filter(elm => elm.Value !== 1 && elm.Value !== 5 && elm.Value !== 6 && elm.Value !== 8 && elm.Value !== 10 && elm.Value !== 11);
        }
        window.localStorage.setItem("checkSemplice", 'true');
        window.localStorage.setItem("checkIntermedio", 'true');
        window.localStorage.setItem("checkComplesso", 'true');
        window.localStorage.setItem("checkRE", 'true');
        window.localStorage.setItem("checkRC", 'true');
    }
}

function caricaDatiSinistriChiusi(thisClone) {
    CustomJS.getRemoteData2(CustomJS.APIUrlProd,
        `token=${CustomJS.tokenProd}&azione=chiusi&dataDa=${annoPrec}0101&dataA=${dataCorrente}&frequenza=mese&compagnie=${CompagnieVisual.join(',')}`, 
        'POST', "application/x-www-form-urlencoded", (retData) => {
            data1 = []; data2 = []; totChiusiAnnoPrec = 0; totChiusiAnnoCorr = 0;
            let groupedArr = _.groupBy(retData, 'periodo');            
            for (let ind=1; ind<=12;ind++) { 
                 let MONTH = _.filter(Object.values(groupedArr), (obj) => { return !CustomJS.isNullEmpty(_.find(obj, obj2 => { return obj2.periodo === yyyy.toString() + ind.toString().padStart(2,'0') || obj2.periodo === annoPrec + ind.toString().padStart(2,'0')}))});
                 data1.push({ x: ind === 1 ? 'GEN' : ind === 2 ? 'FEB' : ind === 3 ? 'MAR' : ind === 4 ? 'APR' : ind === 5 ? 'MAG' : ind === 6 ? 'GIU' 
                               : ind === 7 ? 'LUG' : ind === 8 ? 'AGO' : ind === 9 ? 'SET' : ind === 10 ? 'OTT' : ind === 11 ? 'NOV' : 'DIC', y: MONTH[0]?.sum('chiusi') }); 
                 data2.push({ x: ind === 1 ? 'GEN' : ind === 2 ? 'FEB' : ind === 3 ? 'MAR' : ind === 4 ? 'APR' : ind === 5 ? 'MAG' : ind === 6 ? 'GIU' 
                               : ind === 7 ? 'LUG' : ind === 8 ? 'AGO' : ind === 9 ? 'SET' : ind === 10 ? 'OTT' : ind === 11 ? 'NOV' : 'DIC', y: MONTH[1]?.sum('chiusi') });            
                 if (ind.toString().padStart(2,'0') === mm) {
                     CustomJS.getRemoteData3(CustomJS.APIUrlProd, 
                         { "token": CustomJS.tokenProd, "azione": "chiusi", "dataDa": annoPrec + mm + '01', "dataA": dataCorrAnnoPrec, "frequenza": "mese", "compagnie": CompagnieVisual.join(',') },
                         'POST', { "Content-Type": "application/x-www-form-urlencoded" }, (retData2) => {                             
                             let groupedArr = _.groupBy(retData2, 'periodo');                          
                             totChiusiStessoGGAnnoPrec = CustomJS.isNullEmpty(Object.values(groupedArr)[0]) ? 0 : Object.values(groupedArr)[0]?.sum('chiusi');                                               
                             let ind = new Date().getMonth() + 1;
                             data1.push({ x: ind === 1 ? 'GEN' : ind === 2 ? 'FEB' : ind === 3 ? 'MAR' : ind === 4 ? 'APR' : ind === 5 ? 'MAG' : ind === 6 ? 'GIU' 
                                           : ind === 7 ? 'LUG' : ind === 8 ? 'AGO' : ind === 9 ? 'SET' : ind === 10 ? 'OTT' : ind === 11 ? 'NOV' : 'DIC', y: Object.values(groupedArr)[0]?.sum('chiusi') });
                    }, false, false);
                }
            }         
            data1.forEach((element,idx) => {     
                if (idx !== parseInt(mm)) 
                    totChiusiAnnoPrec += isNaN(element.y) ? 0 : element.y;
            });
            data2.forEach((element,idx) => {            
                totChiusiAnnoCorr += isNaN(element.y) ? 0 : element.y;
            });   
            document.getElementById('accordionStatistiche').ej2_instances[0].enableItem(1, true);                  
            document.getElementById('accordionStatistiche').ej2_instances[0].expandItem(false, 0);
            for (let ind=0;ind<=200000000;ind++) {};
            document.getElementById('accordionStatistiche').ej2_instances[0].expandItem(true, 1);              
            setTimeout(() => {
                ReactDOM.render(<React.Fragment><ChartSinistriEntratiChiusiDefault /><GridTotaliSinistriChiusi /></React.Fragment>, 
                    document.getElementById('contenutoDinamicoStatistica'), () => {
                        document.getElementById('ChartSinistriEntratiChiusiDefault').ej2_instances[0].series[0].dataSource = data1;
                        document.getElementById('ChartSinistriEntratiChiusiDefault').ej2_instances[0].series[1].dataSource = data2; 
                        document.getElementById('ChartSinistriEntratiChiusiDefault').ej2_instances[0].series[0].fill = materialColors[8 % 10];
                        document.getElementById('ChartSinistriEntratiChiusiDefault').ej2_instances[0].series[1].fill = materialColors[9 % 10];
                        document.getElementById('ChartSinistriEntratiChiusiDefault').ej2_instances[0].series[0].name = `Anno ${annoPrec}`;
                        document.getElementById('ChartSinistriEntratiChiusiDefault').ej2_instances[0].series[1].name = `Anno ${yyyy}`;
                    });
            },300);        
            setTimeout(() => {                 
                $(`#ChartSinistriChiusi_Series_0_Point_${parseInt(mm).toString()}`).attr('fill', '#133B95');
                $('#apertiDiCuiAnnoCorr').hide();
                $('#legendaCustom').show();
                $('#legendaCustom2').hide();
                $('#chiusiAnnoCorr').show();
                $('#chiusiAnnoPrec').show();
                document.getElementById('titoloChartSinistriEntratiChiusi').innerText = 'SINISTRI CHIUSI ANNO CORRENTE/ANNO PRECEDENTE';
                document.getElementById('testoLegendaCustom').innerText = `Chiusi da inizio stesso mese e fino a stesso giorno anno precedente : ${totChiusiStessoGGAnnoPrec.toLocaleString('it-IT')}`;
                document.getElementById('testoChiusiAnnoCorr').innerText = `CHIUSI ANNO CORRENTE: ${totChiusiAnnoCorr.toLocaleString('it-IT')}`;
                document.getElementById('testoChiusiAnnoPrec').innerText = `CHIUSI ANNO PRECEDENTE: ${totChiusiAnnoPrec.toLocaleString('it-IT')}`;
                document.getElementById('elencoCompagnieChartSinistriEntratiChiusiDefault').innerHTML = `Compagnia/e: ${document.getElementById('compagnieSelezionate').ej2_instances[0].text.replace(/,/g, ', ')}`;               
            }, 600);             
            CustomJS.getRemoteData2(CustomJS.APIUrlProd,
                `token=${CustomJS.tokenProd}&azione=chiusi&dataDa=${annoPrec}0101&dataA=${dataCorrente}&frequenza=mese&compagnie=${CompagnieVisual.join(',')}`, 
                'POST', "application/x-www-form-urlencoded", (retData) => {   
                    let GridData = [];
                    let groupedArr = _.groupBy(retData, 'periodo');                                          
                    let arrAnnoprec = _.filter(Object.values(groupedArr), (obj) => { return !CustomJS.isNullEmpty(_.find(obj, obj2 => { return obj2.periodo.indexOf(annoPrec) > -1; }))});
                    GridData.push({ rowDescr: annoPrec, GEN: arrAnnoprec[0]?.sum('chiusi'), FEB: arrAnnoprec[1]?.sum('chiusi'), MAR: arrAnnoprec[2]?.sum('chiusi'), APR: arrAnnoprec[3]?.sum('chiusi'),
                                    MAG: arrAnnoprec[4]?.sum('chiusi'), GIU: arrAnnoprec[5]?.sum('chiusi'), LUG: arrAnnoprec[6]?.sum('chiusi'), AGO: arrAnnoprec[7]?.sum('chiusi'), SET: arrAnnoprec[8]?.sum('chiusi'), 
                                    OTT: arrAnnoprec[9]?.sum('chiusi'), NOV: arrAnnoprec[10]?.sum('chiusi'), DIC: arrAnnoprec[11]?.sum('chiusi')});  
                    let arrAnnoCorr = _.filter(Object.values(groupedArr), (obj) => { return !CustomJS.isNullEmpty(_.find(obj, obj2 => { return obj2.periodo.indexOf(yyyy) > -1; }))});
                    GridData.push({ rowDescr: yyyy, GEN: arrAnnoCorr[0]?.sum('chiusi'), FEB: arrAnnoCorr[1]?.sum('chiusi'), MAR: arrAnnoCorr[2]?.sum('chiusi'), APR: arrAnnoCorr[3]?.sum('chiusi'),
                                    MAG: arrAnnoCorr[4]?.sum('chiusi'), GIU: arrAnnoCorr[5]?.sum('chiusi'), LUG: arrAnnoCorr[6]?.sum('chiusi'), AGO: arrAnnoCorr[7]?.sum('chiusi'), SET: arrAnnoCorr[8]?.sum('chiusi'), 
                                    OTT: arrAnnoCorr[9]?.sum('chiusi'), NOV: arrAnnoCorr[10]?.sum('chiusi'), DIC: arrAnnoCorr[11]?.sum('chiusi')});  
                    GridData.push({ rowDescr: 'DIFF.', GEN: arrAnnoCorr[0]?.sum('chiusi') - arrAnnoprec[0]?.sum('chiusi'), FEB: arrAnnoCorr[1]?.sum('chiusi') - arrAnnoprec[1]?.sum('chiusi'), 
                                    MAR: arrAnnoCorr[2]?.sum('chiusi') - arrAnnoprec[2]?.sum('chiusi'), APR: arrAnnoCorr[3]?.sum('chiusi') - arrAnnoprec[3]?.sum('chiusi'),
                                    MAG: arrAnnoCorr[4]?.sum('chiusi') - arrAnnoprec[4]?.sum('chiusi'), GIU: arrAnnoCorr[5]?.sum('chiusi') - arrAnnoprec[5]?.sum('chiusi'), 
                                    LUG: arrAnnoCorr[6]?.sum('chiusi') - arrAnnoprec[6]?.sum('chiusi'), AGO: arrAnnoCorr[7]?.sum('chiusi') - arrAnnoprec[7]?.sum('chiusi'), 
                                    SET: arrAnnoCorr[8]?.sum('chiusi') - arrAnnoprec[8]?.sum('chiusi'), OTT: arrAnnoCorr[9]?.sum('chiusi') - arrAnnoprec[9]?.sum('chiusi'),
                                    NOV: arrAnnoCorr[10]?.sum('chiusi') - arrAnnoprec[10]?.sum('chiusi'), DIC: arrAnnoCorr[11]?.sum('chiusi') - arrAnnoprec[11]?.sum('chiusi')});         
                    GridData.push({ rowDescr: '%', 
                                    GEN: parseFloat(((arrAnnoCorr[0]?.sum('chiusi') - arrAnnoprec[0]?.sum('chiusi')) / arrAnnoCorr[0]?.sum('chiusi')) * 100).toFixed(2), 
                                    FEB: parseFloat((((arrAnnoCorr[0]?.sum('chiusi') + arrAnnoCorr[1]?.sum('chiusi')) - (arrAnnoprec[0]?.sum('chiusi') + arrAnnoprec[1]?.sum('chiusi'))) 
                                                    / (arrAnnoCorr[0]?.sum('chiusi') + arrAnnoCorr[1]?.sum('chiusi'))) * 100).toFixed(2), 
                                    MAR: parseFloat((((arrAnnoCorr[0]?.sum('chiusi') + arrAnnoCorr[1]?.sum('chiusi') + arrAnnoCorr[2]?.sum('chiusi')) 
                                                    - (arrAnnoprec[0]?.sum('chiusi') + arrAnnoprec[1]?.sum('chiusi') + arrAnnoprec[2]?.sum('chiusi'))) 
                                                    / (arrAnnoCorr[0]?.sum('chiusi') + arrAnnoCorr[1]?.sum('chiusi') + arrAnnoCorr[2]?.sum('chiusi'))) * 100).toFixed(2), 
                                    APR: parseFloat((((arrAnnoCorr[0]?.sum('chiusi') + arrAnnoCorr[1]?.sum('chiusi') + arrAnnoCorr[2]?.sum('chiusi') + arrAnnoCorr[3]?.sum('chiusi')) 
                                                    - (arrAnnoprec[0]?.sum('chiusi') + arrAnnoprec[1]?.sum('chiusi') + arrAnnoprec[2]?.sum('chiusi') + arrAnnoprec[3]?.sum('chiusi'))) 
                                                    / (arrAnnoCorr[0]?.sum('chiusi') + arrAnnoCorr[1]?.sum('chiusi') + arrAnnoCorr[2]?.sum('chiusi') + arrAnnoCorr[3]?.sum('chiusi'))) * 100).toFixed(2),
                                    MAG: parseFloat((((arrAnnoCorr[0]?.sum('chiusi') + arrAnnoCorr[1]?.sum('chiusi') + arrAnnoCorr[2]?.sum('chiusi') + arrAnnoCorr[3]?.sum('chiusi') + arrAnnoCorr[4]?.sum('chiusi')) 
                                                    - (arrAnnoprec[0]?.sum('chiusi') + arrAnnoprec[1]?.sum('chiusi') + arrAnnoprec[2]?.sum('chiusi') + arrAnnoprec[3]?.sum('chiusi') + arrAnnoprec[4]?.sum('chiusi'))) 
                                                    / (arrAnnoCorr[0]?.sum('chiusi') + arrAnnoCorr[1]?.sum('chiusi') + arrAnnoCorr[2]?.sum('chiusi') + arrAnnoCorr[3]?.sum('chiusi') + arrAnnoCorr[4]?.sum('chiusi'))) * 100).toFixed(2),
                                    GIU: parseFloat((((arrAnnoCorr[0]?.sum('chiusi') + arrAnnoCorr[1]?.sum('chiusi') + arrAnnoCorr[2]?.sum('chiusi') + arrAnnoCorr[3]?.sum('chiusi') + arrAnnoCorr[4]?.sum('chiusi') + arrAnnoCorr[5]?.sum('chiusi')) 
                                                    - (arrAnnoprec[0]?.sum('chiusi') + arrAnnoprec[1]?.sum('chiusi') + arrAnnoprec[2]?.sum('chiusi') + arrAnnoprec[3]?.sum('chiusi') + arrAnnoprec[4]?.sum('chiusi') + arrAnnoprec[5]?.sum('chiusi'))) 
                                                    / (arrAnnoCorr[0]?.sum('chiusi') + arrAnnoCorr[1]?.sum('chiusi') + arrAnnoCorr[2]?.sum('chiusi') + arrAnnoCorr[3]?.sum('chiusi') + arrAnnoCorr[4]?.sum('chiusi') + arrAnnoCorr[5]?.sum('chiusi'))) * 100).toFixed(2), 
                                    LUG: parseFloat((((arrAnnoCorr[0]?.sum('chiusi') + arrAnnoCorr[1]?.sum('chiusi') + arrAnnoCorr[2]?.sum('chiusi') + arrAnnoCorr[3]?.sum('chiusi') + arrAnnoCorr[4]?.sum('chiusi') + arrAnnoCorr[5]?.sum('chiusi') + arrAnnoCorr[6]?.sum('chiusi')) 
                                                    - (arrAnnoprec[0]?.sum('chiusi') + arrAnnoprec[1]?.sum('chiusi') + arrAnnoprec[2]?.sum('chiusi') + arrAnnoprec[3]?.sum('chiusi') + arrAnnoprec[4]?.sum('chiusi') + arrAnnoprec[5]?.sum('chiusi') + arrAnnoprec[6]?.sum('chiusi'))) 
                                                    / (arrAnnoCorr[0]?.sum('chiusi') + arrAnnoCorr[1]?.sum('chiusi') + arrAnnoCorr[2]?.sum('chiusi') + arrAnnoCorr[3]?.sum('chiusi') + arrAnnoCorr[4]?.sum('chiusi') + arrAnnoCorr[5]?.sum('chiusi') + arrAnnoCorr[6]?.sum('chiusi'))) * 100).toFixed(2), 
                                    AGO: parseFloat((((arrAnnoCorr[0]?.sum('chiusi') + arrAnnoCorr[1]?.sum('chiusi') + arrAnnoCorr[2]?.sum('chiusi') + arrAnnoCorr[3]?.sum('chiusi') + arrAnnoCorr[4]?.sum('chiusi') + arrAnnoCorr[5]?.sum('chiusi') + arrAnnoCorr[6]?.sum('chiusi') + arrAnnoCorr[7]?.sum('chiusi')) 
                                                    - (arrAnnoprec[0]?.sum('chiusi') + arrAnnoprec[1]?.sum('chiusi') + arrAnnoprec[2]?.sum('chiusi') + arrAnnoprec[3]?.sum('chiusi') + arrAnnoprec[4]?.sum('chiusi') + arrAnnoprec[5]?.sum('chiusi') + arrAnnoprec[6]?.sum('chiusi') + arrAnnoprec[7]?.sum('chiusi'))) 
                                                    / (arrAnnoCorr[0]?.sum('chiusi') + arrAnnoCorr[1]?.sum('chiusi') + arrAnnoCorr[2]?.sum('chiusi') + arrAnnoCorr[3]?.sum('chiusi') + arrAnnoCorr[4]?.sum('chiusi') + arrAnnoCorr[5]?.sum('chiusi') + arrAnnoCorr[6]?.sum('chiusi') + arrAnnoCorr[7]?.sum('chiusi'))) * 100).toFixed(2), 
                                    SET: parseFloat((((arrAnnoCorr[0]?.sum('chiusi') + arrAnnoCorr[1]?.sum('chiusi') + arrAnnoCorr[2]?.sum('chiusi') + arrAnnoCorr[3]?.sum('chiusi') + arrAnnoCorr[4]?.sum('chiusi') + arrAnnoCorr[5]?.sum('chiusi') + arrAnnoCorr[6]?.sum('chiusi') + arrAnnoCorr[7]?.sum('chiusi') + arrAnnoCorr[8]?.sum('chiusi')) 
                                                    - (arrAnnoprec[0]?.sum('chiusi') + arrAnnoprec[1]?.sum('chiusi') + arrAnnoprec[2]?.sum('chiusi') + arrAnnoprec[3]?.sum('chiusi') + arrAnnoprec[4]?.sum('chiusi') + arrAnnoprec[5]?.sum('chiusi') + arrAnnoprec[6]?.sum('chiusi') + arrAnnoprec[7]?.sum('chiusi') + arrAnnoprec[8]?.sum('chiusi'))) 
                                                    / (arrAnnoCorr[0]?.sum('chiusi') + arrAnnoCorr[1]?.sum('chiusi') + arrAnnoCorr[2]?.sum('chiusi') + arrAnnoCorr[3]?.sum('chiusi') + arrAnnoCorr[4]?.sum('chiusi') + arrAnnoCorr[5]?.sum('chiusi') + arrAnnoCorr[6]?.sum('chiusi') + arrAnnoCorr[7]?.sum('chiusi') + arrAnnoCorr[8]?.sum('chiusi'))) * 100).toFixed(2), 
                                    OTT: parseFloat((((arrAnnoCorr[0]?.sum('chiusi') + arrAnnoCorr[1]?.sum('chiusi') + arrAnnoCorr[2]?.sum('chiusi') + arrAnnoCorr[3]?.sum('chiusi') + arrAnnoCorr[4]?.sum('chiusi') + arrAnnoCorr[5]?.sum('chiusi') + arrAnnoCorr[6]?.sum('chiusi') + arrAnnoCorr[7]?.sum('chiusi') + arrAnnoCorr[8]?.sum('chiusi') + arrAnnoCorr[9]?.sum('chiusi')) 
                                                    - (arrAnnoprec[0]?.sum('chiusi') + arrAnnoprec[1]?.sum('chiusi') + arrAnnoprec[2]?.sum('chiusi') + arrAnnoprec[3]?.sum('chiusi') + arrAnnoprec[4]?.sum('chiusi') + arrAnnoprec[5]?.sum('chiusi') + arrAnnoprec[6]?.sum('chiusi') + arrAnnoprec[7]?.sum('chiusi') + arrAnnoprec[8]?.sum('chiusi') + arrAnnoprec[9]?.sum('chiusi'))) 
                                                    / (arrAnnoCorr[0]?.sum('chiusi') + arrAnnoCorr[1]?.sum('chiusi') + arrAnnoCorr[2]?.sum('chiusi') + arrAnnoCorr[3]?.sum('chiusi') + arrAnnoCorr[4]?.sum('chiusi') + arrAnnoCorr[5]?.sum('chiusi') + arrAnnoCorr[6]?.sum('chiusi') + arrAnnoCorr[7]?.sum('chiusi') + arrAnnoCorr[8]?.sum('chiusi') + arrAnnoCorr[9]?.sum('chiusi'))) * 100).toFixed(2),
                                    NOV: parseFloat((((arrAnnoCorr[0]?.sum('chiusi') + arrAnnoCorr[1]?.sum('chiusi') + arrAnnoCorr[2]?.sum('chiusi') + arrAnnoCorr[3]?.sum('chiusi') + arrAnnoCorr[4]?.sum('chiusi') + arrAnnoCorr[5]?.sum('chiusi') + arrAnnoCorr[6]?.sum('chiusi') + arrAnnoCorr[7]?.sum('chiusi') + arrAnnoCorr[8]?.sum('chiusi') + arrAnnoCorr[9]?.sum('chiusi') + arrAnnoCorr[10]?.sum('chiusi')) 
                                                    - (arrAnnoprec[0]?.sum('chiusi') + arrAnnoprec[1]?.sum('chiusi') + arrAnnoprec[2]?.sum('chiusi') + arrAnnoprec[3]?.sum('chiusi') + arrAnnoprec[4]?.sum('chiusi') + arrAnnoprec[5]?.sum('chiusi') + arrAnnoprec[6]?.sum('chiusi') + arrAnnoprec[7]?.sum('chiusi') + arrAnnoprec[8]?.sum('chiusi') + arrAnnoprec[9]?.sum('chiusi') + arrAnnoprec[10]?.sum('chiusi'))) 
                                                    / (arrAnnoCorr[0]?.sum('chiusi') + arrAnnoCorr[1]?.sum('chiusi') + arrAnnoCorr[2]?.sum('chiusi') + arrAnnoCorr[3]?.sum('chiusi') + arrAnnoCorr[4]?.sum('chiusi') + arrAnnoCorr[5]?.sum('chiusi') + arrAnnoCorr[6]?.sum('chiusi') + arrAnnoCorr[7]?.sum('chiusi') + arrAnnoCorr[8]?.sum('chiusi') + arrAnnoCorr[9]?.sum('chiusi') + arrAnnoCorr[10]?.sum('chiusi'))) * 100).toFixed(2), 
                                    DIC: parseFloat((((arrAnnoCorr[0]?.sum('chiusi') + arrAnnoCorr[1]?.sum('chiusi') + arrAnnoCorr[2]?.sum('chiusi') + arrAnnoCorr[3]?.sum('chiusi') + arrAnnoCorr[4]?.sum('chiusi') + arrAnnoCorr[5]?.sum('chiusi') + arrAnnoCorr[6]?.sum('chiusi') + arrAnnoCorr[7]?.sum('chiusi') + arrAnnoCorr[8]?.sum('chiusi') + arrAnnoCorr[9]?.sum('chiusi') + arrAnnoCorr[10]?.sum('chiusi') + arrAnnoCorr[11]?.sum('chiusi')) 
                                                    - (arrAnnoprec[0]?.sum('chiusi') + arrAnnoprec[1]?.sum('chiusi') + arrAnnoprec[2]?.sum('chiusi') + arrAnnoprec[3]?.sum('chiusi') + arrAnnoprec[4]?.sum('chiusi') + arrAnnoprec[5]?.sum('chiusi') + arrAnnoprec[6]?.sum('chiusi') + arrAnnoprec[7]?.sum('chiusi') + arrAnnoprec[8]?.sum('chiusi') + arrAnnoprec[9]?.sum('chiusi') + arrAnnoprec[10]?.sum('chiusi') + arrAnnoprec[11]?.sum('chiusi'))) 
                                                    / (arrAnnoCorr[0]?.sum('chiusi') + arrAnnoCorr[1]?.sum('chiusi') + arrAnnoCorr[2]?.sum('chiusi') + arrAnnoCorr[3]?.sum('chiusi') + arrAnnoCorr[4]?.sum('chiusi') + arrAnnoCorr[5]?.sum('chiusi') + arrAnnoCorr[6]?.sum('chiusi') + arrAnnoCorr[7]?.sum('chiusi') + arrAnnoCorr[8]?.sum('chiusi') + arrAnnoCorr[9]?.sum('chiusi') + arrAnnoCorr[10]?.sum('chiusi') + arrAnnoCorr[11]?.sum('chiusi'))) * 100).toFixed(2)});                                    
                    setTimeout(() => {
                        document.getElementById('GridTotaliSinistriChiusi').ej2_instances[0].dataSource = GridData;
                        document.getElementById('GridTotaliSinistriChiusi').ej2_instances[0].refresh();
                    }, 800);
                    thisClone.toggleBlocking();
                    setTimeout(() => { scrollAuto(1000, 55); }, 1000);
            }, true, false);                          
    }, true, false);  
}

function caricaDatiSinistriEntratiChiusi(thisClone) {    
    let totaleAperti = 0, totaleApertiAnnoCorr = 0, totaleChiusiEntratiAnnoCorr = 0, data1 = [], data2 = [];  
    CustomJS.getRemoteData2(CustomJS.APIUrlProd,
        `token=${CustomJS.tokenProd}&azione=tutti&dataDa=${yyyy.toString()}0101&dataA=${dataCorrente}&frequenza=mese&compagnie=${CompagnieVisual.join(',')}`, 
        'POST', "application/x-www-form-urlencoded", (retData) => {
            let groupedArr = _.groupBy(retData, 'periodo');
            for (let ind=1; ind<=12;ind++) {  
                let MONTH = _.filter(Object.values(groupedArr), (obj) => { return !CustomJS.isNullEmpty(_.find(obj, obj2 => { return obj2.periodo === yyyy.toString() + ind.toString().padStart(2,'0') }))});
                data1.push({ x: ind === 1 ? 'GEN' : ind === 2 ? 'FEB' : ind === 3 ? 'MAR' : ind === 4 ? 'APR' : ind === 5 ? 'MAG' : ind === 6 ? 'GIU' 
                            : ind === 7 ? 'LUG' : ind === 8 ? 'AGO' : ind === 9 ? 'SET' : ind === 10 ? 'OTT' : ind === 11 ? 'NOV' : 'DIC', y: MONTH[0]?.sum('entrati') }); 
                data2.push({ x: ind === 1 ? 'GEN' : ind === 2 ? 'FEB' : ind === 3 ? 'MAR' : ind === 4 ? 'APR' : ind === 5 ? 'MAG' : ind === 6 ? 'GIU' 
                            : ind === 7 ? 'LUG' : ind === 8 ? 'AGO' : ind === 9 ? 'SET' : ind === 10 ? 'OTT' : ind === 11 ? 'NOV' : 'DIC', y: MONTH[0]?.sum('chiusi') });  
            }            
            CustomJS.getRemoteData2(CustomJS.APIUrlProd,
                `token=${CustomJS.tokenProd}&azione=chiusi&dataDa=${yyyy.toString()}0101&dataA=${dataCorrente}&frequenza=mese&entratiAnnoCorrente=true&compagnie=${CompagnieVisual.join(',')}`, 
                'POST', "application/x-www-form-urlencoded", (retData) => {
                    let groupedArr = _.groupBy(retData, 'periodo');
                    for (let ind=1; ind<=12;ind++) {  
                        let MONTH = _.filter(Object.values(groupedArr), (obj) => { return !CustomJS.isNullEmpty(_.find(obj, obj2 => { return obj2.periodo === yyyy.toString() + ind.toString().padStart(2,'0') }))});
                        data2.push({ x: ind === 1 ? 'GEN' : ind === 2 ? 'FEB' : ind === 3 ? 'MAR' : ind === 4 ? 'APR' : ind === 5 ? 'MAG' : ind === 6 ? 'GIU' 
                                    : ind === 7 ? 'LUG' : ind === 8 ? 'AGO' : ind === 9 ? 'SET' : ind === 10 ? 'OTT' : ind === 11 ? 'NOV' : 'DIC', y: MONTH[0]?.sum('chiusi') });  
                        totaleChiusiEntratiAnnoCorr += !_.isArray(MONTH[0]) ? 0 : MONTH[0]?.sum('chiusi');                        
                    }               
                    document.getElementById('accordionStatistiche').ej2_instances[0].enableItem(1, true);                  
                    document.getElementById('accordionStatistiche').ej2_instances[0].expandItem(false, 0);
                    for (let ind=0;ind<=200000000;ind++) {};
                    document.getElementById('accordionStatistiche').ej2_instances[0].expandItem(true, 1);                                  
                    setTimeout(() => {
                        ReactDOM.render(<React.Fragment><ChartSinistriEntratiChiusiDefault /><GridTotaliSinistriEntratiChiusi /></React.Fragment>, 
                                         document.getElementById('contenutoDinamicoStatistica'), () => {
                            document.getElementById('ChartSinistriEntratiChiusiDefault').ej2_instances[0].series[0].dataSource = data1;
                            document.getElementById('ChartSinistriEntratiChiusiDefault').ej2_instances[0].series[1].dataSource = data2; 
                            document.getElementById('ChartSinistriEntratiChiusiDefault').ej2_instances[0].series[0].fill = materialColors[5 % 10];
                            document.getElementById('ChartSinistriEntratiChiusiDefault').ej2_instances[0].series[1].fill = materialColors[4 % 10];
                            document.getElementById('ChartSinistriEntratiChiusiDefault').ej2_instances[0].series[0].name = 'Sinistri Entrati';
                            document.getElementById('ChartSinistriEntratiChiusiDefault').ej2_instances[0].series[1].name = 'Sinistri Chiusi';
                        });                        
                    }, 300);        
                    setTimeout(() => { 
                        $('#chiusiAnnoCorr').hide();
                        $('#chiusiAnnoPrec').hide();
                        $('#apertiDiCuiAnnoCorr').show();
                        $('#legendaCustom').hide();
                        $('#legendaCustom2').show();
                        document.getElementById('titoloChartSinistriEntratiChiusi').innerText = 'SINISTRI ENTRATI/CHIUSI ANNO CORRENTE';
                        document.getElementById('testoLegendaCustom2').innerHTML = `Sinistri chiusi, entrati nell&#39;anno corrente : ${totaleChiusiEntratiAnnoCorr.toLocaleString('it-IT')}`;
                        document.getElementById('elencoCompagnieChartSinistriEntratiChiusiDefault').innerHTML = `Compagnia/e: ${document.getElementById('compagnieSelezionate').ej2_instances[0].text.replace(/,/g, ', ')}`;               
                    }, 400); 
            }, true, false);
    }, true, false);    
    CustomJS.getRemoteData3(CustomJS.APIUrlProd, 
            { "token": CustomJS.tokenProd, "azione": "aperti", "frequenza": "sempre", "compagnie": CompagnieVisual.join(',') },
            'POST', { "Content-Type": "application/x-www-form-urlencoded" }, (retData2) => {  
                let groupedArr = _.groupBy(retData2, 'periodo'); 
                totaleAperti = CustomJS.isNullEmpty(groupedArr["sempre"]) ? 0 : groupedArr["sempre"]?.sum('aperti');
                setTimeout(() => {
                    document.getElementById('testoApertiDiCuiAnnoCorr').innerHTML = `APERTI: ${totaleAperti?.toLocaleString('it-IT')}`;
                },2500);               
    }, true, false);
    CustomJS.getRemoteData3(CustomJS.APIUrlProd, 
        { "token": CustomJS.tokenProd, "azione": "aperti", "dataDa": `${yyyy.toString()}0101` , "dataA": `${dataCorrente}` , "frequenza": "sempre", "compagnie": CompagnieVisual.join(',') },
        'POST', { "Content-Type": "application/x-www-form-urlencoded" }, (retData2) => {        
            let groupedArr = _.groupBy(retData2, 'periodo');                                         
            totaleApertiAnnoCorr = CustomJS.isNullEmpty(groupedArr["sempre"]) ? 0 : groupedArr["sempre"]?.sum('aperti');
            setTimeout(() => {                
                document.getElementById('testoApertiDiCuiAnnoCorr').innerHTML += ` (di cui nell'anno corrente: ${totaleApertiAnnoCorr?.toLocaleString('it-IT')})`;
            }, 3000);
    }, true, false);
    CustomJS.getRemoteData2(CustomJS.APIUrlProd,
        `token=${CustomJS.tokenProd}&azione=tutti&dataDa=${yyyy.toString()}0101&dataA=${dataCorrente}&frequenza=mese&compagnie=${CompagnieVisual.join(',')}`,
        'POST', "application/x-www-form-urlencoded", retData => { 
            let GridData = [];         
            let groupedArr = _.groupBy(retData, 'periodo'); 
            GridData.push({ rowDescr: 'Entrati', GEN: groupedArr[yyyy.toString() + '01']?.sum('entrati'), FEB: groupedArr[yyyy.toString() + '02']?.sum('entrati'), MAR: groupedArr[yyyy.toString() + '03']?.sum('entrati'), APR: groupedArr[yyyy.toString() + '04']?.sum('entrati'),
                            MAG: groupedArr[yyyy.toString() + '05']?.sum('entrati'), GIU: groupedArr[yyyy.toString() + '06']?.sum('entrati'), LUG: groupedArr[yyyy.toString() + '07']?.sum('entrati'), AGO: groupedArr[yyyy.toString() + '08']?.sum('entrati'), SET: groupedArr[yyyy.toString() + '09']?.sum('entrati'), 
                            OTT: groupedArr[yyyy.toString() + '10']?.sum('entrati'), NOV: groupedArr[yyyy.toString() + '11']?.sum('entrati'), DIC: groupedArr[yyyy.toString() + '12']?.sum('entrati')});          
            GridData.push({ rowDescr: 'Chiusi', GEN: groupedArr[yyyy.toString() + '01']?.sum('chiusi'), FEB: groupedArr[yyyy.toString() + '02']?.sum('chiusi'), MAR: groupedArr[yyyy.toString() + '03']?.sum('chiusi'), APR: groupedArr[yyyy.toString() + '04']?.sum('chiusi'),
                            MAG: groupedArr[yyyy.toString() + '05']?.sum('chiusi'), GIU: groupedArr[yyyy.toString() + '06']?.sum('chiusi'), LUG: groupedArr[yyyy.toString() + '07']?.sum('chiusi'), AGO: groupedArr[yyyy.toString() + '08']?.sum('chiusi'), SET: groupedArr[yyyy.toString() + '09']?.sum('chiusi'), 
                            OTT: groupedArr[yyyy.toString() + '10']?.sum('chiusi'), NOV: groupedArr[yyyy.toString() + '11']?.sum('chiusi'), DIC: groupedArr[yyyy.toString() + '12']?.sum('chiusi')});  
            GridData.push({ rowDescr: 'DIFF.', GEN: groupedArr[yyyy.toString() + '01']?.sum('chiusi') - groupedArr[yyyy.toString() + '01']?.sum('entrati'), FEB: groupedArr[yyyy.toString() + '02']?.sum('chiusi') - groupedArr[yyyy.toString() + '02']?.sum('entrati'), 
                            MAR: groupedArr[yyyy.toString() + '03']?.sum('chiusi') - groupedArr[yyyy.toString() + '03']?.sum('entrati'), APR: groupedArr[yyyy.toString() + '04']?.sum('chiusi') - groupedArr[yyyy.toString() + '04']?.sum('entrati'),
                            MAG: groupedArr[yyyy.toString() + '05']?.sum('chiusi') - groupedArr[yyyy.toString() + '05']?.sum('entrati'), GIU: groupedArr[yyyy.toString() + '06']?.sum('chiusi') - groupedArr[yyyy.toString() + '06']?.sum('entrati'), 
                            LUG: groupedArr[yyyy.toString() + '07']?.sum('chiusi') - groupedArr[yyyy.toString() + '07']?.sum('entrati'), AGO: groupedArr[yyyy.toString() + '08']?.sum('chiusi') - groupedArr[yyyy.toString() + '08']?.sum('entrati'), 
                            SET: groupedArr[yyyy.toString() + '09']?.sum('chiusi') - groupedArr[yyyy.toString() + '09']?.sum('entrati'), OTT: groupedArr[yyyy.toString() + '10']?.sum('chiusi') - groupedArr[yyyy.toString() + '10']?.sum('entrati'),
                            NOV: groupedArr[yyyy.toString() + '11']?.sum('chiusi') - groupedArr[yyyy.toString() + '11']?.sum('entrati'), DIC: groupedArr[yyyy.toString() + '12']?.sum('chiusi') - groupedArr[yyyy.toString() + '12']?.sum('entrati')});         
            GridData.push({ rowDescr: '%', 
                            GEN: parseFloat((groupedArr[yyyy.toString() + '01']?.sum('chiusi') / groupedArr[yyyy.toString() + '01']?.sum('entrati')) * 100).toFixed(2), 
                            FEB: parseFloat(((groupedArr[yyyy.toString() + '01']?.sum('chiusi') + groupedArr[yyyy.toString() + '02']?.sum('chiusi')) / (groupedArr[yyyy.toString() + '01']?.sum('entrati') + groupedArr[yyyy.toString() + '02']?.sum('entrati'))) * 100).toFixed(2), 
                            MAR: parseFloat(((groupedArr[yyyy.toString() + '01']?.sum('chiusi') + groupedArr[yyyy.toString() + '02']?.sum('chiusi') + groupedArr[yyyy.toString() + '03']?.sum('chiusi')) / (groupedArr[yyyy.toString() + '01']?.sum('entrati') + groupedArr[yyyy.toString() + '02']?.sum('entrati') + groupedArr[yyyy.toString() + '03']?.sum('entrati'))) * 100).toFixed(2), 
                            APR: parseFloat(((groupedArr[yyyy.toString() + '01']?.sum('chiusi') + groupedArr[yyyy.toString() + '02']?.sum('chiusi') + groupedArr[yyyy.toString() + '03']?.sum('chiusi') + groupedArr[yyyy.toString() + '04']?.sum('chiusi'))                                             
                                            / (groupedArr[yyyy.toString() + '01']?.sum('entrati') + groupedArr[yyyy.toString() + '02']?.sum('entrati') + groupedArr[yyyy.toString() + '03']?.sum('entrati') + groupedArr[yyyy.toString() + '04']?.sum('entrati'))) * 100).toFixed(2),
                            MAG: parseFloat(((groupedArr[yyyy.toString() + '01']?.sum('chiusi') + groupedArr[yyyy.toString() + '02']?.sum('chiusi') + groupedArr[yyyy.toString() + '03']?.sum('chiusi') + groupedArr[yyyy.toString() + '04']?.sum('chiusi') + groupedArr[yyyy.toString() + '05']?.sum('chiusi'))
                                            / (groupedArr[yyyy.toString() + '01']?.sum('entrati') + groupedArr[yyyy.toString() + '02']?.sum('entrati') + groupedArr[yyyy.toString() + '03']?.sum('entrati') + groupedArr[yyyy.toString() + '04']?.sum('entrati') + groupedArr[yyyy.toString() + '05']?.sum('entrati'))) * 100).toFixed(2),
                            GIU: parseFloat(((groupedArr[yyyy.toString() + '01']?.sum('chiusi') + groupedArr[yyyy.toString() + '02']?.sum('chiusi') + groupedArr[yyyy.toString() + '03']?.sum('chiusi') + groupedArr[yyyy.toString() + '04']?.sum('chiusi') + groupedArr[yyyy.toString() + '05']?.sum('chiusi') + groupedArr[yyyy.toString() + '06']?.sum('chiusi'))                                             
                                            / (groupedArr[yyyy.toString() + '01']?.sum('entrati') + groupedArr[yyyy.toString() + '02']?.sum('entrati') + groupedArr[yyyy.toString() + '03']?.sum('entrati') + groupedArr[yyyy.toString() + '04']?.sum('entrati') + groupedArr[yyyy.toString() + '05']?.sum('entrati') + groupedArr[yyyy.toString() + '06']?.sum('entrati'))) * 100).toFixed(2), 
                            LUG: parseFloat(((groupedArr[yyyy.toString() + '01']?.sum('chiusi') + groupedArr[yyyy.toString() + '02']?.sum('chiusi') + groupedArr[yyyy.toString() + '03']?.sum('chiusi') + groupedArr[yyyy.toString() + '04']?.sum('chiusi') + groupedArr[yyyy.toString() + '05']?.sum('chiusi') + groupedArr[yyyy.toString() + '06']?.sum('chiusi') + groupedArr[yyyy.toString() + '07']?.sum('chiusi')) 
                                            / (groupedArr[yyyy.toString() + '01']?.sum('entrati') + groupedArr[yyyy.toString() + '02']?.sum('entrati') + groupedArr[yyyy.toString() + '03']?.sum('entrati') + groupedArr[yyyy.toString() + '04']?.sum('entrati') + groupedArr[yyyy.toString() + '05']?.sum('entrati') + groupedArr[yyyy.toString() + '06']?.sum('entrati') + groupedArr[yyyy.toString() + '07']?.sum('entrati'))) * 100).toFixed(2), 
                            AGO: parseFloat(((groupedArr[yyyy.toString() + '01']?.sum('chiusi') + groupedArr[yyyy.toString() + '02']?.sum('chiusi') + groupedArr[yyyy.toString() + '03']?.sum('chiusi') + groupedArr[yyyy.toString() + '04']?.sum('chiusi') + groupedArr[yyyy.toString() + '05']?.sum('chiusi') + groupedArr[yyyy.toString() + '06']?.sum('chiusi') + groupedArr[yyyy.toString() + '07']?.sum('chiusi') + groupedArr[yyyy.toString() + '08']?.sum('chiusi')) 
                                            / (groupedArr[yyyy.toString() + '01']?.sum('entrati') + groupedArr[yyyy.toString() + '02']?.sum('entrati') + groupedArr[yyyy.toString() + '03']?.sum('entrati') + groupedArr[yyyy.toString() + '04']?.sum('entrati') + groupedArr[yyyy.toString() + '05']?.sum('entrati') + groupedArr[yyyy.toString() + '06']?.sum('entrati') + groupedArr[yyyy.toString() + '07']?.sum('entrati') + groupedArr[yyyy.toString() + '08']?.sum('entrati'))) * 100).toFixed(2), 
                            SET: parseFloat(((groupedArr[yyyy.toString() + '01']?.sum('chiusi') + groupedArr[yyyy.toString() + '02']?.sum('chiusi') + groupedArr[yyyy.toString() + '03']?.sum('chiusi') + groupedArr[yyyy.toString() + '04']?.sum('chiusi') + groupedArr[yyyy.toString() + '05']?.sum('chiusi') + groupedArr[yyyy.toString() + '06']?.sum('chiusi') + groupedArr[yyyy.toString() + '07']?.sum('chiusi') + groupedArr[yyyy.toString() + '08']?.sum('chiusi') + groupedArr[yyyy.toString() + '09']?.sum('chiusi')) 
                                            / (groupedArr[yyyy.toString() + '01']?.sum('entrati') + groupedArr[yyyy.toString() + '02']?.sum('entrati') + groupedArr[yyyy.toString() + '03']?.sum('entrati') + groupedArr[yyyy.toString() + '04']?.sum('entrati') + groupedArr[yyyy.toString() + '05']?.sum('entrati') + groupedArr[yyyy.toString() + '06']?.sum('entrati') + groupedArr[yyyy.toString() + '07']?.sum('entrati') + groupedArr[yyyy.toString() + '08']?.sum('entrati') + groupedArr[yyyy.toString() + '09']?.sum('entrati'))) * 100).toFixed(2), 
                            OTT: parseFloat(((groupedArr[yyyy.toString() + '01']?.sum('chiusi') + groupedArr[yyyy.toString() + '02']?.sum('chiusi') + groupedArr[yyyy.toString() + '03']?.sum('chiusi') + groupedArr[yyyy.toString() + '04']?.sum('chiusi') + groupedArr[yyyy.toString() + '05']?.sum('chiusi') + groupedArr[yyyy.toString() + '06']?.sum('chiusi') + groupedArr[yyyy.toString() + '07']?.sum('chiusi') + groupedArr[yyyy.toString() + '08']?.sum('chiusi') + groupedArr[yyyy.toString() + '09']?.sum('chiusi') + groupedArr[yyyy.toString() + '10']?.sum('chiusi')) 
                                            / (groupedArr[yyyy.toString() + '01']?.sum('entrati') + groupedArr[yyyy.toString() + '02']?.sum('entrati') + groupedArr[yyyy.toString() + '03']?.sum('entrati') + groupedArr[yyyy.toString() + '04']?.sum('entrati') + groupedArr[yyyy.toString() + '05']?.sum('entrati') + groupedArr[yyyy.toString() + '06']?.sum('entrati') + groupedArr[yyyy.toString() + '07']?.sum('entrati') + groupedArr[yyyy.toString() + '08']?.sum('entrati') + groupedArr[yyyy.toString() + '09']?.sum('entrati') + groupedArr[yyyy.toString() + '10']?.sum('entrati'))) * 100).toFixed(2),
                            NOV: parseFloat(((groupedArr[yyyy.toString() + '01']?.sum('chiusi') + groupedArr[yyyy.toString() + '02']?.sum('chiusi') + groupedArr[yyyy.toString() + '03']?.sum('chiusi') + groupedArr[yyyy.toString() + '04']?.sum('chiusi') + groupedArr[yyyy.toString() + '05']?.sum('chiusi') + groupedArr[yyyy.toString() + '06']?.sum('chiusi') + groupedArr[yyyy.toString() + '07']?.sum('chiusi') + groupedArr[yyyy.toString() + '08']?.sum('chiusi') + groupedArr[yyyy.toString() + '09']?.sum('chiusi') + groupedArr[yyyy.toString() + '10']?.sum('chiusi') + groupedArr[yyyy.toString() + '11']?.sum('chiusi')) 
                                            / (groupedArr[yyyy.toString() + '01']?.sum('entrati') + groupedArr[yyyy.toString() + '02']?.sum('entrati') + groupedArr[yyyy.toString() + '03']?.sum('entrati') + groupedArr[yyyy.toString() + '04']?.sum('entrati') + groupedArr[yyyy.toString() + '05']?.sum('entrati') + groupedArr[yyyy.toString() + '06']?.sum('entrati') + groupedArr[yyyy.toString() + '07']?.sum('entrati') + groupedArr[yyyy.toString() + '08']?.sum('entrati') + groupedArr[yyyy.toString() + '09']?.sum('entrati') + groupedArr[yyyy.toString() + '10']?.sum('entrati') + groupedArr[yyyy.toString() + '11']?.sum('entrati'))) * 100).toFixed(2), 
                            DIC: parseFloat(((groupedArr[yyyy.toString() + '01']?.sum('chiusi') + groupedArr[yyyy.toString() + '02']?.sum('chiusi') + groupedArr[yyyy.toString() + '03']?.sum('chiusi') + groupedArr[yyyy.toString() + '04']?.sum('chiusi') + groupedArr[yyyy.toString() + '05']?.sum('chiusi') + groupedArr[yyyy.toString() + '06']?.sum('chiusi') + groupedArr[yyyy.toString() + '07']?.sum('chiusi') + groupedArr[yyyy.toString() + '08']?.sum('chiusi') + groupedArr[yyyy.toString() + '09']?.sum('chiusi') + groupedArr[yyyy.toString() + '10']?.sum('chiusi') + groupedArr[yyyy.toString() + '11']?.sum('chiusi') + groupedArr[yyyy.toString() + '12']?.sum('chiusi')) 
                                            / (groupedArr[yyyy.toString() + '01']?.sum('entrati') + groupedArr[yyyy.toString() + '02']?.sum('entrati') + groupedArr[yyyy.toString() + '03']?.sum('entrati') + groupedArr[yyyy.toString() + '04']?.sum('entrati') + groupedArr[yyyy.toString() + '05']?.sum('entrati') + groupedArr[yyyy.toString() + '06']?.sum('entrati') + groupedArr[yyyy.toString() + '07']?.sum('entrati') + groupedArr[yyyy.toString() + '08']?.sum('entrati') + groupedArr[yyyy.toString() + '09']?.sum('entrati') + groupedArr[yyyy.toString() + '10']?.sum('entrati') + groupedArr[yyyy.toString() + '11']?.sum('entrati') + groupedArr[yyyy.toString() + '12']?.sum('entrati'))) * 100).toFixed(2)});
            CustomJS.getRemoteData2(CustomJS.APIUrlProd,
                `token=${CustomJS.tokenProd}&azione=tutti&dataDa=${yyyy.toString()}0101&dataA=${dataCorrente}&frequenza=mese&chiusiEntratiAnnoCorrente=true&compagnie=${CompagnieVisual.join(',')}`,
                'POST', "application/x-www-form-urlencoded", retData => {    
                    let groupedArr = _.groupBy(retData, 'periodo');              
                    GridData.push({ rowDescr: '% anno corrente', 
                                    GEN: parseFloat((groupedArr[yyyy.toString() + '01']?.sum('chiusi') / groupedArr[yyyy.toString() + '01']?.sum('entrati')) * 100).toFixed(2), 
                                    FEB: parseFloat(((groupedArr[yyyy.toString() + '01']?.sum('chiusi') + groupedArr[yyyy.toString() + '02']?.sum('chiusi')) / (groupedArr[yyyy.toString() + '01']?.sum('entrati') + groupedArr[yyyy.toString() + '02']?.sum('entrati'))) * 100).toFixed(2), 
                                    MAR: parseFloat(((groupedArr[yyyy.toString() + '01']?.sum('chiusi') + groupedArr[yyyy.toString() + '02']?.sum('chiusi') + groupedArr[yyyy.toString() + '03']?.sum('chiusi')) / (groupedArr[yyyy.toString() + '01']?.sum('entrati') + groupedArr[yyyy.toString() + '02']?.sum('entrati') + groupedArr[yyyy.toString() + '03']?.sum('entrati'))) * 100).toFixed(2), 
                                    APR: parseFloat(((groupedArr[yyyy.toString() + '01']?.sum('chiusi') + groupedArr[yyyy.toString() + '02']?.sum('chiusi') + groupedArr[yyyy.toString() + '03']?.sum('chiusi') + groupedArr[yyyy.toString() + '04']?.sum('chiusi'))                                             
                                                    / (groupedArr[yyyy.toString() + '01']?.sum('entrati') + groupedArr[yyyy.toString() + '02']?.sum('entrati') + groupedArr[yyyy.toString() + '03']?.sum('entrati') + groupedArr[yyyy.toString() + '04']?.sum('entrati'))) * 100).toFixed(2),
                                    MAG: parseFloat(((groupedArr[yyyy.toString() + '01']?.sum('chiusi') + groupedArr[yyyy.toString() + '02']?.sum('chiusi') + groupedArr[yyyy.toString() + '03']?.sum('chiusi') + groupedArr[yyyy.toString() + '04']?.sum('chiusi') + groupedArr[yyyy.toString() + '05']?.sum('chiusi'))
                                                    / (groupedArr[yyyy.toString() + '01']?.sum('entrati') + groupedArr[yyyy.toString() + '02']?.sum('entrati') + groupedArr[yyyy.toString() + '03']?.sum('entrati') + groupedArr[yyyy.toString() + '04']?.sum('entrati') + groupedArr[yyyy.toString() + '05']?.sum('entrati'))) * 100).toFixed(2),
                                    GIU: parseFloat(((groupedArr[yyyy.toString() + '01']?.sum('chiusi') + groupedArr[yyyy.toString() + '02']?.sum('chiusi') + groupedArr[yyyy.toString() + '03']?.sum('chiusi') + groupedArr[yyyy.toString() + '04']?.sum('chiusi') + groupedArr[yyyy.toString() + '05']?.sum('chiusi') + groupedArr[yyyy.toString() + '06']?.sum('chiusi'))                                             
                                                    / (groupedArr[yyyy.toString() + '01']?.sum('entrati') + groupedArr[yyyy.toString() + '02']?.sum('entrati') + groupedArr[yyyy.toString() + '03']?.sum('entrati') + groupedArr[yyyy.toString() + '04']?.sum('entrati') + groupedArr[yyyy.toString() + '05']?.sum('entrati') + groupedArr[yyyy.toString() + '06']?.sum('entrati'))) * 100).toFixed(2), 
                                    LUG: parseFloat(((groupedArr[yyyy.toString() + '01']?.sum('chiusi') + groupedArr[yyyy.toString() + '02']?.sum('chiusi') + groupedArr[yyyy.toString() + '03']?.sum('chiusi') + groupedArr[yyyy.toString() + '04']?.sum('chiusi') + groupedArr[yyyy.toString() + '05']?.sum('chiusi') + groupedArr[yyyy.toString() + '06']?.sum('chiusi') + groupedArr[yyyy.toString() + '07']?.sum('chiusi')) 
                                                    / (groupedArr[yyyy.toString() + '01']?.sum('entrati') + groupedArr[yyyy.toString() + '02']?.sum('entrati') + groupedArr[yyyy.toString() + '03']?.sum('entrati') + groupedArr[yyyy.toString() + '04']?.sum('entrati') + groupedArr[yyyy.toString() + '05']?.sum('entrati') + groupedArr[yyyy.toString() + '06']?.sum('entrati') + groupedArr[yyyy.toString() + '07']?.sum('entrati'))) * 100).toFixed(2), 
                                    AGO: parseFloat(((groupedArr[yyyy.toString() + '01']?.sum('chiusi') + groupedArr[yyyy.toString() + '02']?.sum('chiusi') + groupedArr[yyyy.toString() + '03']?.sum('chiusi') + groupedArr[yyyy.toString() + '04']?.sum('chiusi') + groupedArr[yyyy.toString() + '05']?.sum('chiusi') + groupedArr[yyyy.toString() + '06']?.sum('chiusi') + groupedArr[yyyy.toString() + '07']?.sum('chiusi') + groupedArr[yyyy.toString() + '08']?.sum('chiusi')) 
                                                    / (groupedArr[yyyy.toString() + '01']?.sum('entrati') + groupedArr[yyyy.toString() + '02']?.sum('entrati') + groupedArr[yyyy.toString() + '03']?.sum('entrati') + groupedArr[yyyy.toString() + '04']?.sum('entrati') + groupedArr[yyyy.toString() + '05']?.sum('entrati') + groupedArr[yyyy.toString() + '06']?.sum('entrati') + groupedArr[yyyy.toString() + '07']?.sum('entrati') + groupedArr[yyyy.toString() + '08']?.sum('entrati'))) * 100).toFixed(2), 
                                    SET: parseFloat(((groupedArr[yyyy.toString() + '01']?.sum('chiusi') + groupedArr[yyyy.toString() + '02']?.sum('chiusi') + groupedArr[yyyy.toString() + '03']?.sum('chiusi') + groupedArr[yyyy.toString() + '04']?.sum('chiusi') + groupedArr[yyyy.toString() + '05']?.sum('chiusi') + groupedArr[yyyy.toString() + '06']?.sum('chiusi') + groupedArr[yyyy.toString() + '07']?.sum('chiusi') + groupedArr[yyyy.toString() + '08']?.sum('chiusi') + groupedArr[yyyy.toString() + '09']?.sum('chiusi')) 
                                                    / (groupedArr[yyyy.toString() + '01']?.sum('entrati') + groupedArr[yyyy.toString() + '02']?.sum('entrati') + groupedArr[yyyy.toString() + '03']?.sum('entrati') + groupedArr[yyyy.toString() + '04']?.sum('entrati') + groupedArr[yyyy.toString() + '05']?.sum('entrati') + groupedArr[yyyy.toString() + '06']?.sum('entrati') + groupedArr[yyyy.toString() + '07']?.sum('entrati') + groupedArr[yyyy.toString() + '08']?.sum('entrati') + groupedArr[yyyy.toString() + '09']?.sum('entrati'))) * 100).toFixed(2), 
                                    OTT: parseFloat(((groupedArr[yyyy.toString() + '01']?.sum('chiusi') + groupedArr[yyyy.toString() + '02']?.sum('chiusi') + groupedArr[yyyy.toString() + '03']?.sum('chiusi') + groupedArr[yyyy.toString() + '04']?.sum('chiusi') + groupedArr[yyyy.toString() + '05']?.sum('chiusi') + groupedArr[yyyy.toString() + '06']?.sum('chiusi') + groupedArr[yyyy.toString() + '07']?.sum('chiusi') + groupedArr[yyyy.toString() + '08']?.sum('chiusi') + groupedArr[yyyy.toString() + '09']?.sum('chiusi') + groupedArr[yyyy.toString() + '10']?.sum('chiusi')) 
                                                    / (groupedArr[yyyy.toString() + '01']?.sum('entrati') + groupedArr[yyyy.toString() + '02']?.sum('entrati') + groupedArr[yyyy.toString() + '03']?.sum('entrati') + groupedArr[yyyy.toString() + '04']?.sum('entrati') + groupedArr[yyyy.toString() + '05']?.sum('entrati') + groupedArr[yyyy.toString() + '06']?.sum('entrati') + groupedArr[yyyy.toString() + '07']?.sum('entrati') + groupedArr[yyyy.toString() + '08']?.sum('entrati') + groupedArr[yyyy.toString() + '09']?.sum('entrati') + groupedArr[yyyy.toString() + '10']?.sum('entrati'))) * 100).toFixed(2),
                                    NOV: parseFloat(((groupedArr[yyyy.toString() + '01']?.sum('chiusi') + groupedArr[yyyy.toString() + '02']?.sum('chiusi') + groupedArr[yyyy.toString() + '03']?.sum('chiusi') + groupedArr[yyyy.toString() + '04']?.sum('chiusi') + groupedArr[yyyy.toString() + '05']?.sum('chiusi') + groupedArr[yyyy.toString() + '06']?.sum('chiusi') + groupedArr[yyyy.toString() + '07']?.sum('chiusi') + groupedArr[yyyy.toString() + '08']?.sum('chiusi') + groupedArr[yyyy.toString() + '09']?.sum('chiusi') + groupedArr[yyyy.toString() + '10']?.sum('chiusi') + groupedArr[yyyy.toString() + '11']?.sum('chiusi')) 
                                                    / (groupedArr[yyyy.toString() + '01']?.sum('entrati') + groupedArr[yyyy.toString() + '02']?.sum('entrati') + groupedArr[yyyy.toString() + '03']?.sum('entrati') + groupedArr[yyyy.toString() + '04']?.sum('entrati') + groupedArr[yyyy.toString() + '05']?.sum('entrati') + groupedArr[yyyy.toString() + '06']?.sum('entrati') + groupedArr[yyyy.toString() + '07']?.sum('entrati') + groupedArr[yyyy.toString() + '08']?.sum('entrati') + groupedArr[yyyy.toString() + '09']?.sum('entrati') + groupedArr[yyyy.toString() + '10']?.sum('entrati') + groupedArr[yyyy.toString() + '11']?.sum('entrati'))) * 100).toFixed(2), 
                                    DIC: parseFloat(((groupedArr[yyyy.toString() + '01']?.sum('chiusi') + groupedArr[yyyy.toString() + '02']?.sum('chiusi') + groupedArr[yyyy.toString() + '03']?.sum('chiusi') + groupedArr[yyyy.toString() + '04']?.sum('chiusi') + groupedArr[yyyy.toString() + '05']?.sum('chiusi') + groupedArr[yyyy.toString() + '06']?.sum('chiusi') + groupedArr[yyyy.toString() + '07']?.sum('chiusi') + groupedArr[yyyy.toString() + '08']?.sum('chiusi') + groupedArr[yyyy.toString() + '09']?.sum('chiusi') + groupedArr[yyyy.toString() + '10']?.sum('chiusi') + groupedArr[yyyy.toString() + '11']?.sum('chiusi') + groupedArr[yyyy.toString() + '12']?.sum('chiusi')) 
                                                    / (groupedArr[yyyy.toString() + '01']?.sum('entrati') + groupedArr[yyyy.toString() + '02']?.sum('entrati') + groupedArr[yyyy.toString() + '03']?.sum('entrati') + groupedArr[yyyy.toString() + '04']?.sum('entrati') + groupedArr[yyyy.toString() + '05']?.sum('entrati') + groupedArr[yyyy.toString() + '06']?.sum('entrati') + groupedArr[yyyy.toString() + '07']?.sum('entrati') + groupedArr[yyyy.toString() + '08']?.sum('entrati') + groupedArr[yyyy.toString() + '09']?.sum('entrati') + groupedArr[yyyy.toString() + '10']?.sum('entrati') + groupedArr[yyyy.toString() + '11']?.sum('entrati') + groupedArr[yyyy.toString() + '12']?.sum('entrati'))) * 100).toFixed(2)});
                    setTimeout(() => {
                        document.getElementById('GridTotaliSinistriEntratiChiusi').ej2_instances[0].dataSource = GridData;
                        document.getElementById('GridTotaliSinistriEntratiChiusi').ej2_instances[0].refresh();
                    }, 800);
                    thisClone.toggleBlocking();
                    setTimeout(() => { scrollAuto(1000, 80); }, 1500);
            }, true, false);
    }, true, false);  
}

function caricaDatiSinistriPervenuti(thisClone) {
    let data1 = [], data2 = [], data3 = [], data4 = [], GridData1 = [], GridData2 = [], groupedArr1 = [], groupedArr2 = [], groupedArr3 = [], groupedArr4 = [];  
    CustomJS.getRemoteData2(CustomJS.APIUrlProd,
        `token=${CustomJS.tokenProd}&azione=entrati&dataDa=${(yyyy - 1).toString()}0101&dataA=${(yyyy - 1).toString()}1231&frequenza=mese&compagnie=${CompagnieVisual.join(',')}`, 
        'POST', "application/x-www-form-urlencoded", (retData) => {
            groupedArr1 = _.groupBy(retData, 'periodo');
            for (let ind=1; ind<=12;ind++) {  
                let MONTH = _.filter(Object.values(groupedArr1), (obj) => { return !CustomJS.isNullEmpty(_.find(obj, obj2 => { return obj2.periodo === (yyyy-1).toString() + ind.toString().padStart(2,'0') }))});
                data1.push({ x: ind === 1 ? new Date(yyyy, 0, 1) : ind === 2 ? new Date(yyyy, 1, 1)  : ind === 3 ? new Date(yyyy, 2, 1)  : ind === 4 ? new Date(yyyy, 3, 1)
                              : ind === 5 ? new Date(yyyy, 4, 1)  : ind === 6 ? new Date(yyyy, 5, 1) : ind === 7 ? new Date(yyyy, 6, 1)  : ind === 8 ? new Date(yyyy, 7, 1)  
                              : ind === 9 ? new Date(yyyy, 8, 1)  : ind === 10 ? new Date(yyyy, 9, 1)  : ind === 11 ? new Date(yyyy, 10, 1)  : new Date(yyyy, 11, 1) , y: MONTH[0]?.sum('entrati') }); 
                data3.push({ x: ind === 1 ? 'Gennaio' : ind === 2 ? 'Febbraio' : ind === 3 ? 'Marzo' : ind === 4 ? 'Aprile' : ind === 5 ? 'Maggio' : ind === 6 ? 'Giugno' 
                              : ind === 7 ? 'Luglio' : ind === 8 ? 'Agosto' : ind === 9 ? 'Settembre' : ind === 10 ? 'Ottobre' : ind === 11 ? 'Novembre' : 'Dicembre', y: MONTH[0]?.sum('entrati') }); 
            }   
            let totRiga1 = (arr) => { let totale = 0; Object.values(arr).forEach((elm,idx) => { totale += elm?.sum('entrati'); }); return totale; };
            GridData1.push({ rowDescr: `Pervenuti ${(yyyy - 1).toString()}`, GEN: groupedArr1[(yyyy - 1).toString() + '01']?.sum('entrati'), FEB: groupedArr1[(yyyy - 1).toString() + '02']?.sum('entrati'), 
                            MAR: groupedArr1[(yyyy - 1).toString() + '03']?.sum('entrati'), APR: groupedArr1[(yyyy - 1).toString() + '04']?.sum('entrati'), MAG: groupedArr1[(yyyy - 1).toString() + '05']?.sum('entrati'),
                            GIU: groupedArr1[(yyyy - 1).toString() + '06']?.sum('entrati'), LUG: groupedArr1[(yyyy - 1).toString() + '07']?.sum('entrati'), AGO: groupedArr1[(yyyy - 1).toString() + '08']?.sum('entrati'),
                            SET: groupedArr1[(yyyy - 1).toString() + '09']?.sum('entrati'), OTT: groupedArr1[(yyyy - 1).toString() + '10']?.sum('entrati'), NOV: groupedArr1[(yyyy - 1).toString() + '11']?.sum('entrati'), 
                            DIC: groupedArr1[(yyyy - 1).toString() + '12']?.sum('entrati'), TOT: totRiga1(groupedArr1) });   
            groupedArr3 = _.groupBy(retData, 'DescrizioneCompagnia');    
            Object.values(groupedArr3).forEach((elm, idx) => {
                GridData2.push({ DescrizioneCompagnia: Object.keys(groupedArr3)[idx], PervAnnoPrec: elm.sum('entrati') });       
            }); 
            CustomJS.getRemoteData2(CustomJS.APIUrlProd,
                `token=${CustomJS.tokenProd}&azione=entrati&dataDa=${yyyy.toString()}0101&dataA=${dataCorrente}&frequenza=mese&compagnie=${CompagnieVisual.join(',')}`, 
                'POST', "application/x-www-form-urlencoded", (retData) => {
                    groupedArr2 = _.groupBy(retData, 'periodo');
                    for (let ind=1; ind<=12;ind++) {  
                        let MONTH = _.filter(Object.values(groupedArr2), (obj) => { return !CustomJS.isNullEmpty(_.find(obj, obj2 => { return obj2.periodo === yyyy.toString() + ind.toString().padStart(2,'0') }))});
                        data2.push({ x: ind === 1 ? new Date(yyyy, 0, 1) : ind === 2 ? new Date(yyyy, 1, 1)  : ind === 3 ? new Date(yyyy, 2, 1)  : ind === 4 ? new Date(yyyy, 3, 1)
                                      : ind === 5 ? new Date(yyyy, 4, 1)  : ind === 6 ? new Date(yyyy, 5, 1) : ind === 7 ? new Date(yyyy, 6, 1)  : ind === 8 ? new Date(yyyy, 7, 1)  
                                      : ind === 9 ? new Date(yyyy, 8, 1)  : ind === 10 ? new Date(yyyy, 9, 1)  : ind === 11 ? new Date(yyyy, 10, 1)  : new Date(yyyy, 11, 1) , y: MONTH[0]?.sum('entrati') }); 
                        data4.push({ x: ind === 1 ? 'Gennaio' : ind === 2 ? 'Febbraio' : ind === 3 ? 'Marzo' : ind === 4 ? 'Aprile' : ind === 5 ? 'Maggio' : ind === 6 ? 'Giugno' 
                                      : ind === 7 ? 'Luglio' : ind === 8 ? 'Agosto' : ind === 9 ? 'Settembre' : ind === 10 ? 'Ottobre' : ind === 11 ? 'Novembre' : 'Dicembre', y: MONTH[0]?.sum('entrati') }); 
                    }   
                    GridData1.push({ rowDescr: `Pervenuti ${yyyy.toString()}`, GEN: groupedArr2[yyyy.toString() + '01']?.sum('entrati'), FEB: groupedArr2[yyyy.toString() + '02']?.sum('entrati'), 
                                    MAR: groupedArr2[yyyy.toString() + '03']?.sum('entrati'), APR: groupedArr2[yyyy.toString() + '04']?.sum('entrati'), MAG: groupedArr2[yyyy.toString() + '05']?.sum('entrati'),
                                    GIU: groupedArr2[yyyy.toString() + '06']?.sum('entrati'), LUG: groupedArr2[yyyy.toString() + '07']?.sum('entrati'), AGO: groupedArr2[yyyy.toString() + '08']?.sum('entrati'),
                                    SET: groupedArr2[yyyy.toString() + '09']?.sum('entrati'), OTT: groupedArr2[yyyy.toString() + '10']?.sum('entrati'), NOV: groupedArr2[yyyy.toString() + '11']?.sum('entrati'), 
                                    DIC: groupedArr2[yyyy.toString() + '12']?.sum('entrati'), TOT: totRiga1(groupedArr2)});   
                    GridData1.push({ rowDescr: 'Delta %', 
                                    GEN: parseFloat(((groupedArr2[yyyy.toString() + '01']?.sum('entrati') - groupedArr1[(yyyy-1).toString() + '01']?.sum('entrati')) / groupedArr1[(yyyy-1).toString() + '01']?.sum('entrati')) * 100).toFixed(2), 
                                    FEB: parseFloat(((groupedArr2[yyyy.toString() + '02']?.sum('entrati') - groupedArr1[(yyyy-1).toString() + '02']?.sum('entrati')) / groupedArr1[(yyyy-1).toString() + '02']?.sum('entrati')) * 100).toFixed(2), 
                                    MAR: parseFloat(((groupedArr2[yyyy.toString() + '03']?.sum('entrati') - groupedArr1[(yyyy-1).toString() + '03']?.sum('entrati')) / groupedArr1[(yyyy-1).toString() + '03']?.sum('entrati')) * 100).toFixed(2), 
                                    APR: parseFloat(((groupedArr2[yyyy.toString() + '04']?.sum('entrati') - groupedArr1[(yyyy-1).toString() + '04']?.sum('entrati')) / groupedArr1[(yyyy-1).toString() + '04']?.sum('entrati')) * 100).toFixed(2),
                                    MAG: parseFloat(((groupedArr2[yyyy.toString() + '05']?.sum('entrati') - groupedArr1[(yyyy-1).toString() + '05']?.sum('entrati')) / groupedArr1[(yyyy-1).toString() + '05']?.sum('entrati')) * 100).toFixed(2),
                                    GIU: parseFloat(((groupedArr2[yyyy.toString() + '06']?.sum('entrati') - groupedArr1[(yyyy-1).toString() + '06']?.sum('entrati')) / groupedArr1[(yyyy-1).toString() + '06']?.sum('entrati')) * 100).toFixed(2), 
                                    LUG: parseFloat(((groupedArr2[yyyy.toString() + '07']?.sum('entrati') - groupedArr1[(yyyy-1).toString() + '07']?.sum('entrati')) / groupedArr1[(yyyy-1).toString() + '07']?.sum('entrati')) * 100).toFixed(2), 
                                    AGO: parseFloat(((groupedArr2[yyyy.toString() + '08']?.sum('entrati') - groupedArr1[(yyyy-1).toString() + '08']?.sum('entrati')) / groupedArr1[(yyyy-1).toString() + '08']?.sum('entrati')) * 100).toFixed(2), 
                                    SET: parseFloat(((groupedArr2[yyyy.toString() + '09']?.sum('entrati') - groupedArr1[(yyyy-1).toString() + '09']?.sum('entrati')) / groupedArr1[(yyyy-1).toString() + '09']?.sum('entrati')) * 100).toFixed(2), 
                                    OTT: parseFloat(((groupedArr2[yyyy.toString() + '10']?.sum('entrati') - groupedArr1[(yyyy-1).toString() + '10']?.sum('entrati')) / groupedArr1[(yyyy-1).toString() + '10']?.sum('entrati')) * 100).toFixed(2),
                                    NOV: parseFloat(((groupedArr2[yyyy.toString() + '11']?.sum('entrati') - groupedArr1[(yyyy-1).toString() + '11']?.sum('entrati')) / groupedArr1[(yyyy-1).toString() + '11']?.sum('entrati')) * 100).toFixed(2), 
                                    DIC: parseFloat(((groupedArr2[yyyy.toString() + '12']?.sum('entrati') - groupedArr1[(yyyy-1).toString() + '12']?.sum('entrati')) / groupedArr1[(yyyy-1).toString() + '12']?.sum('entrati')) * 100).toFixed(2),
                                    TOT: parseFloat(((totRiga1(groupedArr2) - totRiga1(groupedArr1)) / totRiga1(groupedArr1)) * 100).toFixed(2)});
                    groupedArr4 = _.groupBy(retData, 'DescrizioneCompagnia');    
                    Object.values(groupedArr4).forEach((elm, idx) => {
                        let arrObj = _.find(GridData2, elm2 => { return elm2.DescrizioneCompagnia === Object.keys(groupedArr4)[idx] });
                        if (arrObj) {
                            arrObj.PervAnnoCorr = elm.sum('entrati');
                            arrObj.DeltaPerc = parseFloat(((arrObj.PervAnnoCorr - arrObj.PervAnnoPrec) / arrObj.PervAnnoPrec) * 100).toFixed(2);
                        }
                        else {
                            GridData2.push({ DescrizioneCompagnia: Object.keys(groupedArr4)[idx], PervAnnoPrec: '', PervAnnoCorr: elm.sum('entrati'), DeltaPerc: '' }); 
                        }
                    }); 
                    GridData2.push({ DescrizioneCompagnia: 'TOTALI', PervAnnoPrec: totRiga1(groupedArr3), PervAnnoCorr: totRiga1(groupedArr4), DeltaPerc: parseFloat(((totRiga1(groupedArr4) - totRiga1(groupedArr3)) / totRiga1(groupedArr3)) * 100).toFixed(2) });
                    document.getElementById('accordionStatistiche').ej2_instances[0].enableItem(1, true);                  
                    document.getElementById('accordionStatistiche').ej2_instances[0].expandItem(false, 0);
                    for (let ind=0;ind<=200000000;ind++) {};
                    document.getElementById('accordionStatistiche').ej2_instances[0].expandItem(true, 1);                                  
                    setTimeout(() => {
                        ReactDOM.render(<React.Fragment><PaginaSinistriPervenuti /></React.Fragment>, 
                                         document.getElementById('contenutoDinamicoStatistica'), () => {

                            setTimeout(() => {
                                document.getElementById('titoloChartSinistriPervenuti').innerText = 'SINISTRI PERVENUTI ANNO CORRENTE/ANNO PRECEDENTE';
                                document.getElementById('elencoCompagnieChartSinistriPervenuti').innerHTML = `Compagnia/e: ${document.getElementById('compagnieSelezionate').ej2_instances[0].text.replace(/,/g, ', ')}`;               
                                document.getElementById('ChartSinistriPervenutiLinee').ej2_instances[0].series[0].dataSource = data1;
                                document.getElementById('ChartSinistriPervenutiLinee').ej2_instances[0].series[1].dataSource = data2; 
                                document.getElementById('ChartSinistriPervenutiLinee').ej2_instances[0].series[0].fill = materialColors[4 % 10];
                                document.getElementById('ChartSinistriPervenutiLinee').ej2_instances[0].series[1].fill = materialColors[5 % 10];
                                document.getElementById('ChartSinistriPervenutiBarre').ej2_instances[0].series[0].dataSource = data3;
                                document.getElementById('ChartSinistriPervenutiBarre').ej2_instances[0].series[1].dataSource = data4; 
                                document.getElementById('ChartSinistriPervenutiBarre').ej2_instances[0].series[0].fill = materialColors[4 % 10];
                                document.getElementById('ChartSinistriPervenutiBarre').ej2_instances[0].series[1].fill = materialColors[5 % 10];
                                document.getElementById('GridDeltaMensili').ej2_instances[0].dataSource = GridData1;
                                document.getElementById('GridDeltaCompagnie').ej2_instances[0].dataSource = GridData2;
                                document.getElementById('divChartBarre').style.display = 'none';
                                document.getElementById('divChartLinee').style.removeProperty('display');
                                document.getElementById('visioneAndamento').ej2_instances[0].checked = true;
                                caricaDatiSinistriPervenuti2(thisClone);                      
                            }, 300);
                        });  
                    }, 300);                     
                }, true, false);
            }, true, false);  
        }

function caricaDatiSinistriPervenuti2(thisClone) {
    let GridData1 = [], groupedArr1 = [], groupedArr2 = [];  
    const dataDa = moment(thisClone.dateRangeInstance.startDate).format('YYYYMMDD');
    const dataA = moment(thisClone.dateRangeInstance.endDate).format('YYYYMMDD');
    const dataDaPrec = moment(thisClone.dateRangeInstance.startDate).subtract(1, 'years').format("YYYYMMDD");
    const dataAPrec = moment(thisClone.dateRangeInstance.endDate).subtract(1, 'years').format("YYYYMMDD");
    CustomJS.getRemoteData2(CustomJS.APIUrlProd,
        `token=${CustomJS.tokenProd}&azione=entrati&dataDa=${dataDaPrec}&dataA=${dataAPrec}&frequenza=mese&compagnie=${CompagnieVisual.join(',')}`, 
        'POST', "application/x-www-form-urlencoded", (retData) => {               
            groupedArr1 = _.groupBy(retData, 'DescrizioneCompagnia');  
            let totRiga1 = (arr) => { let totale = 0; Object.values(arr).forEach((elm,idx) => { totale += elm?.sum('entrati'); }); return totale; };  
            Object.values(groupedArr1).forEach((elm, idx) => {
                GridData1.push({ DescrizioneCompagnia: Object.keys(groupedArr1)[idx], PervAnnoPrec: elm.sum('entrati') });       
            }); 
            CustomJS.getRemoteData2(CustomJS.APIUrlProd,
                `token=${CustomJS.tokenProd}&azione=entrati&dataDa=${dataDa}&dataA=${dataA}&frequenza=mese&compagnie=${CompagnieVisual.join(',')}`, 
                'POST', "application/x-www-form-urlencoded", (retData) => {                    
                    groupedArr2 = _.groupBy(retData, 'DescrizioneCompagnia');    
                    Object.values(groupedArr2).forEach((elm, idx) => {
                        let arrObj = _.find(GridData1, elm2 => { return elm2.DescrizioneCompagnia === Object.keys(groupedArr2)[idx] });
                        if (arrObj) {
                            arrObj.PervAnnoCorr = elm.sum('entrati');
                            arrObj.DeltaPerc = parseFloat(((arrObj.PervAnnoCorr - arrObj.PervAnnoPrec) / arrObj.PervAnnoPrec) * 100).toFixed(2);
                        }
                        else {
                            GridData1.push({ DescrizioneCompagnia: Object.keys(groupedArr2)[idx], PervAnnoPrec: '', PervAnnoCorr: elm.sum('entrati'), DeltaPerc: '' }); 
                        }
                    }); 
                    GridData1.push({ DescrizioneCompagnia: 'TOTALI', PervAnnoPrec: totRiga1(groupedArr1), PervAnnoCorr: totRiga1(groupedArr2), DeltaPerc: parseFloat(((totRiga1(groupedArr2) - totRiga1(groupedArr1)) / totRiga1(groupedArr1)) * 100).toFixed(2) });       
                    document.getElementById('GridDeltaCompagniePeriodo').ej2_instances[0].dataSource = GridData1;
                    thisClone.toggleBlocking();
            }, true, false);
    }, true, false); 
}

function caricaDatiIndirizziSinistri(thisClone) {       
    document.getElementById('accordionStatistiche').ej2_instances[0].enableItem(1, true);                  
    document.getElementById('accordionStatistiche').ej2_instances[0].expandItem(false, 0);
    for (let ind=0;ind<=200000000;ind++) {};
    document.getElementById('accordionStatistiche').ej2_instances[0].expandItem(true, 1);
    setTimeout(() => {      
        ++thisClone.childKey;     
        ReactDOM.render(<MappaSinistri key={thisClone.childKey} />, document.getElementById('contenutoDinamicoStatistica'), () => {
         });                         
    }, 500);
    setTimeout(() => {    
        document.getElementById('titoloMappaSinistri').innerText = 'RAPPRESENTAZIONE GRAFICA TERRITORIALE SINISTRI';   
        document.getElementById('elencoCompagnieMappaSinistri').innerHTML = `Compagnia/e: ${document.getElementById('compagnieSelezionate').ej2_instances[0].text.replace(/,/g, ', ')}`;             
        thisClone.toggleBlocking();  
        setTimeout(() => { scrollAuto(1000, 130); }, 1000);
    }, 2000);
}

function caricaDatiSinistriSecondoPeritoSurveyor(thisClone) {
    const dataDa = moment(thisClone.dateRangeInstance.startDate).format('YYYYMMDD');
    const dataA = moment(thisClone.dateRangeInstance.endDate).format('YYYYMMDD');
    let dsGriglia1 = [], dsGriglia2 = []; 
    CustomJS.getRemoteData2(CustomJS.APIUrlProd,
        `token=${CustomJS.tokenProd}&azione=secondoPeritoSurveyor&dataDa=${dataDa}&dataA=${dataA}&compagnie=${CompagnieVisual.join(',')}`, 
        'POST', "application/x-www-form-urlencoded", (retData) => {
            let groupedData = _.groupBy(retData, 'NominativoSecondoPeritoSurveyor');            
            Object.values(groupedData).forEach((elm, idx) => {
                let objPrimoLiv = { NominativoPerito: Object.keys(groupedData)[idx], 
                                    NumIncSecPerito: _.filter(elm, elm2 => { return elm2.SecondoPeritoSurveyor === 'Secondo Perito' }).length,
                                    NumIncSurveyor: _.filter(elm, elm2 => { return elm2.SecondoPeritoSurveyor === 'Surveyor' }).length };
                dsGriglia1.push(objPrimoLiv);
                
                elm.forEach(elm3 => { 
                    let objSecondoLiv = { NominativoPerito: Object.keys(groupedData)[idx], 
                                          IdSinistro: elm3.IdSinistro,
                                          SecondoPeritoSurveyor: elm3.SecondoPeritoSurveyor,
                                          NominativoPeritoPrincipale: elm3.NominativoPeritoPrincipale,
                                          StatoSinistro: elm3.StatoSinistro };
                    dsGriglia2.push(objSecondoLiv);
                });                
            }); 
            document.getElementById('accordionStatistiche').ej2_instances[0].enableItem(1, true);                  
            document.getElementById('accordionStatistiche').ej2_instances[0].expandItem(false, 0);
            for (let ind=0;ind<=200000000;ind++) {};
            document.getElementById('accordionStatistiche').ej2_instances[0].expandItem(true, 1);
            setTimeout(() => {
                ReactDOM.render(<React.Fragment><GridSecondoPeritoSurveyor /></React.Fragment>, 
                                 document.getElementById('contenutoDinamicoStatistica'), () => {
                    document.getElementById('GridSecondoPeritoSurveyor').ej2_instances[0].dataSource = dsGriglia1;
                    document.getElementById('GridSecondoPeritoSurveyor').ej2_instances[0].childGrid.dataSource = dsGriglia2;
                    document.getElementById('GridSecondoPeritoSurveyor').ej2_instances[0].refresh();
                });                        
            }, 300); 
            setTimeout(() => {    
                document.getElementById('titoloSecondoPeritoSurveyor').innerText = 'INCARICHI AFFIDATI A SECONDO PERITO/SURVEYOR';   
                document.getElementById('elencoCompagnieSecondoPeritoSurveyor').innerHTML = `Compagnia/e: ${document.getElementById('compagnieSelezionate').ej2_instances[0].text.replace(/,/g, ', ')}`;             
                thisClone.toggleBlocking();  
                //setTimeout(() => { scrollAuto(1000, 0); }, 1000);
            }, 600);  
    }, true, false);
}

function caricaDatiConfrontoParcellaMedia(thisClone) {
    let data1 = [], data2 = [], data3 = [], data4 = [], GridData1 = [], GridData2 = [], groupedArr1 = [], groupedArr2 = [], groupedArr3 = [], groupedArr4 = [];
    CustomJS.callAPI(`${CustomJS.APIStatisticheURLProd}/api/Statistiche/leggiSinistriParcellaMedia/${CompagnieVisual.join(',')}/${(yyyy - 1).toString()}0101/${(yyyy - 1).toString()}1231`, '', 'GET',
        '', retData => { 
            if (retData.response === 'KO') {
                let messaggio = retData.msg;
                toast.warn(messaggio, {
                    containerId: 'toastContainer1',
                    onClose: () => toast.dismiss(),                    
                    position: "bottom-right",
                    autoClose: 15000,
                    hideProgressBar: false,            
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: false,
                    progress: undefined,
                    newestOnTop: true,
                    rtl: false,
                    pauseOnFocusLoss: true
                });
                thisClone.toggleBlocking();
                return;
            }      
            retData.forEach(elm => { 
                elm.periodo = moment(elm.DATA_CHI).format('YYYYMM');
                elm.PARCELLA = parseFloat(elm.PARCELLA);
            });       
            let arrTemp = [], arrFiltr1 = [], arrFiltr2 = [], arrFiltr3 = [], arrFiltr4 = [], arrFiltr5 = [];
            thisClone.checkSel1.forEach((elm, idx) => {
                if (idx === 0)
                    arrFiltr1 = _.filter(retData, elm2 => { return elm2.ID_FENOMENO === elm; });
                if (idx === 1)
                    arrFiltr2 = _.filter(retData, elm2 => { return elm2.ID_FENOMENO === elm; });
                if (idx === 2)
                    arrFiltr3 = _.filter(retData, elm2 => { return elm2.ID_FENOMENO === elm; });                
            });    
            arrTemp = _.union(arrFiltr1, arrFiltr2, arrFiltr3);    
            
            thisClone.checkSel2.forEach((elm, idx) => {
                if (idx === 0)
                    arrFiltr4 = _.filter(arrTemp, elm2 => { return elm2.CAT_PROP === elm; });
                if (idx === 1)
                    arrFiltr5 = _.filter(arrTemp, elm2 => { return elm2.CAT_PROP === elm; });                               
            });  
            arrTemp = _.union(arrFiltr4, arrFiltr5);
            groupedArr1 = _.groupBy(arrTemp, 'periodo');
            for (let ind=1; ind<=12;ind++) {  
                let MONTH = _.filter(Object.values(groupedArr1), (obj) => { return !CustomJS.isNullEmpty(_.find(obj, obj2 => { return obj2.periodo === (yyyy-1).toString() + ind.toString().padStart(2,'0') }))});
                data1.push({ x: ind === 1 ? new Date(yyyy, 0, 1) : ind === 2 ? new Date(yyyy, 1, 1)  : ind === 3 ? new Date(yyyy, 2, 1)  : ind === 4 ? new Date(yyyy, 3, 1)
                              : ind === 5 ? new Date(yyyy, 4, 1)  : ind === 6 ? new Date(yyyy, 5, 1) : ind === 7 ? new Date(yyyy, 6, 1)  : ind === 8 ? new Date(yyyy, 7, 1)  
                              : ind === 9 ? new Date(yyyy, 8, 1)  : ind === 10 ? new Date(yyyy, 9, 1)  : ind === 11 ? new Date(yyyy, 10, 1)  : new Date(yyyy, 11, 1) , y: MONTH[0]?.mediaStandard('PARCELLA') }); 
                data3.push({ x: ind === 1 ? 'Gennaio' : ind === 2 ? 'Febbraio' : ind === 3 ? 'Marzo' : ind === 4 ? 'Aprile' : ind === 5 ? 'Maggio' : ind === 6 ? 'Giugno' 
                              : ind === 7 ? 'Luglio' : ind === 8 ? 'Agosto' : ind === 9 ? 'Settembre' : ind === 10 ? 'Ottobre' : ind === 11 ? 'Novembre' : 'Dicembre', y: MONTH[0]?.mediaStandard('PARCELLA') }); 
            }   
            let totRiga1 = (arr) => { let mediaTotale = 0, pesoTotale = 0; Object.values(arr).forEach((elm,idx) => { mediaTotale += (elm?.mediaStandard('PARCELLA') * elm.length); pesoTotale += elm.length; }); return (mediaTotale / pesoTotale).toFixed(2); };
            let totRiga2 = (arr) => { let sinistriTot = 0; Object.values(arr).forEach((elm,idx) => { sinistriTot += elm.length; }); return sinistriTot; };
            GridData1.push({ rowDescr: `Parcella Media ${(yyyy - 1).toString()}`, 
                            GEN: `${groupedArr1[(yyyy - 1).toString() + '01']?.mediaStandard('PARCELLA').toLocaleString('it-IT')} (${groupedArr1[(yyyy - 1).toString() + '01']?.length.toLocaleString('it-IT')})`, 
                            FEB: `${groupedArr1[(yyyy - 1).toString() + '02']?.mediaStandard('PARCELLA').toLocaleString('it-IT')} (${groupedArr1[(yyyy - 1).toString() + '02']?.length.toLocaleString('it-IT')})`, 
                            MAR: `${groupedArr1[(yyyy - 1).toString() + '03']?.mediaStandard('PARCELLA').toLocaleString('it-IT')} (${groupedArr1[(yyyy - 1).toString() + '03']?.length.toLocaleString('it-IT')})`,
                            APR: `${groupedArr1[(yyyy - 1).toString() + '04']?.mediaStandard('PARCELLA').toLocaleString('it-IT')} (${groupedArr1[(yyyy - 1).toString() + '04']?.length.toLocaleString('it-IT')})`,
                            MAG: `${groupedArr1[(yyyy - 1).toString() + '05']?.mediaStandard('PARCELLA').toLocaleString('it-IT')} (${groupedArr1[(yyyy - 1).toString() + '05']?.length.toLocaleString('it-IT')})`,
                            GIU: `${groupedArr1[(yyyy - 1).toString() + '06']?.mediaStandard('PARCELLA').toLocaleString('it-IT')} (${groupedArr1[(yyyy - 1).toString() + '06']?.length.toLocaleString('it-IT')})`, 
                            LUG: `${groupedArr1[(yyyy - 1).toString() + '07']?.mediaStandard('PARCELLA').toLocaleString('it-IT')} (${groupedArr1[(yyyy - 1).toString() + '07']?.length.toLocaleString('it-IT')})`,
                            AGO: `${groupedArr1[(yyyy - 1).toString() + '08']?.mediaStandard('PARCELLA').toLocaleString('it-IT')} (${groupedArr1[(yyyy - 1).toString() + '08']?.length.toLocaleString('it-IT')})`,
                            SET: `${groupedArr1[(yyyy - 1).toString() + '09']?.mediaStandard('PARCELLA').toLocaleString('it-IT')} (${groupedArr1[(yyyy - 1).toString() + '09']?.length.toLocaleString('it-IT')})`,
                            OTT: `${groupedArr1[(yyyy - 1).toString() + '10']?.mediaStandard('PARCELLA').toLocaleString('it-IT')} (${groupedArr1[(yyyy - 1).toString() + '10']?.length.toLocaleString('it-IT')})`,
                            NOV: `${groupedArr1[(yyyy - 1).toString() + '11']?.mediaStandard('PARCELLA').toLocaleString('it-IT')} (${groupedArr1[(yyyy - 1).toString() + '11']?.length.toLocaleString('it-IT')})`, 
                            DIC: `${groupedArr1[(yyyy - 1).toString() + '12']?.mediaStandard('PARCELLA').toLocaleString('it-IT')} (${groupedArr1[(yyyy - 1).toString() + '12']?.length.toLocaleString('it-IT')})`, TOT: `${totRiga1(groupedArr1).toLocaleString()} (${totRiga2(groupedArr1).toLocaleString()})` });   
            groupedArr3 = _.groupBy(arrTemp, 'COMPAGNIA');    
            Object.values(groupedArr3).forEach((elm, idx) => {
                GridData2.push({ DescrizioneCompagnia: Object.keys(groupedArr3)[idx], SinistriChiusiAnnoPrec : elm.length.toLocaleString('it-IT'), ParcMediaAnnoPrec: elm.mediaStandard('PARCELLA') });       
            }); 
            CustomJS.callAPI(`${CustomJS.APIStatisticheURLProd}/api/Statistiche/leggiSinistriParcellaMedia/${CompagnieVisual.join(',')}/${(yyyy).toString()}0101/${dataCorrente}`, '', 'GET',
            '', retData => { 
                if (retData.response === 'KO') {
                    let messaggio = retData.msg;
                    toast.warn(messaggio, {
                        containerId: 'toastContainer1',
                        onClose: () => toast.dismiss(),                    
                        position: "bottom-right",
                        autoClose: 15000,
                        hideProgressBar: false,            
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: false,
                        progress: undefined,
                        newestOnTop: true,
                        rtl: false,
                        pauseOnFocusLoss: true
                    });
                    thisClone.toggleBlocking();
                    return;
                }     
                retData.forEach(elm => { 
                    elm.periodo = moment(elm.DATA_CHI).format('YYYYMM');
                    elm.PARCELLA = parseFloat(elm.PARCELLA);
                });    
                arrTemp = []; arrFiltr1 = []; arrFiltr2 = []; arrFiltr3 = []; arrFiltr4 = []; arrFiltr5 = [];
                thisClone.checkSel1.forEach((elm, idx) => {
                    if (idx === 0)
                        arrFiltr1 = _.filter(retData, elm2 => { return elm2.ID_FENOMENO === elm; });
                    if (idx === 1)
                        arrFiltr2 = _.filter(retData, elm2 => { return elm2.ID_FENOMENO === elm; });
                    if (idx === 2)
                        arrFiltr3 = _.filter(retData, elm2 => { return elm2.ID_FENOMENO === elm; });                
                });    
                arrTemp = _.union(arrFiltr1, arrFiltr2, arrFiltr3);
                thisClone.checkSel2.forEach((elm, idx) => {
                    if (idx === 0)
                        arrFiltr4 = _.filter(arrTemp, elm2 => { return elm2.CAT_PROP === elm; });
                    if (idx === 1)
                        arrFiltr5 = _.filter(arrTemp, elm2 => { return elm2.CAT_PROP === elm; });                               
                });  
                arrTemp = _.union(arrFiltr4, arrFiltr5);
                groupedArr2 = _.groupBy(arrTemp, 'periodo');               
                for (let ind=1; ind<=12;ind++) {  
                    let MONTH = _.filter(Object.values(groupedArr2), (obj) => { return !CustomJS.isNullEmpty(_.find(obj, obj2 => { return obj2.periodo === yyyy.toString() + ind.toString().padStart(2,'0') }))});
                    data2.push({ x: ind === 1 ? new Date(yyyy, 0, 1) : ind === 2 ? new Date(yyyy, 1, 1)  : ind === 3 ? new Date(yyyy, 2, 1)  : ind === 4 ? new Date(yyyy, 3, 1)
                                  : ind === 5 ? new Date(yyyy, 4, 1)  : ind === 6 ? new Date(yyyy, 5, 1) : ind === 7 ? new Date(yyyy, 6, 1)  : ind === 8 ? new Date(yyyy, 7, 1)  
                                  : ind === 9 ? new Date(yyyy, 8, 1)  : ind === 10 ? new Date(yyyy, 9, 1)  : ind === 11 ? new Date(yyyy, 10, 1)  : new Date(yyyy, 11, 1) , y: MONTH[0]?.mediaStandard('PARCELLA') }); 
                    data4.push({ x: ind === 1 ? 'Gennaio' : ind === 2 ? 'Febbraio' : ind === 3 ? 'Marzo' : ind === 4 ? 'Aprile' : ind === 5 ? 'Maggio' : ind === 6 ? 'Giugno' 
                                  : ind === 7 ? 'Luglio' : ind === 8 ? 'Agosto' : ind === 9 ? 'Settembre' : ind === 10 ? 'Ottobre' : ind === 11 ? 'Novembre' : 'Dicembre', y: MONTH[0]?.mediaStandard('PARCELLA') }); 
                }   
                GridData1.push({ rowDescr: `Parcella Media ${yyyy.toString()}`,
                                GEN: `${groupedArr2[yyyy.toString() + '01']?.mediaStandard('PARCELLA').toLocaleString('it-IT')} (${groupedArr2[yyyy.toString() + '01']?.length.toLocaleString('it-IT')})`, 
                                FEB: `${groupedArr2[yyyy.toString() + '02']?.mediaStandard('PARCELLA').toLocaleString('it-IT')} (${groupedArr2[yyyy.toString() + '02']?.length.toLocaleString('it-IT')})`, 
                                MAR: `${groupedArr2[yyyy.toString() + '03']?.mediaStandard('PARCELLA').toLocaleString('it-IT')} (${groupedArr2[yyyy.toString() + '03']?.length.toLocaleString('it-IT')})`,
                                APR: `${groupedArr2[yyyy.toString() + '04']?.mediaStandard('PARCELLA').toLocaleString('it-IT')} (${groupedArr2[yyyy.toString() + '04']?.length.toLocaleString('it-IT')})`, 
                                MAG: `${groupedArr2[yyyy.toString() + '05']?.mediaStandard('PARCELLA').toLocaleString('it-IT')} (${groupedArr2[yyyy.toString() + '05']?.length.toLocaleString('it-IT')})`,
                                GIU: `${groupedArr2[yyyy.toString() + '06']?.mediaStandard('PARCELLA').toLocaleString('it-IT')} (${groupedArr2[yyyy.toString() + '06']?.length.toLocaleString('it-IT')})`, 
                                LUG: `${groupedArr2[yyyy.toString() + '07']?.mediaStandard('PARCELLA').toLocaleString('it-IT')} (${groupedArr2[yyyy.toString() + '07']?.length.toLocaleString('it-IT')})`,
                                AGO: `${groupedArr2[yyyy.toString() + '08']?.mediaStandard('PARCELLA').toLocaleString('it-IT')} (${groupedArr2[yyyy.toString() + '08']?.length.toLocaleString('it-IT')})`,
                                SET: `${groupedArr2[yyyy.toString() + '09']?.mediaStandard('PARCELLA').toLocaleString('it-IT')} (${groupedArr2[yyyy.toString() + '09']?.length.toLocaleString('it-IT')})`,
                                OTT: `${groupedArr2[yyyy.toString() + '10']?.mediaStandard('PARCELLA').toLocaleString('it-IT')} (${groupedArr2[yyyy.toString() + '10']?.length.toLocaleString('it-IT')})`,
                                NOV: `${groupedArr2[yyyy.toString() + '11']?.mediaStandard('PARCELLA').toLocaleString('it-IT')} (${groupedArr2[yyyy.toString() + '11']?.length.toLocaleString('it-IT')})`, 
                                DIC: `${groupedArr2[yyyy.toString() + '12']?.mediaStandard('PARCELLA').toLocaleString('it-IT')} (${groupedArr2[yyyy.toString() + '12']?.length.toLocaleString('it-IT')})`, TOT: `${totRiga1(groupedArr2).toLocaleString()} (${totRiga2(groupedArr2).toLocaleString()})`});   
                GridData1.push({ rowDescr: 'Delta %', 
                                GEN: parseFloat(((groupedArr2[yyyy.toString() + '01']?.mediaStandard('PARCELLA') - groupedArr1[(yyyy-1).toString() + '01']?.mediaStandard('PARCELLA')) / groupedArr1[(yyyy-1).toString() + '01']?.mediaStandard('PARCELLA')) * 100).toFixed(2), 
                                FEB: parseFloat(((groupedArr2[yyyy.toString() + '02']?.mediaStandard('PARCELLA') - groupedArr1[(yyyy-1).toString() + '02']?.mediaStandard('PARCELLA')) / groupedArr1[(yyyy-1).toString() + '02']?.mediaStandard('PARCELLA')) * 100).toFixed(2), 
                                MAR: parseFloat(((groupedArr2[yyyy.toString() + '03']?.mediaStandard('PARCELLA') - groupedArr1[(yyyy-1).toString() + '03']?.mediaStandard('PARCELLA')) / groupedArr1[(yyyy-1).toString() + '03']?.mediaStandard('PARCELLA')) * 100).toFixed(2), 
                                APR: parseFloat(((groupedArr2[yyyy.toString() + '04']?.mediaStandard('PARCELLA') - groupedArr1[(yyyy-1).toString() + '04']?.mediaStandard('PARCELLA')) / groupedArr1[(yyyy-1).toString() + '04']?.mediaStandard('PARCELLA')) * 100).toFixed(2),
                                MAG: parseFloat(((groupedArr2[yyyy.toString() + '05']?.mediaStandard('PARCELLA') - groupedArr1[(yyyy-1).toString() + '05']?.mediaStandard('PARCELLA')) / groupedArr1[(yyyy-1).toString() + '05']?.mediaStandard('PARCELLA')) * 100).toFixed(2),
                                GIU: parseFloat(((groupedArr2[yyyy.toString() + '06']?.mediaStandard('PARCELLA') - groupedArr1[(yyyy-1).toString() + '06']?.mediaStandard('PARCELLA')) / groupedArr1[(yyyy-1).toString() + '06']?.mediaStandard('PARCELLA')) * 100).toFixed(2), 
                                LUG: parseFloat(((groupedArr2[yyyy.toString() + '07']?.mediaStandard('PARCELLA') - groupedArr1[(yyyy-1).toString() + '07']?.mediaStandard('PARCELLA')) / groupedArr1[(yyyy-1).toString() + '07']?.mediaStandard('PARCELLA')) * 100).toFixed(2), 
                                AGO: parseFloat(((groupedArr2[yyyy.toString() + '08']?.mediaStandard('PARCELLA') - groupedArr1[(yyyy-1).toString() + '08']?.mediaStandard('PARCELLA')) / groupedArr1[(yyyy-1).toString() + '08']?.mediaStandard('PARCELLA')) * 100).toFixed(2), 
                                SET: parseFloat(((groupedArr2[yyyy.toString() + '09']?.mediaStandard('PARCELLA') - groupedArr1[(yyyy-1).toString() + '09']?.mediaStandard('PARCELLA')) / groupedArr1[(yyyy-1).toString() + '09']?.mediaStandard('PARCELLA')) * 100).toFixed(2), 
                                OTT: parseFloat(((groupedArr2[yyyy.toString() + '10']?.mediaStandard('PARCELLA') - groupedArr1[(yyyy-1).toString() + '10']?.mediaStandard('PARCELLA')) / groupedArr1[(yyyy-1).toString() + '10']?.mediaStandard('PARCELLA')) * 100).toFixed(2),
                                NOV: parseFloat(((groupedArr2[yyyy.toString() + '11']?.mediaStandard('PARCELLA') - groupedArr1[(yyyy-1).toString() + '11']?.mediaStandard('PARCELLA')) / groupedArr1[(yyyy-1).toString() + '11']?.mediaStandard('PARCELLA')) * 100).toFixed(2), 
                                DIC: parseFloat(((groupedArr2[yyyy.toString() + '12']?.mediaStandard('PARCELLA') - groupedArr1[(yyyy-1).toString() + '12']?.mediaStandard('PARCELLA')) / groupedArr1[(yyyy-1).toString() + '12']?.mediaStandard('PARCELLA')) * 100).toFixed(2),
                                TOT: parseFloat(((totRiga1(groupedArr2) - totRiga1(groupedArr1)) / totRiga1(groupedArr1)) * 100).toFixed(2)});
                groupedArr4 = _.groupBy(arrTemp, 'COMPAGNIA');    
                Object.values(groupedArr4).forEach((elm, idx) => {
                    let arrObj = _.find(GridData2, elm2 => { return elm2.DescrizioneCompagnia === Object.keys(groupedArr4)[idx] });
                    if (arrObj) {
                        arrObj.SinistriChiusiAnnoCorr = elm.length.toLocaleString('it-IT');
                        arrObj.ParcMediaAnnoCorr = elm.mediaStandard('PARCELLA');
                        arrObj.DeltaPerc = parseFloat(((arrObj.ParcMediaAnnoCorr - arrObj.ParcMediaAnnoPrec) / arrObj.ParcMediaAnnoPrec) * 100).toFixed(2);
                    }
                    else {
                        GridData2.push({ DescrizioneCompagnia: Object.keys(groupedArr4)[idx], SinistriChiusiAnnoPrec: '', ParcMediaAnnoPrec: '', SinistriChiusiAnnoCorr: elm.length.toLocaleString('it-IT'), ParcMediaAnnoCorr: elm.mediaStandard('PARCELLA'), DeltaPerc: ''})  
                    }
                }); 
                GridData2.push({ DescrizioneCompagnia: 'TOTALI', SinistriChiusiAnnoPrec: totRiga2(groupedArr3).toLocaleString('it-IT'), ParcMediaAnnoPrec: totRiga1(groupedArr3).toLocaleString('it-IT'), SinistriChiusiAnnoCorr: totRiga2(groupedArr4).toLocaleString('it-IT'), ParcMediaAnnoCorr: totRiga1(groupedArr4).toLocaleString('it-IT'), DeltaPerc: parseFloat(((totRiga1(groupedArr4) - totRiga1(groupedArr3)) / totRiga1(groupedArr3)) * 100).toFixed(2)});
                document.getElementById('accordionStatistiche').ej2_instances[0].enableItem(1, true);                  
                document.getElementById('accordionStatistiche').ej2_instances[0].expandItem(false, 0);
                for (let ind=0;ind<=200000000;ind++) {};
                document.getElementById('accordionStatistiche').ej2_instances[0].expandItem(true, 1);    
                setTimeout(() => {
                    ++thisClone.childKey;
                    ReactDOM.render(<React.Fragment><PaginaParcellaMedia key={thisClone.childKey} /></React.Fragment>, 
                                     document.getElementById('contenutoDinamicoStatistica'), () => {

                        setTimeout(() => {
                            document.getElementById('titoloChartParcellaMedia').innerText = 'CONFRONTO PARCELLA MEDIA ANNO CORRENTE/ANNO PRECEDENTE';
                            document.getElementById('elencoCompagnieChartParcellaMedia').innerHTML = `Compagnia/e: ${document.getElementById('compagnieSelezionate').ej2_instances[0].text.replace(/,/g, ', ')}`;               
                            document.getElementById('ChartParcellaMediaLinee').ej2_instances[0].series[0].dataSource = data1;
                            document.getElementById('ChartParcellaMediaLinee').ej2_instances[0].series[1].dataSource = data2; 
                            document.getElementById('ChartParcellaMediaLinee').ej2_instances[0].series[0].fill = materialColors[4 % 10];
                            document.getElementById('ChartParcellaMediaLinee').ej2_instances[0].series[1].fill = materialColors[5 % 10];
                            document.getElementById('ChartParcellaMediaLinee').ej2_instances[0].primaryYAxis.interval = Math.round(data1.mediaStandard('y')/ 4) ; //< 400 ? 30 : Math.round(data1.mediaStandard('y') / 3);
                            document.getElementById('ChartParcellaMediaBarre').ej2_instances[0].series[0].dataSource = data3;
                            document.getElementById('ChartParcellaMediaBarre').ej2_instances[0].series[1].dataSource = data4; 
                            document.getElementById('ChartParcellaMediaBarre').ej2_instances[0].series[0].fill = materialColors[4 % 10];
                            document.getElementById('ChartParcellaMediaBarre').ej2_instances[0].series[1].fill = materialColors[5 % 10];
                            document.getElementById('GridDeltaMensili').ej2_instances[0].dataSource = GridData1;
                            document.getElementById('GridDeltaCompagnie').ej2_instances[0].dataSource = GridData2;
                            document.getElementById('divChartBarre').style.display = 'none';
                            document.getElementById('divChartLinee').style.removeProperty('display');
                            document.getElementById('visioneAndamento').ej2_instances[0].checked = true;
                            caricaDatiConfrontoParcellaMedia2(thisClone);
                        }, 300);
                    });  
                }, 300);
            }, error => {
                let messaggio = _.isString(error) && error.indexOf('Not Found') > -1 ? CustomJS.messaggioAPIBackendNonTrovata : CustomJS.messaggioNoBackend;
                toast.warn(messaggio, {
                    containerId: 'toastContainer1',
                    onClose: () => toast.dismiss(),                    
                    position: "bottom-right",
                    autoClose: 15000,
                    hideProgressBar: false,            
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: false,
                    progress: undefined,
                    newestOnTop: true,
                    rtl: false,
                    pauseOnFocusLoss: true});
                thisClone.toggleBlocking();
            }, true, false);           
        }, error => {
            let messaggio = _.isString(error) && error.indexOf('Not Found') > -1 ? CustomJS.messaggioAPIBackendNonTrovata : CustomJS.messaggioNoBackend;
            toast.warn(messaggio, {
                containerId: 'toastContainer1',
                onClose: () => toast.dismiss(),                    
                position: "bottom-right",
                autoClose: 15000,
                hideProgressBar: false,            
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                progress: undefined,
                newestOnTop: true,
                rtl: false,
                pauseOnFocusLoss: true});
            thisClone.toggleBlocking();
        }, true, false);
}

function caricaDatiConfrontoParcellaMedia2(thisClone) {
    let GridData1 = [], groupedArr1 = [], groupedArr2 = [];  
    const dataDa = moment(thisClone.dateRangeInstance.startDate).format('YYYYMMDD');
    const dataA = moment(thisClone.dateRangeInstance.endDate).format('YYYYMMDD');
    const dataDaPrec = moment(thisClone.dateRangeInstance.startDate).subtract(1, 'years').format("YYYYMMDD");
    const dataAPrec = moment(thisClone.dateRangeInstance.endDate).subtract(1, 'years').format("YYYYMMDD");    
    CustomJS.callAPI(`${CustomJS.APIStatisticheURLProd}/api/Statistiche/leggiSinistriParcellaMedia/${CompagnieVisual.join(',')}/${dataDaPrec}/${dataAPrec}`, '', 'GET',
        '', retData => { 
            if (retData.response === 'KO') {
                let messaggio = retData.msg;
                toast.warn(messaggio, {
                    containerId: 'toastContainer1',
                    onClose: () => toast.dismiss(),                    
                    position: "bottom-right",
                    autoClose: 15000,
                    hideProgressBar: false,            
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: false,
                    progress: undefined,
                    newestOnTop: true,
                    rtl: false,
                    pauseOnFocusLoss: true
                });
                thisClone.toggleBlocking();
                return;
            } 
            retData.forEach(elm => { 
                elm.periodo = moment(elm.DATA_CHI).format('YYYYMM');
                elm.PARCELLA = parseFloat(elm.PARCELLA);
            }); 
            let arrTemp = [], arrFiltr1 = [], arrFiltr2 = [], arrFiltr3 = [], arrFiltr4 = [], arrFiltr5 = [];
            thisClone.checkSel1.forEach((elm, idx) => {
                if (idx === 0)
                    arrFiltr1 = _.filter(retData, elm2 => { return elm2.ID_FENOMENO === elm; });
                if (idx === 1)
                    arrFiltr2 = _.filter(retData, elm2 => { return elm2.ID_FENOMENO === elm; });
                if (idx === 2)
                    arrFiltr3 = _.filter(retData, elm2 => { return elm2.ID_FENOMENO === elm; });                
            });    
            arrTemp = _.union(arrFiltr1, arrFiltr2, arrFiltr3);
            thisClone.checkSel2.forEach((elm, idx) => {
                if (idx === 0)
                    arrFiltr4 = _.filter(arrTemp, elm2 => { return elm2.CAT_PROP === elm; });
                if (idx === 1)
                    arrFiltr5 = _.filter(arrTemp, elm2 => { return elm2.CAT_PROP === elm; });                               
            });  
            arrTemp = _.union(arrFiltr4, arrFiltr5);
            groupedArr1 = _.groupBy(arrTemp, 'COMPAGNIA'); 
            let totRiga1 = (arr) => { let mediaTotale = 0, pesoTotale = 0; Object.values(arr).forEach((elm,idx) => { mediaTotale += (elm?.mediaStandard('PARCELLA') * elm.length); pesoTotale += elm.length; }); return (mediaTotale / pesoTotale).toFixed(2); };
            let totRiga2 = (arr) => { let sinistriTot = 0; Object.values(arr).forEach((elm,idx) => { sinistriTot += elm.length; }); return sinistriTot; };
            Object.values(groupedArr1).forEach((elm, idx) => {
                GridData1.push({ DescrizioneCompagnia: Object.keys(groupedArr1)[idx], SinistriChiusiAnnoPrec : elm.length.toLocaleString('it-IT'), ParcMediaAnnoPrec: elm.mediaStandard('PARCELLA')});       
            }); 
            CustomJS.callAPI(`${CustomJS.APIStatisticheURLProd}/api/Statistiche/leggiSinistriParcellaMedia/${CompagnieVisual.join(',')}/${dataDa}/${dataA}`, '', 'GET',
            '', retData => { 
                if (retData.response === 'KO') {
                    let messaggio = retData.msg;
                    toast.warn(messaggio, {
                        containerId: 'toastContainer1',
                        onClose: () => toast.dismiss(),                    
                        position: "bottom-right",
                        autoClose: 15000,
                        hideProgressBar: false,            
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: false,
                        progress: undefined,
                        newestOnTop: true,
                        rtl: false,
                        pauseOnFocusLoss: true
                    });
                    thisClone.toggleBlocking();
                    return;
                } 
                retData.forEach(elm => { 
                    elm.periodo = moment(elm.DATA_CHI).format('YYYYMM');
                    elm.PARCELLA = parseFloat(elm.PARCELLA);
                });
                arrTemp = []; arrFiltr1 = []; arrFiltr2 = []; arrFiltr3 = []; arrFiltr4 = []; arrFiltr5 = [];
                thisClone.checkSel1.forEach((elm, idx) => {
                    if (idx === 0)
                        arrFiltr1 = _.filter(retData, elm2 => { return elm2.ID_FENOMENO === elm; });
                    if (idx === 1)
                        arrFiltr2 = _.filter(retData, elm2 => { return elm2.ID_FENOMENO === elm; });
                    if (idx === 2)
                        arrFiltr3 = _.filter(retData, elm2 => { return elm2.ID_FENOMENO === elm; });                
                });    
                arrTemp = _.union(arrFiltr1, arrFiltr2, arrFiltr3);            
                thisClone.checkSel2.forEach((elm, idx) => {
                    if (idx === 0)
                        arrFiltr4 = _.filter(arrTemp, elm2 => { return elm2.CAT_PROP === elm; });
                    if (idx === 1)
                        arrFiltr5 = _.filter(arrTemp, elm2 => { return elm2.CAT_PROP === elm; });                               
                });  
                arrTemp = _.union(arrFiltr4, arrFiltr5);
                groupedArr2 = _.groupBy(arrTemp, 'COMPAGNIA');    
                Object.values(groupedArr2).forEach((elm, idx) => {
                    let arrObj = _.find(GridData1, elm2 => { return elm2.DescrizioneCompagnia === Object.keys(groupedArr2)[idx] });
                    if (arrObj) {
                        arrObj.SinistriChiusiAnnoCorr = elm.length.toLocaleString('it-IT');
                        arrObj.ParcMediaAnnoCorr = elm.mediaStandard('PARCELLA');
                        arrObj.DeltaPerc = parseFloat(((arrObj.ParcMediaAnnoCorr - arrObj.ParcMediaAnnoPrec) / arrObj.ParcMediaAnnoPrec) * 100).toFixed(2);
                    }
                    else {
                        GridData1.push({ DescrizioneCompagnia: Object.keys(groupedArr2)[idx], SinistriChiusiAnnoPrec: '', ParcMediaAnnoPrec: '', SinistriChiusiAnnoCorr: elm.length.toLocaleString('it-IT'), ParcMediaAnnoCorr: elm.mediaStandard('PARCELLA'), DeltaPerc: ''})  
                    }
                }); 
                GridData1.push({ DescrizioneCompagnia: 'TOTALI', SinistriChiusiAnnoPrec: totRiga2(groupedArr1).toLocaleString('it-IT'), ParcMediaAnnoPrec: totRiga1(groupedArr1).toLocaleString('it-IT'), SinistriChiusiAnnoCorr: totRiga2(groupedArr2).toLocaleString('it-IT'), ParcMediaAnnoCorr: totRiga1(groupedArr2).toLocaleString('it-IT'), DeltaPerc: parseFloat(((totRiga1(groupedArr2) - totRiga1(groupedArr1)) / totRiga1(groupedArr1)) * 100).toFixed(2)});       
                document.getElementById('GridDeltaCompagniePeriodo').ej2_instances[0].dataSource = GridData1;                                 
                thisClone.toggleBlocking();            
            }, error => {
                let messaggio = _.isString(error) && error.indexOf('Not Found') > -1 ? CustomJS.messaggioAPIBackendNonTrovata : CustomJS.messaggioNoBackend;
                toast.warn(messaggio, {
                    containerId: 'toastContainer1',
                    onClose: () => toast.dismiss(),                    
                    position: "bottom-right",
                    autoClose: 15000,
                    hideProgressBar: false,            
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: false,
                    progress: undefined,
                    newestOnTop: true,
                    rtl: false,
                    pauseOnFocusLoss: true});
                thisClone.toggleBlocking();
            }, true, false);           
    }, error => {
        let messaggio = _.isString(error) && error.indexOf('Not Found') > -1 ? CustomJS.messaggioAPIBackendNonTrovata : CustomJS.messaggioNoBackend;
        toast.warn(messaggio, {
            containerId: 'toastContainer1',
            onClose: () => toast.dismiss(),                    
            position: "bottom-right",
            autoClose: 15000,
            hideProgressBar: false,            
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            progress: undefined,
            newestOnTop: true,
            rtl: false,
            pauseOnFocusLoss: true});
        thisClone.toggleBlocking();
    }, true, false);
}

function caricaDatiDettaglioSinistriPervenuti(thisClone) {
    let GridData1 = [];  
    const dataDa = moment(thisClone.dateRangeInstance.startDate).format('YYYYMMDD');
    const dataA = moment(thisClone.dateRangeInstance.endDate).format('YYYYMMDD');    
    CustomJS.callAPI(`${CustomJS.APIStatisticheURLProd}/api/Statistiche/leggiSinistriPervenuti/${CompagnieVisual.join(',')}/${dataDa}/${dataA}`, '', 'GET',
    '', retData => { 
        if (retData.response === 'KO') {
            let messaggio = retData.msg;
            toast.warn(messaggio, {
                containerId: 'toastContainer1',
                onClose: () => toast.dismiss(),                    
                position: "bottom-right",
                autoClose: 15000,
                hideProgressBar: false,            
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                progress: undefined,
                newestOnTop: true,
                rtl: false,
                pauseOnFocusLoss: true
            });
            thisClone.toggleBlocking();
            return;
        } 
        retData.forEach(elm => { 
            elm.gestFormaDiretta = elm.gestFormaDiretta === 0 ? 'NO' : 'SI';
            elm.FormaDiretta = elm.FormaDiretta === 0 ? 'NO' : 'SI';
            elm.sin_per = CustomJS.isNullEmpty(elm.sin_per) || elm.sin_per === '0000-00-00' ? '' : moment(elm.sin_per).format('DD/MM/YYYY');
            elm.data_incarico = CustomJS.isNullEmpty(elm.data_incarico) || elm.data_incarico === '0000-00-00'  ? '' : moment(elm.data_incarico).format('DD/MM/YYYY');
            elm.data_res = CustomJS.isNullEmpty(elm.data_res) || elm.data_res === '0000-00-00' ? '' : moment(elm.data_res).format('DD/MM/YYYY');
            elm.data_chi = CustomJS.isNullEmpty(elm.data_chi) || elm.data_chi === '0000-00-00' ? '' : moment(elm.data_chi).format('DD/MM/YYYY');
            GridData1.push(elm);       
        });  
        document.getElementById('accordionStatistiche').ej2_instances[0].enableItem(1, true);                  
            document.getElementById('accordionStatistiche').ej2_instances[0].expandItem(false, 0);
            for (let ind=0;ind<=200000000;ind++) {};
            document.getElementById('accordionStatistiche').ej2_instances[0].expandItem(true, 1);    
            setTimeout(() => {
                ++thisClone.childKey;
                ReactDOM.render(<React.Fragment><PaginaDettaglioSinistriPervenuti key={thisClone.childKey} /></React.Fragment>, 
                                    document.getElementById('contenutoDinamicoStatistica'), () => {

                    setTimeout(() => {
                        document.getElementById('titoloDettaglioSinistriPervenuti').innerText = 'DETTAGLIO SINISTRI PERVENUTI';
                        document.getElementById('elencoCompagnieDettaglioSinistriPervenuti').innerHTML = `Compagnia/e: ${document.getElementById('compagnieSelezionate').ej2_instances[0].text.replace(/,/g, ', ')}`;               
                        document.getElementById('GridDettaglioSinistriPervenuti').ej2_instances[0].dataSource = GridData1;
                    }, 300);
                    thisClone.toggleBlocking();
                });  
            }, 300);  
    }, error => {
        let messaggio = _.isString(error) && error.indexOf('Not Found') > -1 ? CustomJS.messaggioAPIBackendNonTrovata : CustomJS.messaggioNoBackend;
        toast.warn(messaggio, {
            containerId: 'toastContainer1',
            onClose: () => toast.dismiss(),                    
            position: "bottom-right",
            autoClose: 15000,
            hideProgressBar: false,            
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            progress: undefined,
            newestOnTop: true,
            rtl: false,
            pauseOnFocusLoss: true});
        thisClone.toggleBlocking();
    }, true, false);
}

function caricaDati(tipoStat, thisClone) {
    if (tipoStat === 5) {
        caricaDatiSinistriChiusi(thisClone);
        return;
    }
    if (tipoStat === 6) {
        caricaDatiSinistriEntratiChiusi(thisClone);
        return;
    }
    if (tipoStat === 7) {
        caricaDatiSinistriSecondoPeritoSurveyor(thisClone);
        return;
    }
    if (tipoStat === 8) {                
        caricaDatiSinistriPervenuti(thisClone);
        return;
    }
    if (tipoStat === 9) {                
        caricaDatiIndirizziSinistri(thisClone);
        return;
    }
    if (tipoStat === 10) {   
        thisClone.checkSel1 = [];
        thisClone.checkSel2 = [];
        if(window.localStorage.getItem("checkSemplice") === 'true')
           thisClone.checkSel1.push('Semplice')           
        if(window.localStorage.getItem("checkIntermedio") === 'true')
           thisClone.checkSel1.push('Intermedio')           
        if(window.localStorage.getItem("checkComplesso") === 'true')
           thisClone.checkSel1.push('Complesso')           
        if(window.localStorage.getItem("checkRE") === 'true')
           thisClone.checkSel2.push('RE')           
        if(window.localStorage.getItem("checkRC") === 'true')
           thisClone.checkSel2.push('RC')           
        caricaDatiConfrontoParcellaMedia(thisClone);
        return;
    }
    if (tipoStat === 11) {                
        caricaDatiDettaglioSinistriPervenuti(thisClone);
        return;
    }

    const raggruppamento = tipoStat === 1 ? 'area' : (tipoStat === 2 || tipoStat === 4) ? 'provincia' : tipoStat === 3 ? 'rerc' : '';
    CustomJS.getRemoteData2(CustomJS.APIUrlProd,
        `token=${CustomJS.tokenProd}&azione=scaricoCompagnie&raggruppamento=${raggruppamento}`, 
        'POST', "application/x-www-form-urlencoded", (retData) => { 
            let globalChartData = [];              
            retData.forEach((elm, idx) => {                
                if (CompagnieVisual.length > 0) {
                    let peritoSel = _.find(globalChartData, (obj) => { return obj.NominativoPerito === elm.NominativoPerito; });
                    if (CustomJS.isNullEmpty(peritoSel) || tipoStat === 1 || tipoStat === 2 || tipoStat === 4) {
                        if (_.find(CompagnieVisual, (obj) => { return obj === parseInt(elm.IdCompagnia); })) {
                            let objPerito = {};
                            objPerito.NominativoPerito = elm.NominativoPerito;
                            let percChiusi = ((elm.NumSinistriChiusi / elm.NumSinistriEntrati) * 100) < 100 && ((elm.NumSinistriChiusi / elm.NumSinistriEntrati) * 100) > 0 
                                               && (((elm.NumSinistriChiusi / elm.NumSinistriEntrati) * 100) - Math.floor(((elm.NumSinistriChiusi / elm.NumSinistriEntrati) * 100))) !== 0
                                              ? ((elm.NumSinistriChiusi / elm.NumSinistriEntrati) * 100).toFixed(2)
                                              : ((elm.NumSinistriChiusi / elm.NumSinistriEntrati) * 100).toFixed(0);
                            objPerito[elm.IdCompagnia] = `${parseFloat(percChiusi).toLocaleString()}% (${elm.NumSinistriChiusi.toString()}/${elm.NumSinistriEntrati.toString()})`;
                            objPerito.TotSinistriChiusi = elm.NumSinistriChiusi;
                            objPerito.TotSinistriEntrati = elm.NumSinistriEntrati;
                            if (tipoStat === 3 && elm.Raggruppamento === 'RE') {
                                objPerito.TotSinistriChiusiRE = elm.NumSinistriChiusi;
                                objPerito.TotSinistriEntratiRE = elm.NumSinistriEntrati;
                                objPerito.TotSinistriChiusiRC = 0;
                                objPerito.TotSinistriEntratiRC = 0;
                                objPerito.GiorniMediRientroRE = elm.GiorniMediRientro;
                                objPerito.PagatoMedioRE = elm.PagatoMedio;
                                objPerito.GiorniMediRientroRC = 0;
                                objPerito.PagatoMedioRC = 0;
                            }
                            else if (tipoStat === 3 && elm.Raggruppamento === 'RC') {
                                objPerito.TotSinistriChiusiRC = elm.NumSinistriChiusi;
                                objPerito.TotSinistriEntratiRC = elm.NumSinistriEntrati;
                                objPerito.TotSinistriChiusiRE = 0;
                                objPerito.TotSinistriEntratiRE = 0;
                                objPerito.GiorniMediRientroRC = elm.GiorniMediRientro;
                                objPerito.PagatoMedioRC = elm.PagatoMedio;
                                objPerito.GiorniMediRientroRE = 0;
                                objPerito.PagatoMedioRE = 0;
                            }     
                            else if (tipoStat === 2) {
                                objPerito.Regione = _.find(RegioniProvince.regioni, elm2 => { return !isNullOrUndefined(_.find(elm2.province, elm3 => { return elm3 === elm.Raggruppamento })) })?.nome;
                            }  
                            else if (tipoStat === 4) {
                                let indOK = 0;
                                let objReg = _.find(RegioniProvince.regioni, elm2 => { return !isNullOrUndefined(_.find(elm2.province, (elm3,idx) => { indOK = elm3 === elm.Raggruppamento ? idx : 0;  return elm3 === elm.Raggruppamento; })) });
                                objPerito.Provincia = '';                                
                                objPerito.Provincia = objReg?.capoluoghi[indOK];
                            }                          
                            objPerito.Raggruppamento = elm.Raggruppamento;
                            objPerito.GiorniMediRientro = elm.GiorniMediRientro;
                            objPerito.PagatoMedio = elm.PagatoMedio;
                            globalChartData.push(objPerito);
                        }
                    }
                    else {
                        if (_.find(CompagnieVisual, (obj) => { return obj === parseInt(elm.IdCompagnia); })) {  
                            let percChiusi = ((elm.NumSinistriChiusi / elm.NumSinistriEntrati) * 100) < 100 && ((elm.NumSinistriChiusi / elm.NumSinistriEntrati) * 100) > 0 
                                               && (((elm.NumSinistriChiusi / elm.NumSinistriEntrati) * 100) - Math.floor(((elm.NumSinistriChiusi / elm.NumSinistriEntrati) * 100))) !== 0
                                              ? ((elm.NumSinistriChiusi / elm.NumSinistriEntrati) * 100).toFixed(2)
                                              : ((elm.NumSinistriChiusi / elm.NumSinistriEntrati) * 100).toFixed(0);                                                      
                            peritoSel[elm.IdCompagnia] = `${parseFloat(percChiusi).toLocaleString()}% (${elm.NumSinistriChiusi.toString()}/${elm.NumSinistriEntrati.toString()})`;
                            peritoSel.GiorniMediRientro = (peritoSel.GiorniMediRientro * peritoSel.TotSinistriChiusi) + (elm.GiorniMediRientro * elm.NumSinistriChiusi) / (peritoSel.TotSinistriChiusi + elm.NumSinistriChiusi);
                            peritoSel.PagatoMedio = (peritoSel.PagatoMedio * peritoSel.TotSinistriChiusi) + (elm.PagatoMedio * elm.NumSinistriChiusi) / (peritoSel.TotSinistriChiusi + elm.NumSinistriChiusi);
                            peritoSel.TotSinistriChiusi += elm.NumSinistriChiusi;
                            peritoSel.TotSinistriEntrati += elm.NumSinistriEntrati;
                            if (tipoStat === 3 && elm.Raggruppamento === 'RE') {
                                if (elm.NumSinistriChiusi > 0) {
                                    peritoSel.GiorniMediRientroRE = ((peritoSel.GiorniMediRientroRE * peritoSel.TotSinistriChiusiRE) + (elm.GiorniMediRientro * elm.NumSinistriChiusi)) / (peritoSel.TotSinistriChiusiRE + elm.NumSinistriChiusi);
                                    peritoSel.PagatoMedioRE = ((peritoSel.PagatoMedioRE * peritoSel.TotSinistriChiusiRE) + (elm.PagatoMedio * elm.NumSinistriChiusi)) / (peritoSel.TotSinistriChiusiRE + elm.NumSinistriChiusi);
                                }
                                peritoSel.TotSinistriChiusiRE += elm.NumSinistriChiusi;
                                peritoSel.TotSinistriEntratiRE += elm.NumSinistriEntrati;
                            }
                            else if (tipoStat === 3 && elm.Raggruppamento === 'RC') {
                                if (elm.NumSinistriChiusi > 0) {
                                    peritoSel.GiorniMediRientroRC = ((peritoSel.GiorniMediRientroRC * peritoSel.TotSinistriChiusiRC) + (elm.GiorniMediRientro * elm.NumSinistriChiusi)) / (peritoSel.TotSinistriChiusiRC + elm.NumSinistriChiusi);
                                    peritoSel.PagatoMedioRC = ((peritoSel.PagatoMedioRC * peritoSel.TotSinistriChiusiRC) + (elm.PagatoMedio * elm.NumSinistriChiusi)) / (peritoSel.TotSinistriChiusiRC + elm.NumSinistriChiusi);
                                }
                                peritoSel.TotSinistriChiusiRC += elm.NumSinistriChiusi;
                                peritoSel.TotSinistriEntratiRC += elm.NumSinistriEntrati;
                            }    
                            else if (tipoStat === 2) {
                                peritoSel.Regione = _.find(RegioniProvince.regioni, elm2 => { return !isNullOrUndefined(_.find(elm2.province, elm3 => { return elm3 === elm.Raggruppamento })) })?.nome;
                            }   
                            else if (tipoStat === 4) {
                                let indOK = 0;
                                let objReg = _.find(RegioniProvince.regioni, elm2 => { return !isNullOrUndefined(_.find(elm2.province, elm3 => { return elm3 === elm.Raggruppamento })) });
                                peritoSel.Provincia = '';
                                if (!CustomJS.isNullEmpty(objReg)) {
                                    objReg.province.forEach((elm4, idx) => {
                                        if (elm4 === elm.Raggruppamento)
                                            indOK = idx;
                                    });
                                    peritoSel.Provincia = objReg?.capoluoghi[indOK];
                                }
                            }               
                        }
                    }
                }                       
            });  
            if (globalChartData.length === 0) {                
                toast.warn('Attenzione!! Nessun dato estratto con i parametri selezionati!!', {
                    onClose: () => toast.dismiss(),
                    position: "top-center",
                    autoClose: 3000,
                    hideProgressBar: false,            
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: false,
                    progress: undefined,
                    newestOnTop: false,
                    rtl: false,
                    pauseOnFocusLoss: true
                });     
                thisClone.toggleBlocking();
                return;
            }
            globalChartData.forEach((elm, idx) => {   
                let percChiusi = ((elm.TotSinistriChiusi / elm.TotSinistriEntrati) * 100) < 100 && ((elm.TotSinistriChiusi / elm.TotSinistriEntrati) * 100) > 0 
                                               && (((elm.TotSinistriChiusi / elm.TotSinistriEntrati) * 100) - Math.floor(((elm.TotSinistriChiusi / elm.TotSinistriEntrati) * 100))) !== 0
                                              ? ((elm.TotSinistriChiusi / elm.TotSinistriEntrati) * 100).toFixed(2)
                                              : ((elm.TotSinistriChiusi / elm.TotSinistriEntrati) * 100).toFixed(0);  
                elm.PercentChiusi = parseFloat(percChiusi);           
                elm.ScaricoTotale = `${parseFloat(percChiusi).toLocaleString()}% (${elm.TotSinistriChiusi.toString()}/${elm.TotSinistriEntrati.toString()})`;
            });
            globalChartData = _.filter(globalChartData, elm => { return elm.NominativoPerito !== 'TOTALI' && !CustomJS.isNullEmpty(elm.Raggruppamento) && 
                              (elm.TotSinistriEntrati > 0 || elm.TotSinistriChiusi > 0)});            
            if (tipoStat === 3)
                globalChartData = _.sortBy(globalChartData, 'PercentChiusi').reverse(); 
            else
                globalChartData = _.sortBy(globalChartData, 'TotSinistriEntrati').reverse();
            document.getElementById('accordionStatistiche').ej2_instances[0].enableItem(1, true);                  
            document.getElementById('accordionStatistiche').ej2_instances[0].expandItem(false, 0);
            for (let ind=0;ind<=200000000;ind++) {};
            document.getElementById('accordionStatistiche').ej2_instances[0].expandItem(true, 1);
            setTimeout(() => {
                if (tipoStat === 1) {
                    let data1 = [], data2 = [], ddlAree = [];                    
                    globalChartData = _.groupBy(globalChartData, 'Raggruppamento'); 
                    Object.values(globalChartData).reverse().forEach((elm, idx) => {
                        data1.push({ x: Object.keys(globalChartData).reverse()[idx], y: elm.sum('TotSinistriEntrati') }); 
                        data2.push({ x: Object.keys(globalChartData).reverse()[idx], y: elm.sum('TotSinistriChiusi'), 
                                     GiorniMediRientro: elm.mediaPonderataMedie('GiorniMediRientro','TotSinistriChiusi'),
                                     PagatoMedio: elm.mediaPonderataMedie('PagatoMedio','TotSinistriChiusi')});  
                        data1.push({ x: Object.keys(globalChartData).reverse()[idx], y: elm.sum('TotSinistriChiusi') });                    
                    });    
                    window.localStorage.setItem('ChartData1Both', JSON.stringify(data1));                         
                    //document.getElementById("contenutoDinamicoStatistica").innerHTML = "";
                    ReactDOM.render(<ChartSuddivisioneArea />, document.getElementById('contenutoDinamicoStatistica'), () => {
                        document.getElementById('ChartSuddArea').ej2_instances[0].series[0].dataSource = data1;
                    });
                    data1 = [];
                    Object.values(globalChartData).reverse().forEach((elm, idx) => {
                        data1.push({ x: Object.keys(globalChartData).reverse()[idx], y: elm.sum('TotSinistriEntrati') });                                           
                    }); 
                    window.localStorage.setItem('ChartData1', JSON.stringify(data1));
                    window.localStorage.setItem('ChartData2', JSON.stringify(data2));                     
                    document.getElementById('ChartSuddArea').ej2_instances[0].height = data1.length === 1 ? (data1.length * 50).toString() + '%' : (data1.length * 30).toString() + '%';                       
                    document.getElementById('elencoCompagnieChartArea').innerHTML = `Compagnia/e: ${document.getElementById('compagnieSelezionate').ej2_instances[0].text.replace(/,/g, ', ')}`;  
                    let percChiusi = ((data2.sum('y') / data1.sum('y')) * 100) < 100 && ((data2.sum('y') / data1.sum('y')) * 100) > 0 
                                               && (((data2.sum('y') / data1.sum('y')) * 100) - Math.floor(((data2.sum('y') / data1.sum('y')) * 100))) !== 0
                                              ? ((data2.sum('y') / data1.sum('y')) * 100).toFixed(2)
                                              : ((data2.sum('y') / data1.sum('y')) * 100).toFixed(0);
                    document.getElementById('TotaleEntratiArea').innerHTML = `Totale Entrati: ${data1.sum('y').toLocaleString()}`;
                    document.getElementById('TotaleChiusiArea').innerHTML = `Totale Chiusi: ${data2.sum('y').toLocaleString()}`;
                    document.getElementById('ScaricoTotaleArea').innerHTML = `Scarico Totale: ${parseFloat(percChiusi).toLocaleString()}%`;
                    document.getElementById('MedieTotaliArea').innerHTML = `Giorni Medi Rientro: ${data2.mediaPonderataMedie('GiorniMediRientro','y').toLocaleString()}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Pagato Medio: ${data2.mediaPonderataMedie('PagatoMedio','y').toLocaleString()}`;             
                    Object.keys(globalChartData).reverse().forEach(elm => {
                        let area = { DescrizioneArea: elm };
                        ddlAree.push(area);
                    });
                    document.getElementById('filtroAreeVisual').ej2_instances[0].dataSource = ddlAree;
                    document.getElementById('filtroAreeVisual').ej2_instances[0].value = null;                    
                    thisClone.toggleBlocking();
                }   
                else if (tipoStat === 2) {
                    let data1 = [], data2 = [], ddlProvReg = [];
                    globalChartData = _.groupBy(globalChartData, 'Regione');                    
                    Object.values(globalChartData).reverse().forEach((elm, idx) => {
                        data1.push({ x: Object.keys(globalChartData).reverse()[idx], y: elm.sum('TotSinistriEntrati') }); 
                        data2.push({ x: Object.keys(globalChartData).reverse()[idx], y: elm.sum('TotSinistriChiusi'), 
                                     GiorniMediRientro: elm.mediaPonderataMedie('GiorniMediRientro','TotSinistriChiusi'),
                                     PagatoMedio: elm.mediaPonderataMedie('PagatoMedio','TotSinistriChiusi') }); 
                        data1.push({ x: Object.keys(globalChartData).reverse()[idx], y: elm.sum('TotSinistriChiusi') });                    
                    }); 
                    window.localStorage.setItem('ChartData1Both', JSON.stringify(data1));
                    //document.getElementById("contenutoDinamicoStatistica").innerHTML = "";
                    ReactDOM.render(<ChartSuddivisioneProvReg />, document.getElementById('contenutoDinamicoStatistica'), () => {
                        document.getElementById('ChartSuddProvReg').ej2_instances[0].series[0].dataSource = data1;
                    });                     
                    data1 = [];
                    Object.values(globalChartData).reverse().forEach((elm, idx) => {
                        data1.push({ x: Object.keys(globalChartData).reverse()[idx], y: elm.sum('TotSinistriEntrati') });                                           
                    }); 
                    window.localStorage.setItem('ChartData1', JSON.stringify(data1));
                    window.localStorage.setItem('ChartData2', JSON.stringify(data2)); 
                    document.getElementById('ChartSuddProvReg').ej2_instances[0].height = data1.length === 1 ? (data1.length * 50).toString() + '%' : (data1.length * 30).toString() + '%'; 
                    $("#divChartPerito").hide();
                    $("#divChartArea").hide();  
                    $("#divChartProvReg").show();   
                    document.getElementById('titoloGraficoProvReg').innerHTML = "SCARICO PER REGIONE";
                    document.getElementById('elencoCompagnieChartProvReg').innerHTML = `Compagnia/e: ${document.getElementById('compagnieSelezionate').ej2_instances[0].text.replace(/,/g, ', ')}`;               
                    let percChiusi = ((data2.sum('y') / data1.sum('y')) * 100) < 100 && ((data2.sum('y') / data1.sum('y')) * 100) > 0 
                                               && (((data2.sum('y') / data1.sum('y')) * 100) - Math.floor(((data2.sum('y') / data1.sum('y')) * 100))) !== 0
                                              ? ((data2.sum('y') / data1.sum('y')) * 100).toFixed(2)
                                              : ((data2.sum('y') / data1.sum('y')) * 100).toFixed(0);
                    document.getElementById('TotaleEntratiProvReg').innerHTML = `Totale Entrati: ${data1.sum('y').toLocaleString()}`;
                    document.getElementById('TotaleChiusiProvReg').innerHTML = `Totale Chiusi: ${data2.sum('y').toLocaleString()}`;
                    document.getElementById('ScaricoTotaleProvReg').innerHTML = `Scarico Totale: ${parseFloat(percChiusi).toLocaleString()}%`;
                    document.getElementById('MedieTotaliProvReg').innerHTML = `Giorni Medi Rientro: ${data2.mediaPonderataMedie('GiorniMediRientro','y').toLocaleString()}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Pagato Medio: ${data2.mediaPonderataMedie('PagatoMedio','y').toLocaleString()}`;             
                    Object.keys(globalChartData).reverse().forEach(elm => {
                        let provreg = { DescrizioneProvReg: elm };
                        ddlProvReg.push(provreg);
                    });
                    document.getElementById('filtroProvRegVisual').ej2_instances[0].dataSource = ddlProvReg;
                    document.getElementById('filtroProvRegVisual').ej2_instances[0].value = null;
                    thisClone.toggleBlocking();
                }   
                else if (tipoStat === 3) {                
                    let data1 = [], data2 = [], data3 = [], data4 = [], ddlPerito = [];
                    globalChartData.forEach((elm, idx) => {
                        data1.push({ x: elm.NominativoPerito, y: elm.TotSinistriEntratiRE }); 
                        data2.push({ x: elm.NominativoPerito, y: elm.TotSinistriChiusiRE, GiorniMediRientroRE: elm.GiorniMediRientroRE, PagatoMedioRE: elm.PagatoMedioRE }); 
                        data1.push({ x: elm.NominativoPerito, y: elm.TotSinistriChiusiRE });                     
                    });  
                    globalChartData.forEach((elm, idx) => {
                        data3.push({ x: elm.NominativoPerito, y: elm.TotSinistriEntratiRC }); 
                        data4.push({ x: elm.NominativoPerito, y: elm.TotSinistriChiusiRC, GiorniMediRientroRC: elm.GiorniMediRientroRC, PagatoMedioRC: elm.PagatoMedioRC });   
                        data3.push({ x: elm.NominativoPerito, y: elm.TotSinistriChiusiRC });                   
                    });     
                    window.localStorage.setItem('ChartData1Both', JSON.stringify(data1));
                    window.localStorage.setItem('ChartData3Both', JSON.stringify(data3));  
                    //document.getElementById("contenutoDinamicoStatistica").innerHTML = "";
                    ReactDOM.render(<ChartSuddivisionePerito />, document.getElementById('contenutoDinamicoStatistica'), () => {
                        document.getElementById('ChartSuddPerito').ej2_instances[0].series[0].dataSource = data1;
                        document.getElementById('ChartSuddPerito').ej2_instances[0].series[1].dataSource = data3;
                    });  
                    data1 = [];
                    data3 = [];
                    Object.values(globalChartData).forEach((elm, idx) => {
                        data1.push({ x: elm.NominativoPerito, y: elm.TotSinistriEntratiRE });  
                        data3.push({ x: elm.NominativoPerito, y: elm.TotSinistriEntratiRC });                                        
                    }); 
                    window.localStorage.setItem('ChartData1', JSON.stringify(data1));
                    window.localStorage.setItem('ChartData2', JSON.stringify(data2));  
                    window.localStorage.setItem('ChartData3', JSON.stringify(data3));
                    window.localStorage.setItem('ChartData4', JSON.stringify(data4));                    
                    document.getElementById('ChartSuddPerito').ej2_instances[0].height = (data1.length * 50).toString() + '%';
                    $("#divChartPerito").show();
                    $("#divChartArea").hide();  
                    $("#divChartProvReg").hide();  
                    document.getElementById('elencoCompagnieChartPerito').innerHTML = `Compagnia/e: ${document.getElementById('compagnieSelezionate').ej2_instances[0].text.replace(/,/g, ', ')}`;                
                    let percChiusi = (((data2.sum('y') + data4.sum('y')) / (data1.sum('y') + data3.sum('y'))) * 100) < 100 && (((data2.sum('y') + data4.sum('y')) / (data1.sum('y') + data3.sum('y'))) * 100) > 0 
                                               && ((((data2.sum('y') + data4.sum('y')) / (data1.sum('y') + data3.sum('y'))) * 100) - Math.floor((((data2.sum('y') + data4.sum('y')) / (data1.sum('y') + data3.sum('y'))) * 100))) !== 0
                                              ? (((data2.sum('y') + data4.sum('y')) / (data1.sum('y') + data3.sum('y'))) * 100).toFixed(2)
                                              : (((data2.sum('y') + data4.sum('y')) / (data1.sum('y') + data3.sum('y'))) * 100).toFixed(0);
                    document.getElementById('TotaleEntratiPerito').innerHTML = `Totale Entrati: ${(data1.sum('y') + data3.sum('y')).toLocaleString()}`;
                    document.getElementById('TotaleChiusiPerito').innerHTML = `Totale Chiusi: ${(data2.sum('y') + data4.sum('y')).toLocaleString()}`;
                    document.getElementById('ScaricoTotalePerito').innerHTML = `Scarico Totale: ${parseFloat(percChiusi).toLocaleString()}%`;             
                    document.getElementById('TotaleEntratiRE').innerHTML = `Totale Entrati RE: ${data1.sum('y').toLocaleString()}`;
                    document.getElementById('TotaleChiusiRE').innerHTML = `Totale Chiusi RE: ${data2.sum('y').toLocaleString()}`;
                    let percChiusiRE = ((data2.sum('y') / data1.sum('y')) * 100) < 100 && ((data2.sum('y') / data1.sum('y')) * 100) > 0 
                                               && (((data2.sum('y') / data1.sum('y')) * 100) - Math.floor(((data2.sum('y') / data1.sum('y')) * 100))) !== 0
                                              ? ((data2.sum('y') / data1.sum('y')) * 100).toFixed(2)
                                              : ((data2.sum('y') / data1.sum('y')) * 100).toFixed(0);
                    document.getElementById('ScaricoTotaleRE').innerHTML = `Scarico Totale RE: ${parseFloat(percChiusiRE).toLocaleString()}%`;
                    document.getElementById('TotaleEntratiRC').innerHTML = `Totale Entrati RC: ${data3.sum('y').toLocaleString()}`;
                    document.getElementById('TotaleChiusiRC').innerHTML = `Totale Chiusi RC: ${data4.sum('y').toLocaleString()}`;
                    let percChiusiRC = ((data4.sum('y') / data3.sum('y')) * 100) < 100 && ((data4.sum('y') / data3.sum('y')) * 100) > 0 
                                               && (((data4.sum('y') / data3.sum('y')) * 100) - Math.floor(((data4.sum('y') / data3.sum('y')) * 100))) !== 0
                                              ? ((data4.sum('y') / data3.sum('y')) * 100).toFixed(2)
                                              : ((data4.sum('y') / data3.sum('y')) * 100).toFixed(0);
                    document.getElementById('ScaricoTotaleRC').innerHTML = `Scarico Totale RC: ${parseFloat(percChiusiRC).toLocaleString()}%`;
                    document.getElementById('MedieTotaliPeritoRE').innerHTML = `Giorni Medi Rientro RE: ${data2.mediaPonderataMedie('GiorniMediRientroRE','y').toLocaleString('it-IT')}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Pagato Medio RE: ${data2.mediaPonderataMedie('PagatoMedioRE','y').toLocaleString()}`;             
                    document.getElementById('MedieTotaliPeritoRC').innerHTML = `Giorni Medi Rientro RC: ${data4.mediaPonderataMedie('GiorniMediRientroRC','y').toLocaleString('it-IT')}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Pagato Medio RC: ${data4.mediaPonderataMedie('PagatoMedioRC','y').toLocaleString()}`;             
                    let raggrPerito = _.groupBy(globalChartData, 'NominativoPerito');
                    Object.keys(raggrPerito).forEach(elm => {
                        let perito = { DescrizionePerito: elm };
                        ddlPerito.push(perito);
                    });
                    document.getElementById('filtroPeritoVisual').ej2_instances[0].dataSource = ddlPerito;
                    document.getElementById('filtroPeritoVisual').ej2_instances[0].value = null;
                    thisClone.toggleBlocking();
                } 
                else if (tipoStat === 4) {
                    let data1 = [], data2 = [], ddlProvReg = [];
                    globalChartData = _.groupBy(globalChartData, 'Provincia');                                       
                    Object.values(globalChartData).reverse().forEach((elm, idx) => {
                        data1.push({ x: Object.keys(globalChartData).reverse()[idx], y: elm.sum('TotSinistriEntrati') }); 
                        data2.push({ x: Object.keys(globalChartData).reverse()[idx], y: elm.sum('TotSinistriChiusi'),
                                     GiorniMediRientro: elm.mediaPonderataMedie('GiorniMediRientro','TotSinistriChiusi'),
                                     PagatoMedio: elm.mediaPonderataMedie('PagatoMedio','TotSinistriChiusi') });  
                        data1.push({ x: Object.keys(globalChartData).reverse()[idx], y: elm.sum('TotSinistriChiusi') });                    
                    }); 
                    window.localStorage.setItem('ChartData1Both', JSON.stringify(data1));
                    //document.getElementById("contenutoDinamicoStatistica").innerHTML = "";
                    ReactDOM.render(<ChartSuddivisioneProvReg />, document.getElementById('contenutoDinamicoStatistica'),() => {
                        document.getElementById('ChartSuddProvReg').ej2_instances[0].series[0].dataSource = data1;
                    });                     
                    data1 = [];
                    Object.values(globalChartData).reverse().forEach((elm, idx) => {
                        data1.push({ x: Object.keys(globalChartData).reverse()[idx], y: elm.sum('TotSinistriEntrati') });                                           
                    }); 
                    window.localStorage.setItem('ChartData1', JSON.stringify(data1));
                    window.localStorage.setItem('ChartData2', JSON.stringify(data2)); 
                    document.getElementById('ChartSuddProvReg').ej2_instances[0].height = data1.length === 1 ? (data1.length * 50).toString() + '%' : (data1.length * 30).toString() + '%'; 
                    $("#divChartPerito").hide();
                    $("#divChartArea").hide();  
                    $("#divChartProvReg").show();   
                    document.getElementById('titoloGraficoProvReg').innerHTML = "SCARICO PER PROVINCIA";
                    document.getElementById('elencoCompagnieChartProvReg').innerHTML = `Compagnia/e: ${document.getElementById('compagnieSelezionate').ej2_instances[0].text.replace(/,/g, ', ')}`;               
                    let percChiusi = ((data2.sum('y') / data1.sum('y')) * 100) < 100 && ((data2.sum('y') / data1.sum('y')) * 100) > 0 
                                               && (((data2.sum('y') / data1.sum('y')) * 100) - Math.floor(((data2.sum('y') / data1.sum('y')) * 100))) !== 0
                                              ? ((data2.sum('y') / data1.sum('y')) * 100).toFixed(2)
                                              : ((data2.sum('y') / data1.sum('y')) * 100).toFixed(0);
                    document.getElementById('TotaleEntratiProvReg').innerHTML = `Totale Entrati: ${data1.sum('y').toLocaleString()}`;
                    document.getElementById('TotaleChiusiProvReg').innerHTML = `Totale Chiusi: ${data2.sum('y').toLocaleString()}`;
                    document.getElementById('ScaricoTotaleProvReg').innerHTML = `Scarico Totale: ${parseFloat(percChiusi).toLocaleString()}%`;
                    document.getElementById('MedieTotaliProvReg').innerHTML = `Giorni Medi Rientro: ${data2.mediaPonderataMedie('GiorniMediRientro','y').toLocaleString()}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Pagato Medio: ${data2.mediaPonderataMedie('PagatoMedio','y').toLocaleString()}`;             
                    Object.keys(globalChartData).reverse().forEach(elm => {
                        let provreg = { DescrizioneProvReg: elm };
                        ddlProvReg.push(provreg);
                    });
                    document.getElementById('filtroProvRegVisual').ej2_instances[0].dataSource = ddlProvReg;
                    document.getElementById('filtroProvRegVisual').ej2_instances[0].value = null;
                    thisClone.toggleBlocking();
                }   
            }, 300);      
    }, true, false);    
}

function scrollAuto(speed, offset) {
    $('html, body').animate({ scrollTop: $(document).height() - $(window).height() - offset }, speed, function() {
        //$(this).animate({ scrollTop: 0 }, speed);
    });
} 