import React from 'react';
import { ChartComponent, SeriesCollectionDirective, SeriesDirective, Inject, Category, ColumnSeries, DataLabel, Tooltip, BarSeries, Legend } from '@syncfusion/ej2-react-charts';
import { Browser } from '@syncfusion/ej2-base';
import { MultiSelectComponent } from '@syncfusion/ej2-react-dropdowns';
import { Query } from '@syncfusion/ej2-data';
import { fabricColors, bootstrapColors, materialColors, highContrastColors } from './theme-color';
import { SampleBase } from './sample-base';
import * as CustomJS from '../Custom';
import { random } from 'underscore';
import _ from 'underscore/underscore-min';
import $ from 'jquery';

let data1 = [], data2 = [], filtroAreeVisual = [], applicaFiltro = false;

let labelRender = (args) => {
    let themes = ['fabric', 'material', 'highcontrast', 'bootstrap'];
    let selectedTheme = themes[random(3)];
    if (selectedTheme && selectedTheme.indexOf('fabric') > -1) {
        args.fill = fabricColors[args.point.index % 10];
    }
    else if (selectedTheme === 'material') {
        args.fill = materialColors[args.point.index % 10];
    }
    else if (selectedTheme === 'highcontrast') {
        args.fill = highContrastColors[args.point.index % 10];
    }
    else {
        args.fill = bootstrapColors[args.point.index % 10];
    }
};

export default class ChartSuddivisioneArea extends SampleBase {
    constructor() {
        super(...arguments);
        this.fieldsAree = { text: 'DescrizioneArea', value: 'DescrizioneArea' };
    }
    tooltipTemplate(props) {       
        debugger;
        let NumSinistriEntrati = _.find(JSON.parse(window.localStorage.getItem('ChartData1')), elm => {return elm.x === props.x})?.y;
        let NumSinistriChiusi = _.find(JSON.parse(window.localStorage.getItem('ChartData2')), elm => {return elm.x === props.x})?.y;
        let GiorniMediRientro = _.find(JSON.parse(window.localStorage.getItem('ChartData2')), elm => {return elm.x === props.x})?.GiorniMediRientro;
        let PagatoMedio = _.find(JSON.parse(window.localStorage.getItem('ChartData2')), elm => {return elm.x === props.x})?.PagatoMedio;
        let percChiusi = NumSinistriEntrati === 0 ? 0 : ((NumSinistriChiusi / NumSinistriEntrati) * 100) < 100 && ((NumSinistriChiusi / NumSinistriEntrati) * 100) > 0 
                                               && (((NumSinistriChiusi / NumSinistriEntrati) * 100) - Math.floor(((NumSinistriChiusi / NumSinistriEntrati) * 100))) !== 0
                                              ? ((NumSinistriChiusi / NumSinistriEntrati) * 100).toFixed(2)
                                              : ((NumSinistriChiusi / NumSinistriEntrati) * 100).toFixed(0); 
        return (<div id="tooltipContainer" className="tooltip-container">                
                <div className="row">
                     <div className="col-lg-12">
                         <span style={{ fontSize: '1.1vw', color: '#133B95', fontWeight: 'bold' }}>{props.x}</span>
                     </div>
                </div> 
                <div className="row">
                     <div className="col-lg-6">
                        <table style={{ width: '100%', border: '1px solid lightgrey' }}>
                            <tbody>                                                                         
                                <tr style={{ border: 'none' }}>
                                    <td style={{ fontSize: '0.9vw', color: materialColors[5 % 10], fontWeight: 'bold' }}>Sinistri Entrati: {NumSinistriEntrati?.toLocaleString('it-IT')}</td>
                                </tr>
                                <tr>
                                    <td style={{ fontSize: '0.9vw', color: materialColors[4 % 10], fontWeight: 'bold' }}>Sinistri Chiusi: {NumSinistriChiusi?.toLocaleString('it-IT')}</td>
                                </tr>
                                <tr>
                                    <td style={{ fontSize: '0.9vw', color: 'purple', fontWeight: 'bold' }}>Scarico: {parseFloat(percChiusi)?.toLocaleString('it-IT')}%</td>
                                </tr>                                
                            </tbody>
                        </table>
                     </div>
                     <div className="col-lg-6">
                        <table style={{ width: '100%', border: '1px solid lightgrey' }}>
                            <tbody>  
                                <tr style={{ border: 'none' }}>
                                    <td style={{ fontSize: '0.9vw', color: 'darkslategrey', fontWeight: 'bold', padding: '5px' }}>Giorni Medi Rientro: {GiorniMediRientro?.toLocaleString('it-IT')}</td>
                                </tr>
                                <tr>
                                    <td style={{ fontSize: '0.9vw', color: 'darkslategrey', fontWeight: 'bold', padding: '5px' }}>Pagato Medio: {PagatoMedio?.toLocaleString('it-IT')}</td>
                                </tr> 
                            </tbody>
                        </table>
                     </div>
                </div> 
            </div>);
    }
    onTagging(e) {
        e.setClass(CustomJS.colorsData[random(26)].Color.toLowerCase());
    }      
    onSelect(e) {
        filtroAreeVisual.push(e.itemData.DescrizioneArea);  
        this.applicaFiltroAree();      
    }
    onRemove(e) {
        filtroAreeVisual = filtroAreeVisual.filter(elm => { return elm !== e.itemData.DescrizioneArea}); 
        this.applicaFiltroAree();
    }
    onFiltering(e) {   
        applicaFiltro = true;     
        let query = new Query();
        query = (e.text !== "") ? query.where("DescrizioneArea", "contains", e.text, true) : query;     
        e.updateData(this.dataSource, query);
    }
    onDataBound(e) {
        if (!applicaFiltro)
            filtroAreeVisual = [];
        applicaFiltro = false;
    }

    render() {
        return (<div id='divChartArea'>  
                    <div className='control-section card-control-section basic_card_layout'>                    
                        <div className="e-card-resize-container">                            
                            <div className="card-layout" >
                                <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12">
                                    <div className="e-card" id="basic_card" >  
                                        <div className="e-card-content">
                                            <div className="row" style={{ marginTop: '10px' }}>      
                                                <div className="col-xs-2 col-sm-4 col-lg-6 col-md-5">
                                                    <span style={{ fontSize: '2.0vw', fontWeight: 'bold', color:'#133B95'}}>SCARICO PER AREA</span>
                                                </div>
                                                <div className="col-xs-10 col-sm-8 col-lg-6 col-md-7 text-right">
                                                    <span style={{ fontSize: '1.2vw', fontWeight: 'bold', color:'#133B95'}}>(posizionare il cursore sulle barre del grafico per vedere le info di dettaglio)</span>
                                                </div>                                       
                                            </div>    
                                            <div className="row">
                                                <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12">   
                                                    <span id='elencoCompagnieChartArea' style={{ fontSize: '1.3vw', fontWeight: 'bold', color:'#133B95'}}></span>
                                                </div> 
                                            </div>
                                            <div className="row">&nbsp;</div>
                                            <div className="row">
                                                <div className="col-xs-3 col-sm-8 col-md-5 col-lg-5" style={{ paddingTop: '10px', paddingBottom: '10px' }}>
                                                    <MultiSelectComponent id="filtroAreeVisual" mode="Box" fields={this.fieldsAree} cssClass='e-outline' ref={ddlAree => this.ddlAreeInstance = ddlAree} 
                                                                placeholder="Filtro Aree da visualizzare" floatLabelType='always' tagging={this.onTagging.bind(this)} select={this.onSelect.bind(this)}
                                                                allowFiltering={true} filtering={this.onFiltering} removed={this.onRemove.bind(this)} dataBound={this.onDataBound.bind(this)} />
                                                </div>
                                                <div className="col-xs-9 col-sm-8 col-lg-7 col-md-7 text-right">
                                                    <table style={{ width: '100%', border: '1px solid purple' }}>
                                                        <tbody>   
                                                            <tr>
                                                                <td align='center' style={{ width: '33%' }}>
                                                                    <span id='TotaleEntratiArea' style={{ fontSize: '1.5vw', fontWeight: 'bold', color:materialColors[5%10] }}></span>
                                                                </td>
                                                                <td align='center' style={{ width: '33%' }}>
                                                                    <span id='TotaleChiusiArea' style={{ fontSize: '1.5vw', fontWeight: 'bold', color:materialColors[4%10] }}></span>
                                                                </td>
                                                                <td align='center' style={{ width: '33%' }}>
                                                                    <span id='ScaricoTotaleArea' style={{ fontSize: '1.5vw', fontWeight: 'bold', color: 'purple' }}></span>
                                                                </td>
                                                            </tr>   
                                                            <tr>
                                                                <td align='center' colSpan='3'>
                                                                    <span id='MedieTotaliArea' style={{ fontSize: '1.5vw', fontWeight: 'bold', color:'darkslategrey'}}></span>
                                                                </td>
                                                            </tr>              
                                                        </tbody>
                                                    </table>                                                    
                                                </div> 
                                            </div>
                                            <div className="row">&nbsp;</div>
                                            <div className="row">          
                                                <ChartComponent id='ChartSuddArea' loaded={this.operazioniDopoCarica.bind(this)} style={{ textAlign: "center" }}
                                                    className='col-xs-12 col-sm-12 col-lg-12 col-md-12' ref={chart => {this.chartAreaInstance = chart;}} 
                                                    primaryXAxis={{ valueType: 'Category', interval: 1, majorGridLines: { width: 0 }, labelStyle: { color: '#133B95', fontSize: '1.8vw', fontWeight: 'bolder' } }} 
                                                    primaryYAxis={{ majorGridLines: { width: 0 }, majorTickLines: { width: 0 }, lineStyle: { width: 0 }, labelStyle: { color: 'transparent' } }}
                                                    chartArea={{ border: { width: 0 } }} tooltip={{ enable: true, template: this.tooltipTemplate.bind(this) }} 
                                                    width={CustomJS.chartWidth}
                                                    height='100%' title=''
                                                    legendSettings={{ visible: false, position: 'Top' }}>
                                                    <Inject services={[ColumnSeries, Legend, Tooltip, Category, DataLabel, BarSeries]}/>
                                                    <SeriesCollectionDirective>
                                                        <SeriesDirective dataSource={data1} xName='x' yName='y' name='Sinistri Entrati' type='Bar' fill={materialColors[5 % 10]} cornerRadius={{ bottomLeft: 5, bottomRight: 5, topLeft: 5, topRight: 5 }} marker={{ dataLabel: { visible: true, position: 'Top', font: { fontWeight: '600', color: '#ffffff' } } }}>
                                                        </SeriesDirective>
                                                    </SeriesCollectionDirective>
                                                </ChartComponent>      
                                            </div>  
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>                             
            </div>)
    }
    applicaFiltroAree() {
        this.chartAreaInstance.series[0].dataSource = filtroAreeVisual.length === 0 ? JSON.parse(window.localStorage.getItem('ChartData1Both')) : JSON.parse(window.localStorage.getItem('ChartData1Both')).filter(function(e) { return this.indexOf(e.x) > -1; }, filtroAreeVisual);
        this.chartAreaInstance.height = filtroAreeVisual.length === 0 ? (JSON.parse(window.localStorage.getItem('ChartData1')).length * 30).toString() + '%' : (JSON.parse(window.localStorage.getItem('ChartData1')).filter(function(e) { return this.indexOf(e.x) > -1; }, filtroAreeVisual).length * 50).toString() + '%';
    }
    operazioniDopoCarica(args) {                 
        setTimeout(() => {              
            for (let ind=1;ind<=this.chartAreaInstance.series[0].dataSource.length-1;ind+=2)
                 $(`#ChartSuddArea_Series_0_Point_${(ind).toString()}`).attr('fill', materialColors[4 % 10]);
        }, 500);  
    }
}