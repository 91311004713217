import * as CustomJS from './Custom';

String.prototype.format2 = function() {
  let a = this;
  for (let k in arguments) {
    a = a.replace("{" + k + "}", arguments[k])
  }
  return a
}

/* src/api/fetchFakeData.js */
/**
 * A complete Coordinate Pair consisting of a latitude and longitude
 * @typedef {Object} CoordinatePair
 * @property {number} longitude - longitude coordinate
 * @property {number} latitude - latitude coordinate
 */

/**
 * Generates a GeoJSON FeatureCollection of random points based on
 * the center coordinates passed in.
 * @param {CoordinatePair} centerCoordinates - the {@link CoordinatePair} for the map center
 * @return {results} GeoJSON FeatureCollection
 */
export const fetchCoordinatesData = centerCoordinates => {
    const newFeaturesList = [];
    for (let i = 0; i < 5; i++) {
      const id = i;      
      const { longitude, latitude } = getRandomCoordinate(centerCoordinates);
      newFeaturesList.push({
        type: 'Feature',
        geometry: {
          type: 'Point',
          coordinates: [longitude, latitude],
        },
        properties: {
          id,
          name: `Random Point #${id}`,
          description: `Description for Random Point #${id}`,
        },
      });
    }  
    return Promise.resolve({
      type: 'FeatureCollection',
      features: newFeaturesList,
    });
};

export const fetchCoordinatesData2 = arrayIndirizzi => {
    let newFeaturesList = [], idx = 0;
    //Promise.all(
      arrayIndirizzi.map((elm) => {
        const id = idx++;                  
        getRealCoordinate(elm, (longitude, latitude) => {  
          console.log(longitude);
          console.log(latitude);
          newFeaturesList.push({
            type: 'Feature',
            geometry: {
              type: 'Point',
              coordinates: [longitude, latitude],
            },
            properties: {
              id,
              name: `Random Point #${id}`,
              description: `Description for Random Point #${id}`,
            },
          });
        });
      })
    //)   
    return ({
      type: 'FeatureCollection',
      features: newFeaturesList,
    });
};
  
/**
 * Generates a random point within 0.025 radius of map center coordinates.
 * @param {CoordinatePair} centerCoordinates - the {@link CoordinatePair} for the map center
 * @return {CoordinatePair} randomly generated coordinate pair
 */
const getRandomCoordinate = ({ longitude: centerLon, latitude: centerLat }) => {
  const r = 0.025 * Math.sqrt(Math.random());
  const theta = Math.random() * 2 * Math.PI;
  const latitude = centerLat + r * Math.cos(theta);
  const longitude = centerLon + r * Math.sin(theta);
  return { longitude, latitude };
};

const getRealCoordinate = (indirizzo, cbFunc) => {
  let longitude, latitude;  
  CustomJS.getRemoteData2(CustomJS.APIUrlBingMaps.format2(indirizzo),'', 
    'GET', "", retData => {
      debugger;
      let geoObj = JSON.parse(retData);
      latitude = geoObj.resourceSets[0].resources[0].point.coordinates[0];
      longitude = geoObj.resourceSets[0].resources[0].point.coordinates[1]; 
      cbFunc(longitude, latitude);       
  }, false, true);  
}  