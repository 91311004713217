import * as React from 'react';
import ReactDOM from 'react-dom';
import { GridComponent, ColumnsDirective, ColumnDirective, ExcelExport, PdfExport,Toolbar, DetailRow, Page, Inject, Sort } from '@syncfusion/ej2-react-grids';
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import { SampleBase } from './sample-base';
import * as CustomJS from '../Custom';
import _ from 'underscore/underscore-min';

var dataCorrente = new Date();
var dd = String(dataCorrente.getDate()).padStart(2, '0');
let mm = String(dataCorrente.getMonth() + 1).padStart(2, '0'); //January is 0!
var yyyy = dataCorrente.getFullYear();
dataCorrente = yyyy + mm + dd;

function headerTemplate(props) {    
    return (<div>
         <span style={{ fontSize: '1.0vw', fontWeight: 'bolder'}}>{props.headerText}</span>
    </div>);
}
export default class GridSecondoPeritoSurveyor extends SampleBase {   
    constructor() {
        super(...arguments);
        this.childGrid = {
            queryString: 'NominativoPerito',
            allowPaging: false,
            pageSettings: { pageSize: 6, pageCount: 5 },
            toolbar: [ 'Search' ],
            columns: [
                { field: 'IdSinistro', headerText: 'ID Sinistro', headerTemplate: headerTemplate, textAlign: 'Center', width: '25%' },
                { field: 'SecondoPeritoSurveyor', headerText: 'Secondo Perito/Surveyor', headerTemplate: headerTemplate, width: '25%' },
                { field: 'NominativoPeritoPrincipale', headerText: 'Nominativo Perito Principale', headerTemplate: headerTemplate, width: '25%' },
                { field: 'StatoSinistro', headerText: 'Stato Sinistro', headerTemplate: headerTemplate, width: '25%' },
            ],
            queryCellInfo: this.onQueryCellInfo2.bind(this),
            dataBound: this.onDatabound2,
        };
        this.toolbarOptions = [ 'ExcelExport', { template: this.ddlStatiToolbar.bind(this) }, 'Search'];
        this.ddlFields = { text: 'TEXT', value: 'VALUE' };
        this.dsStati = [{TEXT: 'Tutti', VALUE: 'T'}, {TEXT: 'Aperti', VALUE: 'A'}, {TEXT: 'Chiusi', VALUE: 'C'}];
        this.dsPrimoLiv = [];
        this.dsSecondoLiv = [];
        this.primavolta = true;
    }
    onQueryCellInfo(args) {
        args.cell.style.color = args.column.index === 0 ? 'black' : 'purple';
        args.cell.style.fontSize = '1.0vw';
        args.cell.style.fontWeight = 'bold';                 
    }    
    onQueryCellInfo2(args) {
        args.cell.style.color = args.column.index === 0 ? 'black' : 'purple';
        args.cell.style.fontSize = '1.0vw';
        args.cell.style.fontWeight = 'bold';                 
    }   
    onDatabound(args) {         
        for (let ind=0;ind<=3;ind++)
             document.getElementById('GridSecondoPeritoSurveyor').querySelectorAll('.e-columnheader')[0].children[ind].style.backgroundColor = 'Linen';
        if (this.primavolta){
            this.dsPrimoLiv = this.gridInstance.dataSource;   
            this.dsSecondoLiv = this.gridInstance.childGrid.dataSource;
            this.primavolta = false;     
        }
    }
    onDatabound2(args) {  
        for (let ind=0;ind<=3;ind++)
        this.element.querySelectorAll('.e-columnheader')[0].children[ind].style.backgroundColor = 'Linen';       
    }
    toolbarClick(args) {
        switch (args.item.properties.prefixIcon) {
            case 'e-pdfexport':
                this.gridInstance.pdfExport();
                break;
            case 'e-excelexport':
                this.gridInstance.excelExport();
                break;
            case 'e-csvexport':
                this.gridInstance.csvExport();
                break;
        }
    } 
    onChangeStati(args) {
        let dsFiltro2Liv = [], dsFiltro1Liv = [];
        if (args.value === 'T'){
            this.gridInstance.dataSource = this.dsPrimoLiv;
            this.gridInstance.childGrid.dataSource = this.dsSecondoLiv;
            this.gridInstance.refresh();
        }
        else {
            debugger;
            if (args.value === 'A')
                dsFiltro2Liv = _.filter(this.dsSecondoLiv, elm3 => { return elm3.StatoSinistro === 'Aperto'; });
            else if (args.value === 'C')
                dsFiltro2Liv = _.filter(this.dsSecondoLiv, elm3 => { return elm3.StatoSinistro === 'Chiuso'; });
            dsFiltro2Liv = _.sortBy(dsFiltro2Liv, 'NominativoPerito');
            let dsTemp = _.groupBy(dsFiltro2Liv, 'NominativoPerito');
            Object.values(dsTemp).forEach((elm , idx) => {
                if (elm.length > 0){
                    dsFiltro1Liv.push({
                        NominativoPerito:Object.keys(dsTemp)[idx],
                        NumIncSecPerito:_.filter(elm, elm2 => {return elm2.SecondoPeritoSurveyor === 'Secondo Perito'}).length,
                        NumIncSurveyor:_.filter(elm, elm2 => {return elm2.SecondoPeritoSurveyor === 'Surveyor'}).length,
                    }); 
                }
            })
            this.gridInstance.dataSource = dsFiltro1Liv;
            this.gridInstance.childGrid.dataSource = dsFiltro2Liv;
            this.gridInstance.refresh();
        }
       
    }
    ddlStatiToolbar(args) {                
        return (
            <div id='divDDL'></div>
       );        
    }
    onCreated(args) {
        ReactDOM.render(<DropDownListComponent id='ddlStati' fields={this.ddlFields} ref={ ddlObj => this.ddlInstance = ddlObj } value='T' dataSource={this.dsStati} change={this.onChangeStati.bind(this)} allowFiltering={false} filterType='Contains'
               style={{width: '20vw'}} placeholder="Selezionare uno stato da visualizzare" floatLabelType='always' popupHeight="250px" cssClass='e-outline' />, document.getElementById('divDDL'), () => {                 
        });
    }

    render() {
        return (<div className='container-fluid'>
                    <div className="row">
                        <div className="col-xs-12 col-sm-12 col-lg-10 col-md-10">
                            <span id='titoloSecondoPeritoSurveyor' style={{ fontSize: '1.7vw', fontWeight: 'bold', color:'#133B95'}}></span>
                        </div>  
                    </div>
                    <div className="row">
                        <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12">    
                            <span id='elencoCompagnieSecondoPeritoSurveyor' style={{ fontSize: '1.2vw', fontWeight: 'bold', color:'#133B95'}}></span>
                        </div> 
                    </div>   
                    <div className="row">
                        <GridComponent id="GridSecondoPeritoSurveyor" ref={grid => this.gridInstance = grid} toolbar={this.toolbarOptions} childGrid={this.childGrid} allowSelection={false} enableHover={false} 
                                allowPaging={false} queryCellInfo={this.onQueryCellInfo.bind(this)} dataBound={this.onDatabound.bind(this)} allowFiltering={false}
                                allowTextWrap={true} toolbarClick={this.toolbarClick.bind(this)} allowExcelExport={true} allowPdfExport={true} hierarchyPrintMode='All' created={this.onCreated.bind(this)}>     
                            <ColumnsDirective>
                                <ColumnDirective field='NominativoPerito' headerText='Nominativo Perito' headerTemplate={headerTemplate} textAlign='Center'></ColumnDirective>
                                <ColumnDirective field='NumIncSecPerito' headerText='Numero Incarichi come Secondo Perito' headerTemplate={headerTemplate} textAlign='Center'></ColumnDirective>
                                <ColumnDirective field='NumIncSurveyor' headerText='Numero Incarichi come Surveyor' headerTemplate={headerTemplate} textAlign='Center'></ColumnDirective>
                            </ColumnsDirective>       
                            <Inject services={[Toolbar, DetailRow,ExcelExport, PdfExport, Page, Sort]}/>                
                        </GridComponent>
                    </div>                   
            </div>);
    }    
}    
